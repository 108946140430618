import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { Links, Pregnancy } from "../types";
import { mapIncomingValues, mapOutgoingValues } from "./mapping";

interface PregnancyWithLinks extends Pregnancy, Links { };

export const getPregnancyByPatientId = async (id: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.patients}/${id}/pregnancies`);

    const mappedResults = mapIncomingValues(result.data._embedded.pregnancies[0]);

    return (Object.fromEntries(Object.entries(mappedResults).filter(([_, v]) => v != null))) as unknown as PregnancyWithLinks;
};

export const updatePregnancyForId = async (delta: { [key: string]: any }, id: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.pregnancies}/${id}`, {
        ...mapOutgoingValues(delta)
    });
}