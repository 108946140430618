import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    OTHER_BLOOD_THINNER,
    PREMATURITY,
    PRIOR_FETAL_OUTCOME_DETAILS,
    PRIO_ORGAN_LESION_DETAILS,
    SEVERITY,
} from '../../../app/config';
import { YESNOUNKNOWN } from '../../../app/constants';
import { OutcomeContext } from '../../../contexts/OutcomeContext';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { ChecklistWrapper } from '../../Wrapper/ChecklistWrapper';
import { DateWrapper } from '../../Wrapper/DateWrapper';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';
import { SelectWrapper } from '../../Wrapper/SelectWrapper';
import { YesNoUnknownWrapper } from '../../Wrapper/YesNoUnknownWrapper';

interface OutcomeAddProps {
    dismissHandler: () => void;
    isShown: boolean;
}

export const OutcomeAdd: React.FC<OutcomeAddProps> = ({ dismissHandler, isShown }) => {
    const { outcome, updateOutcomeAttribute } = useContext(OutcomeContext);

    const saveOutcome = () => {
        // TODO
        console.log('save outcome');
    };

    return (
        <ModalWrapper
            confirmHandler={saveOutcome}
            confirmText="Add outcome"
            dismissHandler={dismissHandler}
            header="Add outcome"
            isShown={isShown}
            size="large"
            type="confirm"
        >
            <div className="outcome-add__outcome">
                <SectionHeader title="Outcome" hideDivider />
                <Row className="mb-3">
                    <Col>
                        <YesNoUnknownWrapper
                            clickHandler={updateOutcomeAttribute}
                            id="prececlampsiaDetected"
                            label="Preeclampsia detected"
                            selected={outcome.prececlampsiaDetected}
                            noUnknown
                        />
                    </Col>
                    <Col>
                        <DateWrapper
                            blurHandler={updateOutcomeAttribute}
                            id="dateDelivery"
                            label="Date of delivery"
                            value={outcome.dateDelivery}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <YesNoUnknownWrapper
                            clickHandler={updateOutcomeAttribute}
                            id="aspirinUsed"
                            label="Use of Aspirin"
                            selected={outcome.aspirinUsed}
                            noUnknown
                        />
                    </Col>
                    <Col>
                        <SelectWrapper
                            clickHandler={updateOutcomeAttribute}
                            id="bloodThinner"
                            items={OTHER_BLOOD_THINNER}
                            label="Use of other blood thinner"
                            selected={outcome.bloodThinner}
                        />
                    </Col>
                </Row>
            </div>
            <div className="outcome-add__maternal">
                <SectionHeader title="Maternal outcome" />
                <Row className="mb-3">
                    <Col>
                        <YesNoUnknownWrapper
                            clickHandler={updateOutcomeAttribute}
                            id="icuAdmission"
                            label="ICU Admission"
                            selected={outcome.icuAdmission}
                        />
                    </Col>
                    <Col>
                        <SelectWrapper
                            clickHandler={updateOutcomeAttribute}
                            id="severity"
                            items={SEVERITY}
                            label="Severity"
                            selected={outcome.severity}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <YesNoUnknownWrapper
                            clickHandler={updateOutcomeAttribute}
                            id="hellpSyndrome"
                            label="HELLP syndrome"
                            selected={outcome.hellpSyndrome}
                        />
                    </Col>
                    <Col>
                        <SelectWrapper
                            clickHandler={updateOutcomeAttribute}
                            id="prematurity"
                            items={PREMATURITY}
                            label="Prematurity"
                            selected={outcome.prematurity}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <YesNoUnknownWrapper
                            clickHandler={updateOutcomeAttribute}
                            id="organLesion"
                            label="Target organ lesion"
                            selected={outcome.organLesion}
                        />
                    </Col>
                    <Col />
                </Row>
                {outcome.organLesion === YESNOUNKNOWN.YES && (
                    <ChecklistWrapper
                        changeHandler={updateOutcomeAttribute}
                        id="organLesionDetails"
                        items={PRIO_ORGAN_LESION_DETAILS}
                        label="target organ lesion details"
                        labelAdd="If yes, "
                        selected={outcome.organLesionDetails}
                    />
                )}
            </div>
            <div className="outcome-add__fetal">
                <SectionHeader title="Fetal outcome" />
                <Row className="mb-3">
                    <Col>
                        <YesNoUnknownWrapper
                            clickHandler={updateOutcomeAttribute}
                            id="adverseOutcomes"
                            label="Adverse outcomes"
                            selected={outcome.adverseOutcome}
                            noUnknown
                        />
                    </Col>
                    <Col />
                </Row>
                {outcome.adverseOutcome === YESNOUNKNOWN.YES && (
                    <ChecklistWrapper
                        changeHandler={updateOutcomeAttribute}
                        id="adverseOutcomeDetails"
                        items={PRIOR_FETAL_OUTCOME_DETAILS}
                        label="adverse outcome details"
                        labelAdd="If yes, "
                        selected={outcome.adverseOutcomeDetails}
                    />
                )}
            </div>
        </ModalWrapper>
    );
};
