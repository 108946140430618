import { useCallback, useContext, useEffect } from "react";
import { BloodSampleBundlesContext } from "../contexts/BloodSampleBundlesContext";
import { isValidIntegerId } from "../helper/isValidId";

export function useLoadBloodSampleBundleForPregnancyId(pregnancyId: any) {
    const { loadBloodSampleBundlesForPregnancy, clearBloodSampleBundles } = useContext(BloodSampleBundlesContext);

    const cleanup = useCallback(() => {
        clearBloodSampleBundles();
    }, [
        clearBloodSampleBundles
    ])

    useEffect(() => {
        // TODO add error handling ?
        if (isValidIntegerId(pregnancyId)) {
            const id = Number(pregnancyId);
            loadBloodSampleBundlesForPregnancy(id);
        } else {
            cleanup();
        }

        return cleanup;
    }, [
        cleanup,
        pregnancyId,
        loadBloodSampleBundlesForPregnancy
    ]);
}