import React from 'react';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

interface BulkGenericErrorProps {
    dismissHandler: () => void;
    isShown: boolean;
    errorMessage?: string;
    errorHeader?: string;
}

export const BulkGenericError: React.FC<BulkGenericErrorProps> = ({
    dismissHandler,
    errorHeader = 'Error',
    errorMessage = 'Error: There has been an error.',
    isShown,
}) => {
    return (
        <ModalWrapper
            dismissHandler={dismissHandler}
            dismissText="Add later"
            header={errorHeader}
            icon="danger"
            isShown={isShown}
            type="dismiss"
        >
            {errorMessage}
        </ModalWrapper>
    );
};
