import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { FilledPlateMap, Links, PlateMap, PlateMapRegisterResult } from "../types";
import { mapIncomingValues, mapOutgoingValues } from "./mapping";

interface PlateMapWithLinks extends PlateMap, Links { };

export const getPlateMaps = async (size: number, page: number) => {
    const axios = HttpService.getAxiosClient();

    const url = `${endpoints.data.platemaps}?size=${size}&page=${page}&sort=id,desc`;

    const result = await axios.get(url);

    const mappedData: PlateMapWithLinks[] = result.data._embedded.platemaps.map(mapIncomingValues);
    return {
        data: mappedData,
        page: result.data.page
    }
};

export const getFilledPlateMapById = async (plateMapId: number) => {
    const axios = HttpService.getAxiosClient();

    const result = await axios.get(`${endpoints.data.platemaps}/${plateMapId}?projection=platemapdetails`);

    return result.data as FilledPlateMap;
}

export const createPlateMap = async () => {
    const axios = HttpService.getAxiosClient();

    const result = await axios.post(`${endpoints.data.platemaps}`, {});

    return result.data as PlateMapWithLinks;
}

export const updatePlateMapById = async (delta: { [key: string]: any }, plateMapId: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.platemaps}/${plateMapId}`, {
        ...mapOutgoingValues(delta)
    });
}

export const registerPlateMapById = async (plateMapId: number) => {
    const axios = HttpService.getAxiosClient();

    const result = await axios.post(`${endpoints.platemap.register}?plateMapId=${plateMapId}`, {});

    return result.data as PlateMapRegisterResult;
}

// PLATEMAP CUSTOM APIs

interface ManualPopulateProps {
    plateMapId: number;
    samplesIds: number[];
}
export const manualPopulatePlateMapByIdWithSamplesById = async ({ plateMapId, samplesIds }: ManualPopulateProps) => {
    const axios = HttpService.getAxiosClient();

    const url = `${endpoints.platemap.manualPopulate}?plateMapId=${plateMapId}&sampleIds=${samplesIds.join(',')}`;
    await axios.post(url, {});
}

export const autoPopulatePlateMapById = async (plateMapId: number) => {
    const axios = HttpService.getAxiosClient();

    const url = `${endpoints.platemap.autopopulate}?plateMapId=${plateMapId}`;

    await axios.post(url, {});
}

export const clearPlateMapById = async (plateMapId: number) => {
    const axios = HttpService.getAxiosClient();

    await axios.post(`${endpoints.platemap.clear}?plateMapId=${plateMapId}`, {});
}

export const exportRunFileById = async (plateMapId: number) => {
    const axios = HttpService.getAxiosClient();

    const result = await axios.get(`${endpoints.platemap.csvexport}?plateMapId=${plateMapId}`);

    return result.data as string;
}