import React, { useContext, useEffect, useRef, useState } from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { useLocation, useNavigate } from 'react-router';
import { PatientList } from '../components/PatientList/PatientList';
import { Col, Container, Row } from 'react-bootstrap';
import { IconButton } from '../components/IconButton/IconButton';

import { ReactComponent as AddResultIcon } from '../resources/icons/addResult.svg';
import { ReactComponent as AddPatientIcon } from '../resources/icons/addPatient.svg';
import { ReactComponent as AddPlateIcon } from '../resources/icons/addPlate.svg';
import { ScrollTop } from '../helper/ScrollTop';
import { PlateMapList } from '../components/PlateMapList/PlateMapList';
import { SampleCandidatesContext } from '../contexts/SampleCandidatesContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { PatientListContext } from '../contexts/PatientListContext';

export default function Home() {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { loadCandidates, sampleCandidates } = useContext(SampleCandidatesContext);
    const { setTestStatusFilter } = useContext(PatientListContext);
    const { headerHeight } = useContext(SettingsContext);
    const [yPosition, setYPosition] = useState(0);

    const tableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (pathname.indexOf('toreport') !== -1) {
            // this should run only after the table data is loaded, so we can calculate the correct scroll position
            // TODO? this is not optimal, since it relys on everything being loaded before the timeout is resolved
            setTimeout(() => {
                const currentScrollPosition = window.scrollY;
                setYPosition(
                    tableRef?.current?.getBoundingClientRect().top - headerHeight + currentScrollPosition || 0
                );
            }, 500); // 500 works for now, but might need to be adjusted if the table takes longer to load

            setTestStatusFilter('ToReport');
        }
    }, [headerHeight, pathname, setTestStatusFilter]);

    useEffect(() => {
        loadCandidates();
    }, [loadCandidates]);

    const addNewPatient = () => {
        navigate('/patientForm/new');
    };

    const setupText = () => {
        navigate(`/plateMap/new`);
    };

    const addResults = () => {
        navigate(`/bulkImport`);
    };

    return (
        <div className="home-page">
            <Header />
            <Container className="mt-5">
                <Row>
                    <Col>
                        <div className="flex justify-center">
                            <IconButton clickHandler={addNewPatient} Icon={AddPatientIcon} label="New patient" />
                            <IconButton
                                clickHandler={setupText}
                                Icon={AddPlateIcon}
                                label="Set up test"
                                notifications={sampleCandidates.length}
                            />
                            <IconButton clickHandler={addResults} Icon={AddResultIcon} label="Add Results" />
                        </div>
                    </Col>
                </Row>
            </Container>

            <PlateMapList />

            <PatientList ref={tableRef} />
            <Footer />
            <ScrollTop yPosition={yPosition} />
        </div>
    );
}
