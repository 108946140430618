import React, { useContext, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { SampleCandidatesContext } from '../../contexts/SampleCandidatesContext';
import { SampleCandidateWithSelection } from '../../types';
import { GenericTable } from '../GenericTable/GenericTable';
import './SampleCandidatesTable.scss';

interface SampleCandidatesTableProps {
    setSelectedIds: (ids: number[]) => void;
}

export const SampleCandidatesTable: React.FC<SampleCandidatesTableProps> = ({ setSelectedIds }) => {
    const { loadCandidates, sampleCandidates, setSampleCandidates } = useContext(SampleCandidatesContext);

    useEffect(() => {
        loadCandidates();
    }, [loadCandidates]);

    const toggleSelection = (id: number) => {
        const updatedCandidates = sampleCandidates.map((candidate) => {
            if (candidate.id === id) {
                return {
                    ...candidate,
                    isSelected: !candidate.isSelected,
                };
            }
            return candidate;
        });
        setSelectedIds(updatedCandidates.filter((candidate) => candidate.isSelected).map((candidate) => candidate.id));
        setSampleCandidates(updatedCandidates);
    };

    const concatName = (candidate: SampleCandidateWithSelection) => {
        if (!candidate.patientForename && !candidate.patientSurname) {
            return '-';
        }
        return `${candidate.patientForename} ${candidate.patientSurname}`;
    };

    const tableHeader = ['', 'External ID', 'Blood Sample Date Taken', 'Name'];
    const tableData = sampleCandidates.map((candidate) => [
        <Form.Check
            checked={candidate.isSelected}
            id="table-header-checkbox"
            type="checkbox"
            onChange={() => {}} /* intentional */
            onClick={() => toggleSelection(candidate.id)}
        />,
        candidate.patientExternalId,
        candidate.bloodSampleDateTaken,
        concatName(candidate),
    ]);

    if (tableData.length === 0) {
        return <div className="sample-candidates-table__no-data">No sample candidates to populate.</div>;
    }

    return <GenericTable className="sample-candidates-table" data={tableData} header={tableHeader} />;
};
