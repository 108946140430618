import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthenticationService from './helper/AuthenticationService';
import HttpService from './helper/HttpService';
import './index.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const renderApp = () => root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

AuthenticationService.initKeycloak(renderApp);
HttpService.configure();
// renderApp();
