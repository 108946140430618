export const getMappedNumber = (number: string) => {
    switch (number) {
        case '1':
            return 1;
        case '2':
            return 2;
        case '3':
            return 3;
        case '4':
            return 4;
        case '5+':
            return 99;
        default:
            return 0;
    }
}

export const limitObjectValues = (object: any, limit: number) => {
    const keys = Object.keys(object);
    const limitedObject: any = {};
    keys.forEach((key, index) => {
        if (index < limit + 1) {
            limitedObject[key] = object[key];
        }
    });
    return limitedObject;
}