import Keycloak from 'keycloak-js';

// create keycloak with json config
const keycloak = new Keycloak({
    "clientId": "prepsia-user-client",
    "realm": process.env.REACT_APP_KEYCLOAK_REALM,
    "url": "https://auth.mdeg.cloud",
});

// init keycloak with options
const initKeycloak = (onAuthenticatedCallback: () => void) => {
    keycloak.init({
        onLoad: 'login-required',
        pkceMethod: 'S256',
    }).then((authenticated) => {
        if (!authenticated) {
            console.log("user is not authenticated..!");
            // TODO THROW ERROR?
        }
        onAuthenticatedCallback();
    })
        .catch((e) => {
            console.log('failure', e);
        });
}

const doLogin = keycloak.login;
const doLogout = keycloak.logout;
const getToken = () => keycloak.token;
const isLoggedIn = () => !!keycloak.token;


const updateToken = (successCallback: () => Promise<any>) =>
    keycloak.updateToken(5)
        .then(successCallback)
        .catch(doLogin);


const AuthenticationService = {
    doLogin,
    doLogout,
    getToken,
    initKeycloak,
    isLoggedIn,
    updateToken
}

export default AuthenticationService