import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

import { ReactComponent as CheckIcon } from '../../resources/icons/check.svg';
import { ReactComponent as DangerIcon } from '../../resources/icons/danger.svg';

import './UploadCard.scss';

type UploadState = 'success' | 'failure' | 'waiting' | 'loading';

interface UploadCardProps {
    className?: string;
    header: string;
    text: string | JSX.Element;
    uploadHandler: (file: File, afterUpload: (state: UploadState) => void) => void;
}

export const UploadCard: React.FC<UploadCardProps> = ({ className, header, text, uploadHandler }) => {
    const [file, setFile] = useState<File>(null);
    const [uploadState, setUploadState] = useState<UploadState>('waiting');

    const startUpload = () => {
        setUploadState('loading');
        uploadHandler(file, afterUpload);
    };

    const afterUpload = (state: UploadState) => {
        setUploadState(state);
    };

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFile(e.target.files[0]);
        setUploadState('waiting');
    };

    let border = 'dark';

    if (file) border = 'info';
    if (uploadState === 'success') border = 'success';
    if (uploadState === 'failure') border = 'danger';

    let uploadAction = (
        <Form>
            <Form.Group className="mb-3">
                <Form.Control accept=".xml" type="file" onChange={changeHandler} />
            </Form.Group>
            <Button
                className="btn--full"
                disabled={!file || uploadState === 'loading' || uploadState === 'success'}
                variant="outline-primary"
                onClick={startUpload}
            >
                Submit
            </Button>
        </Form>
    );

    return (
        <Card border={border} className={className}>
            <Card.Header>{header}</Card.Header>
            <Card.Body className="upload-card">
                <div>{text}</div>

                <div>{uploadAction}</div>
            </Card.Body>
        </Card>
    );
};
