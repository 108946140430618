import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
    INTERPREGNANCY_INTERVAL,
    NUMBER_PRIOR_BIRTHS,
    NUMBER_PRIOR_GESTATIONS,
    NUMBER_PRIOR_MISCARRIAGES,
    PRIOR_TREATMENT,
} from '../../app/config';
import { PREGNANCY_STATE, YESNOUNKNOWN } from '../../app/constants';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { getMappedNumber, limitObjectValues } from '../../helper/gestationHelper';
import { InputWrapper } from '../Wrapper/InputWrapper';
import { SelectWrapper } from '../Wrapper/SelectWrapper';
import { YesNoUnknownWrapper } from '../Wrapper/YesNoUnknownWrapper';
import { PriorOutcome } from './PriorOutcome';

interface PriorHistoryProps {}

export const PriorHistory: React.FC<PriorHistoryProps> = () => {
    const { pregnancy, updatePregnancyAttribute } = useContext(PregnancyContext);

    // handle gestations
    const hasGestations =
        typeof pregnancy.numberOfPriorGestations === 'string' &&
        pregnancy.numberOfPriorGestations !== Object.keys(NUMBER_PRIOR_GESTATIONS)[0];
    // calculate how many births and miscarriages can be selected
    const maxGestations = getMappedNumber(NUMBER_PRIOR_GESTATIONS[pregnancy.numberOfPriorGestations]);
    const amountBirths = getMappedNumber(NUMBER_PRIOR_BIRTHS[pregnancy.numberOfPriorBirth]);
    const amountMiscarriages = getMappedNumber(NUMBER_PRIOR_MISCARRIAGES[pregnancy.numberOfPriorMiscarriages]);

    const maxMiscarriages = maxGestations - Math.min(amountBirths, 10);
    const maxBirths = maxGestations - Math.min(amountMiscarriages, 10);

    const priorBirths = limitObjectValues(NUMBER_PRIOR_BIRTHS, maxBirths);
    const priorMiscarriages = limitObjectValues(NUMBER_PRIOR_MISCARRIAGES, maxMiscarriages);

    // reset birth and miscarriage if gestations are changed to prevent invalid selections
    const updateGestations = (delta: { [key: string]: any }) => {
        updatePregnancyAttribute({
            numberOfPriorBirth: null,
            numberOfPriorGestations: delta.numberOfPriorGestations,
            numberOfPriorMiscarriages: null,
        });
    };

    return (
        <div className="prior-history mb-4">
            <Row className="mb-3">
                <Col>
                    <SelectWrapper
                        clickHandler={updateGestations}
                        id="numberOfPriorGestations"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        items={NUMBER_PRIOR_GESTATIONS}
                        label="Number of Prior Gestations"
                        selected={pregnancy.numberOfPriorGestations}
                    />
                </Col>
                <Col>
                    {hasGestations && (
                        <SelectWrapper
                            clickHandler={updatePregnancyAttribute}
                            id="numberOfPriorBirth"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            items={priorBirths}
                            label="Number of Prior Births"
                            selected={pregnancy.numberOfPriorBirth}
                        />
                    )}
                </Col>
            </Row>
            {hasGestations && (
                <Row className="mb-3">
                    <Col>
                        <SelectWrapper
                            clickHandler={updatePregnancyAttribute}
                            id="numberOfPriorMiscarriages"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            items={priorMiscarriages}
                            label="Number of Prior Miscarriages"
                            selected={pregnancy.numberOfPriorMiscarriages}
                        />
                    </Col>
                    <Col />
                </Row>
            )}
            {hasGestations && (
                <Row className="mb-3">
                    <Col>
                        <YesNoUnknownWrapper
                            clickHandler={updatePregnancyAttribute}
                            id="differentPreviousFather"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            label="Different Previous Father"
                            selected={pregnancy.differentPreviousFather}
                        />
                    </Col>
                    <Col>
                        <SelectWrapper
                            clickHandler={updatePregnancyAttribute}
                            id="interpregnancyInterval"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            items={INTERPREGNANCY_INTERVAL}
                            label="Interpregnancy interval <br/> (including misscarriages)"
                            selected={pregnancy.interpregnancyInterval}
                        />
                    </Col>
                </Row>
            )}
            {hasGestations && (
                <Row className="mb-3">
                    <Col>
                        <YesNoUnknownWrapper
                            clickHandler={updatePregnancyAttribute}
                            id="prevPreeclampsiaPregnancy"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            label="Previous Pregnancy with Preeclampsia"
                            selected={pregnancy.prevPreeclampsiaPregnancy}
                            noUnknown
                            required
                        />
                    </Col>
                    <Col>
                        {pregnancy.prevPreeclampsiaPregnancy === YESNOUNKNOWN.YES && (
                            <InputWrapper
                                changeHandler={updatePregnancyAttribute}
                                id="prevPreeclampsiaPregnancyId"
                                isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                                label="If previous PrePsia testing performed, enter previous Patient ID"
                                placeholder={'MDX01-'}
                                value={pregnancy.prevPreeclampsiaPregnancyId}
                            />
                        )}
                    </Col>
                </Row>
            )}
            {pregnancy.prevPreeclampsiaPregnancy === YESNOUNKNOWN.YES && (
                <Row className="mb-3">
                    <Col>
                        <SelectWrapper
                            clickHandler={updatePregnancyAttribute}
                            id="priorTreatment"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            items={PRIOR_TREATMENT}
                            label="Prior Treatment"
                            selected={pregnancy.priorTreatment}
                            required
                        />
                    </Col>
                    <Col />
                </Row>
            )}
            {hasGestations && pregnancy.prevPreeclampsiaPregnancy === YESNOUNKNOWN.YES && <PriorOutcome />}
        </div>
    );
};
