import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { BloodMapsContext } from '../../contexts/BloodMapsContext';
import { BloodSampleBundlesContext } from '../../contexts/BloodSampleBundlesContext';
import { EdtaSamplesContext } from '../../contexts/EdtaSamplesContext';
import { PatientContext } from '../../contexts/PatientContext';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { ResidenceContext } from '../../contexts/ResidenceContext';
import { SerumSamplesContext } from '../../contexts/SerumSamplesContext';
import { ValidationError } from '../../types';
import './ValidationWrapper.scss';

interface ValidationWrapperProps {
    externalErrors?: ValidationError[];
    attribute: string;
    children: any;
    label: string;
}

interface Translations {
    [key: string]: string;
}

export const ValidationWrapper: React.FC<ValidationWrapperProps> = ({
    attribute,
    children,
    externalErrors = [],
    label,
}) => {
    const { patientErrors } = useContext(PatientContext);
    const { pregnancyErrors } = useContext(PregnancyContext);
    const { bloodSampleBundlesErrors } = useContext(BloodSampleBundlesContext);
    const { bloodMapsErrors } = useContext(BloodMapsContext);
    const { edtaSamplesErrors } = useContext(EdtaSamplesContext);
    const { serumSamplesErrors } = useContext(SerumSamplesContext);
    const { residenceErrors } = useContext(ResidenceContext);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setErrorMessage('');
        [
            ...patientErrors,
            ...pregnancyErrors,
            ...bloodSampleBundlesErrors,
            ...bloodMapsErrors,
            ...edtaSamplesErrors,
            ...serumSamplesErrors,
            ...residenceErrors,
            ...externalErrors,
        ].forEach((error) => {
            if (error.field === attribute) {
                // console.log(error.field, attribute, error.code);
                setErrorMessage(error.code);
            }
        });
    }, [
        attribute,
        externalErrors,
        patientErrors,
        pregnancyErrors,
        bloodSampleBundlesErrors,
        bloodMapsErrors,
        edtaSamplesErrors,
        serumSamplesErrors,
        residenceErrors,
    ]);

    const hasError = errorMessage !== '';
    const classes = clsx('validation-wrapper', {
        'validation-wrapper--error': hasError,
    });

    let translations: Translations = {
        alreadyExists: `${label} already exists.`,
        invalidValue: `${label} has an invalid value.`,
        noValue: `${label} does not have a value.`,
        outOfRange: `${label} is out of valid range.`,
    };

    return (
        <div className={classes}>
            {children}
            {hasError && (
                <span className="validation-wrapper__message">{translations[errorMessage] || errorMessage}</span>
            )}
        </div>
    );
};
