import React from 'react';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

import { ReactComponent as ErrorIcon } from '../../../resources/icons/danger.svg';
import { ValidationError } from '../../../types';

interface PatientRegisterErrorProps {
    dismissHandler: () => void;
    isShown: boolean;
    patientFormErrors: ValidationError[];
}

export const PatientRegisterError: React.FC<PatientRegisterErrorProps> = ({
    dismissHandler,
    isShown,
    patientFormErrors,
}) => {
    const mapError = (error: string) => {
        switch (error) {
            case 'externalPatientID':
                return 'Laboratory ID';
            case 'forename':
                return 'Forename';
            case 'surname':
                return 'Surname';
            case 'height':
                return 'Height';
            case 'birthDate':
                return 'Date of Birth';
            case 'weight':
                return 'Weight';
            case 'unitTypeHeight':
            case 'unitTypeWeight':
                return null;
            case 'countryOfResidence':
            case 'country':
                return 'Country of Residence';
            case 'zipCode':
                return 'Postal / ZIP Code';
            case 'ethnicity':
                return 'Ethnicity';
            case 'region':
                return 'State/Region/County';
            case 'race':
            case 'raceOther':
            case 'raceUs':
                return 'Race';
            case 'lastPeriod':
                return 'Last Period';
            case 'crownRump':
                return 'Crown Rump';
            case 'multifetalGestation':
                return 'Multifetal Gestation';
            case 'dateCrl':
                return 'Date of CRL';
            case 'smoker':
                return 'Smoker';
            case 'numberPriorMiscarriages':
                return 'Number of Prior Miscarriages';
            case 'prevPreeclampsiaPregnancy':
                return 'Previous Pregnancy with Preeclampsia';
            case 'priorTreatment':
                return 'Prior Treatment';
            case 'historyAdverseOutcomeAvailable':
                return 'History of Adverse Pregnancy Outcome';
            case 'priorFetalOutcomes':
                return 'Adverse Fetal Outcomes';
            case 'priorFetalOutcomeDetails':
                return 'Adverse Fetal Outcome Details';
            case 'priorMaternalOutcome':
                return 'Adverse Maternal Outcome';
            case 'priorIcuAdmission':
                return 'ICU Admission';
            case 'priorHellpSyndrome':
                return 'HELLP Syndrome';
            case 'priorOrganLesion':
                return 'Target Organ Lesion';
            case 'priorOrganLesionDetails':
                return 'Target Organ Lesion Details';
            case 'dopllerDateRecorded':
                return 'Doppler Date Recorded';
            default:
                return error;
        }
    };

    const onExitedHandler = () => {
        const errorOrder = [
            'externalPatientID',
            'surname',
            'forename',
            'height',
            'birthDate',
            'weight',
            'countryOfResidence',
            'zipCode',
            'ethnicity',
            'region',
            'raceOther',
            'raceUs',
            'race',
            'lastPeriod',
            'crownRump',
            'multifetalGestation',
            'dateCrl',
            'smoker',
            'numberPriorMiscarriages',
            'prevPreeclampsiaPregnancy',
            'priorTreatment',
            'historyAdverseOutcomeAvailable',
            'priorFetalOutcomes',
            'priorFetalOutcomeDetails',
            'priorMaternalOutcome',
            'priorIcuAdmission',
            'priorHellpSyndrome',
            'priorOrganLesion',
            'priorOrganLesionDetails',
            'dopllerDateRecorded',
        ];

        const firstError = errorOrder.find((error) =>
            patientFormErrors.find((patientFormError) => patientFormError.field === error)
        );

        if (firstError) {
            const element = document.querySelector(`#${firstError}`)?.nextElementSibling as HTMLElement;
            element?.focus();
        }
    };

    return (
        <ModalWrapper
            className="error-overlay"
            dismissHandler={dismissHandler}
            dismissText="Edit now"
            header="Error"
            icon={<ErrorIcon />}
            isShown={isShown}
            restoreFocus={false}
            size="small"
            type="dismiss"
            onExited={onExitedHandler}
        >
            <p>Patient details not registered.</p>
            <p>The following fields still require adjustment:</p>
            <ul>{patientFormErrors.map((error) => mapError(error.field) && <li>{mapError(error.field)}</li>)}</ul>
            <p>Please adjust these details before any PrePsia risk calculation is performed.</p>
        </ModalWrapper>
    );
};
