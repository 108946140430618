import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";

export const getHypertension = async (id: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.patients}/${id}/hypertension`);

    return result.data;
};

export const updateHypertensionForPatientId = async (delta: { [key: string]: any }, id: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.hypertensiondisease}/${id}`, {
        ...delta
    });
}