import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { GestationalAge, Links, Patient } from "../types";
import { mapIncomingValues, mapOutgoingValues } from "./mapping";

interface PatientWithLinks extends Patient, Links { };

export const getPatientById = async (id: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.patients}/${id}`);

    const mappedResults = mapIncomingValues(result.data);

    // this filters all entries where the value is null
    return (Object.fromEntries(Object.entries(mappedResults).filter(([_, v]) => v != null))) as unknown as PatientWithLinks;
};

export const addNewPatient = async (externalPatientId: string) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.post(endpoints.data.patients, {
        anonymized: true,
        externalPatientId,
    });

    return mapIncomingValues(result.data) as Patient;
};

export const updatePatientDataForId = async (delta: { [key: string]: any }, id: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.patients}/${id}`, {
        ...mapOutgoingValues(delta)
    });
}

export const deletePatientById = async (patientId: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.delete(`${endpoints.data.patients}/${patientId}`);
}

export const getGestationalAge = async (pregnancyId: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.patient.gestationalAge}?pregnancyId=${pregnancyId}`);

    return result.data as GestationalAge;
}