import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SerumSample } from '../../../types';
import { DateWrapper } from '../../Wrapper/DateWrapper';
import { InputWrapper } from '../../Wrapper/InputWrapper';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

interface PIGFAddProps {
    addPigfResult: ({ pgifDate, pgifResultValue }: { pgifDate: string; pgifResultValue: string }) => void;
    dismissHandler: () => void;
    isShown: boolean;
    serumSample: SerumSample;
}

export const PIGFAdd: React.FC<PIGFAddProps> = ({ addPigfResult, dismissHandler, isShown, serumSample }) => {
    const [pgifDate, setPgifDate] = useState(serumSample.pgifDate || '');
    const [pgifResultValue, setPgifResultValue] = useState(
        serumSample.pgifResultValue ? String(serumSample.pgifResultValue) : ''
    );

    const addResult = () => {
        addPigfResult({ pgifDate, pgifResultValue });
    };

    return (
        <ModalWrapper
            confirmHandler={addResult}
            confirmText="Submit"
            dismissHandler={dismissHandler}
            header="Add PIGF Result"
            isShown={isShown}
            type="confirm"
        >
            <Row>
                <Col>
                    <InputWrapper
                        changeHandler={(delta) => {
                            setPgifResultValue(delta.pgifResultValue);
                        }}
                        id="pgifResultValue"
                        label="Result"
                        unit="ng.ml"
                        value={pgifResultValue}
                    />
                </Col>
                <Col>
                    <DateWrapper
                        blurHandler={(delta) => {
                            setPgifDate(delta.pgifDate);
                        }}
                        id="pgifDate"
                        label="Date of Result"
                        value={pgifDate}
                    />
                </Col>
            </Row>
        </ModalWrapper>
    );
};
