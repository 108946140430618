import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { mapOutgoingValues } from "./mapping";

export const getResidenceByPatientId = async (id: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.patients}/${id}/residences`);

    return result.data._embedded.residences[0];
};

export const updateResidenceForId = async (delta: { [key: string]: any }, id: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.residences}/${id}`, {
        ...mapOutgoingValues(delta),
    });
}