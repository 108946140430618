import React, { useContext } from 'react';
import { PatientContext } from '../../contexts/PatientContext';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { ResidenceContext } from '../../contexts/ResidenceContext';
import { COUNTRIES, COUNTRIES_DATA, ETHNICITIES, RACES_OTHER, RACES_US } from '../../app/config';

interface PatientDProps {
    className?: string;
}

export const PatientD: React.FC<PatientDProps> = ({ className = '' }) => {
    const { patient } = useContext(PatientContext);
    const { pregnancy } = useContext(PregnancyContext);
    const { residence } = useContext(ResidenceContext);

    // COUNTRIES AND STATES
    let states: any = {};

    if (!!residence?.country) {
        states = COUNTRIES_DATA[residence?.country];
    }

    return (
        <div className={className}>
            <h5>Patient Details:</h5>
            <ul>
                <li>
                    <span>Height (m.cm):</span>
                    <span>{patient.height}</span>
                </li>
                <li>
                    <span>Weight (kg.g):</span>
                    <span>{pregnancy.weight}</span>
                </li>
                <li>
                    <span>Country of Residence:</span>
                    <span>{COUNTRIES[residence.country]}</span>
                </li>
                <li>
                    <span>Postal/Zip Code:</span>
                    <span>{residence.zipCode}</span>
                </li>
                <li>
                    <span>State/Region/County:</span>
                    <span>{states[residence.region]}</span>
                </li>
                <li>
                    <span>Ethnicity:</span>
                    <span>{ETHNICITIES[patient.ethnicity]}</span>
                </li>
                <li>
                    <span>Race:</span>
                    <span>{!!patient.raceUs ? RACES_US[patient.raceUs] : RACES_OTHER[patient.raceOther]}</span>
                </li>
            </ul>
        </div>
    );
};
