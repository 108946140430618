import { createContext, useCallback, useState } from 'react';
import { getOutcomeByPregnancyId, updateOutcomeForId } from '../api/outcome';
import { debouncedSaveWithErrors } from '../helper/apiHelper';
import { Outcome } from '../types';

interface OutcomeContextProps {
    clearOutcome: () => void;
    loadOutcomeForPregnancy: (id: number) => void;
    outcome: Outcome;
    updateOutcomeAttribute: (delta: { [key: string]: any }) => void;
}

export const OutcomeContext = createContext<OutcomeContextProps>(null);
OutcomeContext.displayName = 'OutcomeContext';

const outcomeDefaults: Outcome = {
    adverseOutcomeDetails: [],
    id: null,
    organLesionDetails: [],
};

export const OutcomeContextProvider = (props: any) => {
    const [outcome, setOutcome] = useState(outcomeDefaults);

    const updateOutcomeAttribute = async (delta: { [key: string]: any }) => {
        await debouncedSaveWithErrors({ callApi: updateOutcomeForId, setErrors: () => {} })(delta, outcome.id);
        setOutcome({ ...outcome, ...delta });
    };

    const clearOutcome = useCallback(() => {
        setOutcome(outcomeDefaults);
    }, []);

    const loadOutcomeForPregnancy = useCallback(async (pregnancyId: number) => {
        const outcomeWithLinks = await getOutcomeByPregnancyId(pregnancyId);
        const { _links, ...outcomeWithoutLinks } = outcomeWithLinks;
        setOutcome(outcomeWithoutLinks);
    }, []);

    return (
        <OutcomeContext.Provider
            value={{
                clearOutcome,
                loadOutcomeForPregnancy,
                outcome,
                updateOutcomeAttribute,
            }}
        >
            {props.children}
        </OutcomeContext.Provider>
    );
};
