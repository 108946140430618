import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CONCEPTION_METHODS, MULTIFETAL_GESTATION } from '../../app/config';
import { ModalWrapper } from '../Wrapper/ModalWrapper';
import { InputWrapper } from '../Wrapper/InputWrapper';
import { SelectWrapper } from '../Wrapper/SelectWrapper';
import { PREGNANCY_STATE, YESNOUNKNOWN } from '../../app/constants';
import { PatientContext } from '../../contexts/PatientContext';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { Headline } from '../Headline/Headline';
import { DateWrapper } from '../Wrapper/DateWrapper';

interface PregnancyDetailsProps {}

export const PregnancyDetails: React.FC<PregnancyDetailsProps> = () => {
    const { patient, updatePatientAttribute } = useContext(PatientContext);
    const { pregnancy, updatePregnancyAttribute } = useContext(PregnancyContext);

    const [isMultifetalGestationModalShown, setIsMultifetalGestationModalShown] = useState(false);

    const selectMultifetalGestation = (delta: { [key: string]: string }) => {
        updatePregnancyAttribute(delta);
        if (delta.multifetalGestation === YESNOUNKNOWN.YES) setIsMultifetalGestationModalShown(true);
    };

    return (
        <div className="pregnancy-details mb-5 mt-5 form-section">
            <Headline headline="2. Pregnancy Details" requiredInfo />
            <Row className="mb-3">
                <Col>
                    <DateWrapper
                        blurHandler={updatePregnancyAttribute}
                        id="lastPeriod"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Last Menstrul Period"
                        value={pregnancy.lastPeriod}
                        required
                    />
                </Col>
                <Col>
                    <SelectWrapper
                        clickHandler={updatePregnancyAttribute}
                        id="conceptionMethod"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        items={CONCEPTION_METHODS}
                        label="Conception Method"
                        selected={pregnancy.conceptionMethod}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <InputWrapper
                        changeHandler={updatePregnancyAttribute}
                        id="crownRump"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Crown Rump Length"
                        placeholder="mm"
                        unit="mm"
                        value={pregnancy.crownRump}
                        required
                    />
                </Col>
                <Col>
                    <SelectWrapper
                        clickHandler={selectMultifetalGestation}
                        id="multifetalGestation"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        items={MULTIFETAL_GESTATION}
                        label="Multifetal gestation"
                        selected={pregnancy.multifetalGestation}
                        required
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DateWrapper
                        blurHandler={updatePregnancyAttribute}
                        id="dateCrl"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Date of CRL"
                        value={pregnancy.dateCrl}
                        required
                    />
                </Col>
                <Col>
                    <InputWrapper
                        changeHandler={updatePatientAttribute}
                        id="referringInstitution"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Referring Clinician/Institution"
                        value={patient.referringInstitution}
                    />
                </Col>
            </Row>
            <ModalWrapper
                dismissHandler={() => setIsMultifetalGestationModalShown(false)}
                header="Note"
                icon="warning"
                isShown={isMultifetalGestationModalShown}
                type="dismiss"
            >
                <p>PrePsia and risk calculations can't get requested for any multifetal gestations.</p>
            </ModalWrapper>
        </div>
    );
};
