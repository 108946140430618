import React from 'react';
import { Row } from 'react-bootstrap';
import { Headline } from '../Headline/Headline';
import { PriorHistory } from './PriorHistory';
import { RiskFactors } from './RiskFactors';

interface ClinicalHistoryRiskFactorsProps {}

export const ClinicalHistoryRiskFactors: React.FC<ClinicalHistoryRiskFactorsProps> = () => {
    return (
        <div className="clinical-history-risk-factors mb-5 mt-5 form-section">
            <Headline headline="3. Clinical History and Risk Factors" requiredInfo />
            <RiskFactors />
            <Row className="mb-4 mt-4">
                <div className="divider" />
            </Row>
            <PriorHistory />
        </div>
    );
};
