import React from 'react';
import { Req } from '../Req/Req';
import './Headline.scss';

interface HeadlineProps {
    headline: string;
    requiredInfo?: boolean;
}

export const Headline: React.FC<HeadlineProps> = ({ headline, requiredInfo = false }) => {
    return (
        <h4 className="headline mb-4">
            {headline}
            {requiredInfo && <span><Req /> Required for PrePsia and risk calculation</span>}
        </h4>
    );
};
