import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollTopProps {
    yPosition?: number;
}

export const ScrollTop = ({ yPosition = 0 }: ScrollTopProps): null => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, yPosition);
    }, [pathname, yPosition]);

    return null;
}
