import React, { useContext } from 'react';
import { PatientContext } from '../../contexts/PatientContext';

interface PatientConstentProps {
    className?: string;
}

export const PatientConstent: React.FC<PatientConstentProps> = ({ className = '' }) => {
    const { patient } = useContext(PatientContext);

    return (
        <div className={className}>
            <h5>Patient Consent</h5>
            <p>
                <strong>
                    The patient has consented to the use of their clinical, screening test and outcome data for the
                    optimisation of PrePsia and the research and development of other predictors of preeclapsia and
                    other related conditions.
                </strong>
            </p>
            <p>{patient.consent ? 'Yes' : 'No'}</p>
        </div>
    );
};
