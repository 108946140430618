import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PRIOR_FETAL_OUTCOME_DETAILS, PRIO_ORGAN_LESION_DETAILS } from '../../app/config';
import { PREGNANCY_STATE, YESNOUNKNOWN } from '../../app/constants';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { addArrayItem, removeArrayItem } from '../../helper/arrayHelper';
import { ChecklistWrapper } from '../Wrapper/ChecklistWrapper';
import { YesNoUnknownWrapper } from '../Wrapper/YesNoUnknownWrapper';

interface PriorOutcomeProps {}

export const PriorOutcome: React.FC<PriorOutcomeProps> = () => {
    const { pregnancy, updatePregnancyAttribute } = useContext(PregnancyContext);

    const fetalOutcomeHandler = (fetalOutcome: { [key: string]: boolean }) => {
        const key = Object.keys(fetalOutcome)[0];
        if (fetalOutcome[key]) {
            updatePregnancyAttribute({
                priorFetalOutcomesDetails: addArrayItem(pregnancy.priorFetalOutcomesDetails, key),
            });
        } else {
            updatePregnancyAttribute({
                priorFetalOutcomesDetails: removeArrayItem(pregnancy.priorFetalOutcomesDetails, key),
            });
        }
    };

    const organLesionHandler = (organLesion: { [key: string]: boolean }) => {
        const key = Object.keys(organLesion)[0];
        if (organLesion[key]) {
            updatePregnancyAttribute({
                priorOrganLesionDetails: addArrayItem(pregnancy.priorOrganLesionDetails, key),
            });
        } else {
            updatePregnancyAttribute({
                priorOrganLesionDetails: removeArrayItem(pregnancy.priorOrganLesionDetails, key),
            });
        }
    };

    return (
        <div className="prior-outcome mb-4">
            <Row className="mb-4">
                <Col>
                    <YesNoUnknownWrapper
                        clickHandler={updatePregnancyAttribute}
                        id="historyAdverseOutcomeAvailable"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="History of adverse pregnancy outcome"
                        selected={pregnancy.historyAdverseOutcomeAvailable}
                        required
                    />
                </Col>
                <Col />
            </Row>
            {pregnancy.historyAdverseOutcomeAvailable === YESNOUNKNOWN.YES && (
                <>
                    <Row className="mb-3">
                        <h5>Prior Fetal outcome</h5>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <YesNoUnknownWrapper
                                clickHandler={updatePregnancyAttribute}
                                id="priorFetalOutcome"
                                isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                                label="Adverse fetal outcomes"
                                selected={pregnancy.priorFetalOutcome}
                                required
                            />
                        </Col>
                        <Col />
                    </Row>

                    {pregnancy.priorFetalOutcome === YESNOUNKNOWN.YES && (
                        <ChecklistWrapper
                            changeHandler={fetalOutcomeHandler}
                            id="priorFetalOutcomesDetails"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            items={PRIOR_FETAL_OUTCOME_DETAILS}
                            label="prior adverse fetal outcome details"
                            labelAdd="If yes, please provide "
                            selected={pregnancy.priorFetalOutcomesDetails}
                            required
                        />
                    )}

                    <Row className="mb-3">
                        <h5>Prior Maternal Outcome</h5>
                    </Row>

                    <Row className="mb-3">
                        <Col>
                            <YesNoUnknownWrapper
                                clickHandler={updatePregnancyAttribute}
                                id="priorMaternalOutcome"
                                isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                                label="Adverse maternal outcomes"
                                selected={pregnancy.priorMaternalOutcome}
                                required
                            />
                        </Col>
                        <Col />
                    </Row>
                    {pregnancy.priorMaternalOutcome === YESNOUNKNOWN.YES && (
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <YesNoUnknownWrapper
                                        clickHandler={updatePregnancyAttribute}
                                        id="priorIcuAdmission"
                                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                                        label="ICU Admission"
                                        selected={pregnancy.priorIcuAdmission}
                                        required
                                    />
                                </Col>
                                <Col />
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <YesNoUnknownWrapper
                                        clickHandler={updatePregnancyAttribute}
                                        id="priorHellpSyndrome"
                                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                                        label="HELLP syndrome"
                                        selected={pregnancy.priorHellpSyndrome}
                                        required
                                    />
                                </Col>
                                <Col />
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <YesNoUnknownWrapper
                                        clickHandler={updatePregnancyAttribute}
                                        id="priorOrganLesion"
                                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                                        label="Target organ lesion"
                                        selected={pregnancy.priorOrganLesion}
                                        required
                                    />
                                </Col>
                                <Col />
                            </Row>
                            {pregnancy.priorOrganLesion === YESNOUNKNOWN.YES && (
                                <ChecklistWrapper
                                    changeHandler={organLesionHandler}
                                    id="priorOrganLesionDetails"
                                    isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                                    items={PRIO_ORGAN_LESION_DETAILS}
                                    label="target organ lesion details"
                                    labelAdd="If yes, "
                                    selected={pregnancy.priorOrganLesionDetails}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};
