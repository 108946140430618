import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { PlateMapsContext } from '../../contexts/PlateMapsContext';
import { ConsumableEntry } from './ConsumableEntry';

interface PrepsiaConsumablesProps {}

export const PrepsiaConsumables: React.FC<PrepsiaConsumablesProps> = () => {
    const { plateMapId } = useParams();
    const { getPlateMapById, updatePlateMapAttributeForPlateMapId } = useContext(PlateMapsContext);

    const changeHandler = (delta: { [key: string]: string }) => {
        updatePlateMapAttributeForPlateMapId(delta, Number(plateMapId));
    };

    const plateMap = getPlateMapById(Number(plateMapId));

    if (!plateMap) return <></>;

    return (
        <div className="prepsia-consumables">
            <h4>Batch Records: Consumables</h4>
            <h6 className="mt-5">LC-MS Columns</h6>

            <ConsumableEntry changeHandler={changeHandler} id="hilic" label="HILIC Column" plateMap={plateMap} />
            <ConsumableEntry changeHandler={changeHandler} id="rp" label="Reverse Phase Column" plateMap={plateMap} />
        </div>
    );
};
