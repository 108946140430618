import { endpoints } from "../app/config";
import { POST_EVENT } from "../app/constants";
import HttpService from "../helper/HttpService";

interface PostEvent {
    postEvent: POST_EVENT;
    pregnancyId: number;
}

export const postEvent = async ({ postEvent, pregnancyId }: PostEvent) => {
    const axios = HttpService.getAxiosClient();
    let url = `${endpoints.patient.postEvent}?event=${postEvent}&pregnancyId=${pregnancyId}`;

    const result = await axios.post(url);
    return result.data;
};