import React from 'react';
import { useNavigate } from 'react-router';
import { InputWrapper } from '../../Wrapper/InputWrapper';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

import { ReactComponent as AddPatientIcon } from '../../../resources/icons/addPatient.svg';
import { ValidationWrapper } from '../../Wrapper/ValidationWrapper';
import { ValidationError } from '../../../types';

interface PatientCreateProps {
    createNewPatient: () => void;
    errors: ValidationError[];
    extPatientId: string;
    isShown: boolean;
    updateExtPatientId: (delta: {[key: string]: string}) => void;
}

export const PatientCreate: React.FC<PatientCreateProps> = ({
    createNewPatient,
    errors,
    extPatientId,
    isShown,
    updateExtPatientId,
}) => {
    const navigate = useNavigate();
    return (
        <ModalWrapper
            confirmHandler={createNewPatient}
            confirmText="Create"
            dismissHandler={() => navigate('/home')}
            header="Add new patient"
            icon={<AddPatientIcon />}
            isShown={isShown}
            size="small"
            type="confirm"
        >
            <p>Please insert refference Patient ID for creating new patient.</p>
            <ValidationWrapper attribute='extPatientId' externalErrors={errors} label='Patient ID'>
                <InputWrapper changeHandler={updateExtPatientId} id="extPatientId" label="Patient ID" value={extPatientId} />
            </ValidationWrapper>
        </ModalWrapper>
    );
};
