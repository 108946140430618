import { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
    createBloodMapForBloodSampleBundle,
    CreateBloodMapParams,
    deleteBloodMapById,
    getBloodMapByBloodSampleBundleId as getBloodMapByMeasurementId,
    updateBloodMapById,
} from '../api/bloodMap';
import { debouncedSaveWithErrors } from '../helper/apiHelper';
import { modifyObjectInArrayById } from '../helper/arrayHelper';
import { BloodMap, BloodMapWithMeasurementId, ValidationError } from '../types';

interface BloodMapsContextProps {
    bloodMaps: BloodMapWithMeasurementId[];
    bloodMapsErrors: ValidationError[];
    clearBloodMaps: () => void;
    createNewBloodMapForBloodSampleBundle: ({ bloodSampleBundleId, data }: CreateBloodMapParams) => Promise<BloodMap>;
    deleteBloodMapByBloodSampleBundleId: (bloodSampleBundleId: number) => void;
    getBloodMapByBloodSampleBundleId: (bloodSampleBundleId: number) => BloodMapWithMeasurementId;
    loadBloodMapsForbloodSampleBundlesIds: (bloodSampleBundlesIds: number[]) => void;
    setBloodMapsErrors: Dispatch<SetStateAction<ValidationError[]>>;
    updateBloodMapAttributeForBloodMapId: (bloodMap: number, delta: { [key: string]: any }) => void;
}

const bloodMapsDefaults: BloodMapWithMeasurementId[] = [
    {
        bloodSampleBundleId: null as number,
        id: null as number,
    },
];

export const BloodMapsContext = createContext<BloodMapsContextProps>(null);
BloodMapsContext.displayName = 'BloodMapsContext';

export const BloodMapsContextProvider = (props: any) => {
    const [bloodMaps, setBloodMaps] = useState(bloodMapsDefaults);
    const [bloodMapsErrors, setBloodMapsErrors] = useState<ValidationError[]>([]);
    const [debounceFunctions, setDebounceFunctions] = useState<{ [key: string]: any }>({});

    const getBloodMapByBloodSampleBundleId = (bloodSampleBundleId: number) => {
        return bloodMaps.find((bloodMap) => bloodMap.bloodSampleBundleId === bloodSampleBundleId);
    };

    const clearBloodMaps = useCallback(() => {
        setBloodMaps(bloodMapsDefaults);
    }, []);

    const createNewBloodMapForBloodSampleBundle = async ({ bloodSampleBundleId, data }: CreateBloodMapParams) => {
        const bloodMapWithLinks = await createBloodMapForBloodSampleBundle({ bloodSampleBundleId, data });
        const { _links, ...bloodMapWithoutLinks } = bloodMapWithLinks;
        setBloodMaps([...bloodMaps, { ...bloodMapWithoutLinks, bloodSampleBundleId }]);
        return bloodMapWithoutLinks;
    };

    const deleteBloodMapByBloodSampleBundleId = async (bloodSampleBundleId: number) => {
        const bloodMap = getBloodMapByBloodSampleBundleId(bloodSampleBundleId);
        if (bloodMap) {
            await deleteBloodMapById(bloodMap.id);
            setBloodMaps(bloodMaps.filter((bloodMap) => bloodMap.bloodSampleBundleId !== bloodSampleBundleId));
        }
    };

    const loadBloodMapsForbloodSampleBundlesIds = useCallback(async (bloodSampleBundlesIds: number[]) => {
        const bloodMaps: BloodMapWithMeasurementId[] = [];
        for (const bloodSampleBundleId of bloodSampleBundlesIds) {
            const bloodMapWithLinks = await getBloodMapByMeasurementId(bloodSampleBundleId);
            const { _links, ...bloodMapWithoutLinks } = bloodMapWithLinks;
            bloodMaps.push({ ...bloodMapWithoutLinks, bloodSampleBundleId });
        }
        setBloodMaps(bloodMaps);
    }, []);

    const updateBloodMapAttributeForBloodMapId = useCallback(
        async (bloodMapId: number, delta: { [key: string]: any }) => {
            const attribute = Object.keys(delta)[0];
            let debounceFunction = debounceFunctions[attribute];

            if (!debounceFunctions[attribute]) {
                debounceFunction = debouncedSaveWithErrors({
                    callApi: updateBloodMapById,
                    setErrors: setBloodMapsErrors,
                });
                setDebounceFunctions({ ...debounceFunctions, [attribute]: debounceFunction });
            }

            await debounceFunction(delta, bloodMapId);

            const updatedBloodMaps = modifyObjectInArrayById({
                array: bloodMaps,
                delta,
                id: bloodMapId,
            });

            setBloodMaps(updatedBloodMaps);
        },
        [bloodMaps, debounceFunctions]
    );

    return (
        <BloodMapsContext.Provider
            value={{
                bloodMaps,
                bloodMapsErrors,
                clearBloodMaps,
                createNewBloodMapForBloodSampleBundle,
                deleteBloodMapByBloodSampleBundleId,
                getBloodMapByBloodSampleBundleId,
                loadBloodMapsForbloodSampleBundlesIds,
                setBloodMapsErrors,
                updateBloodMapAttributeForBloodMapId,
            }}
        >
            {props.children}
        </BloodMapsContext.Provider>
    );
};
