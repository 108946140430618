import React from 'react';
import { default as BSPagination } from 'react-bootstrap/Pagination';

interface PaginationProps {
    perPage: number;
    total: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({ currentPage, setCurrentPage, perPage, total }) => {
    const totalPages = Math.ceil(total / perPage);
    const maxShownPages = 9;
    let showAllPages = true;

    if (totalPages > maxShownPages) showAllPages = false;

    return (
        <div className="data-table__pagination mt-5">
            {showAllPages && (
                <AllPages currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
            )}
            {!showAllPages && (
                <SomePages currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
            )}
        </div>
    );
};

interface PagesProps {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    totalPages: number;
}
const AllPages: React.FC<PagesProps> = ({ currentPage, setCurrentPage, totalPages }) => {
    const pages = [...Array(totalPages)];

    return (
        <BSPagination>
            {totalPages > 5 && (
                <>
                    <BSPagination.First />
                    <BSPagination.Prev />
                </>
            )}
            {pages.map((page, index) => (
                <BSPagination.Item
                    active={index + 1 === currentPage}
                    key={`item_${index}`}
                    onClick={() => setCurrentPage(index + 1)}
                >
                    {index + 1}
                </BSPagination.Item>
            ))}
            {totalPages > 5 && (
                <>
                    <BSPagination.Next />
                    <BSPagination.Last />
                </>
            )}
        </BSPagination>
    );
};

const SomePages: React.FC<PagesProps> = ({ currentPage, setCurrentPage, totalPages }) => {
    const bottomPages = [...Array(7)];
    const topPages = [...Array(7)];
    const middlePages = [...Array(5)];

    const useMiddleIndex = currentPage > 6 && currentPage + 5 < totalPages;
    const useBottomIndex = currentPage <= 6;
    const useTopIndex = currentPage + 5 >= totalPages;

    const goBack = () => {
        setCurrentPage(Math.max(currentPage - 1, 1));
    };
    const goForward = () => {
        setCurrentPage(Math.min(currentPage + 1, totalPages));
    };

    return (
        <BSPagination>
            <BSPagination.First onClick={() => setCurrentPage(1)} />
            <BSPagination.Prev onClick={goBack} />

            {useBottomIndex && (
                <>
                    {bottomPages.map((page, index) => (
                        <BSPagination.Item
                            active={index + 1 === currentPage}
                            key={`item_${index}`}
                            onClick={() => {
                                setCurrentPage(index + 1);
                            }}
                        >
                            {index + 1}
                        </BSPagination.Item>
                    ))}
                    <BSPagination.Ellipsis />
                    <BSPagination.Item
                        active={currentPage === totalPages}
                        onClick={() => {
                            setCurrentPage(totalPages);
                        }}
                    >
                        {totalPages}
                    </BSPagination.Item>
                </>
            )}

            {useMiddleIndex && (
                <>
                    <BSPagination.Item active={currentPage === 1}>{1}</BSPagination.Item>
                    <BSPagination.Ellipsis />
                    {middlePages.map((page, index) => (
                        <BSPagination.Item
                            active={index === 2}
                            key={`item_${index}`}
                            onClick={() => {
                                setCurrentPage(index + currentPage - 2);
                            }}
                        >
                            {index + currentPage - 2}
                        </BSPagination.Item>
                    ))}
                    <BSPagination.Ellipsis />
                    <BSPagination.Item active={currentPage === totalPages}>{totalPages}</BSPagination.Item>
                </>
            )}

            {useTopIndex && (
                <>
                    <BSPagination.Item
                        active={currentPage === 1}
                        onClick={() => {
                            setCurrentPage(1);
                        }}
                    >
                        {1}
                    </BSPagination.Item>
                    <BSPagination.Ellipsis />
                    {topPages.map((page, index) => (
                        <BSPagination.Item
                            active={totalPages + index - 6 === currentPage}
                            key={`item_${index}`}
                            onClick={() => {
                                setCurrentPage(totalPages + index - 6);
                            }}
                        >
                            {totalPages + index - 6}
                        </BSPagination.Item>
                    ))}
                </>
            )}
            <BSPagination.Next onClick={goForward} />
            <BSPagination.Last onClick={() => setCurrentPage(totalPages)} />
        </BSPagination>
    );
};
