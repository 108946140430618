import React, { useEffect, useState } from 'react';

import './PlateGrid.scss';
import clsx from 'clsx';
import { PLATE_MAP_DEFAULTS } from './PlateGridDefaults';
import { useParams } from 'react-router';
import { getFilledPlateMapById } from '../../api/plateMaps';
import { mapPlateMapEntry } from '../../helper/plateMapHelper';
import { PlateMapItem } from '../../types';
import { PlateItem } from '../PlateItem/PlateItem';
import { isValidIntegerId } from '../../helper/isValidId';

interface PlateGridProps {
    afterUpdateCallback: () => void;
    isForceGridUpdate: boolean;
}

export const PlateGrid: React.FC<PlateGridProps> = ({ afterUpdateCallback, isForceGridUpdate }) => {
    const { plateMapId } = useParams();
    const [mappedPlateMapItems, setMappedPlateMapItems] = useState<PlateMapItem[]>([]);

    useEffect(() => {
        if (isValidIntegerId(plateMapId)) {
            const loadPlateMapData = async () => {
                const filledPlateMap = await getFilledPlateMapById(Number(plateMapId));

                const freePlateEntries = filledPlateMap.plateMapEntries.filter((entry) => !entry.reserved);
                const mappedPlateMapItems = freePlateEntries.map(mapPlateMapEntry);

                setMappedPlateMapItems(mappedPlateMapItems);
            };

            loadPlateMapData();
            if (isForceGridUpdate) afterUpdateCallback();
        }
    }, [afterUpdateCallback, isForceGridUpdate, plateMapId]);

    // Rendering ...
    const [gridPoint, setGridPoint] = useState({ cIndex: null, rIndex: null });

    const rowsIndex = [...'_abcdefgh'.split('')];
    const colsIndex = [...new Array(13)];
    const plateMapItems = [...mappedPlateMapItems, ...PLATE_MAP_DEFAULTS];

    const buildCol = (cIndex: number, rowChar: string, rIndex: number) => {
        const id = `item_${rowChar}_${cIndex}`;
        let content;
        let addClass = '';

        if (rowChar === '_') {
            content = cIndex === 0 ? '' : cIndex;
        } else if (cIndex === 0) {
            content = rowChar;
        } else {
            addClass = 'plate-grid__item--has-border';

            const plateMapItem = plateMapItems.filter(
                (data) => data.xPosition === cIndex && data.yPosition === rIndex
            )[0];

            if (plateMapItem) {
                content = (
                    <PlateItem
                        content={plateMapItem.content}
                        title={plateMapItem.title}
                        variant={plateMapItem.variant}
                    />
                );
            }
        }
        if (gridPoint.cIndex === cIndex || gridPoint.rIndex === rIndex) {
            addClass += ' plate-grid__item--highlighted';
        }
        return (
            <div
                className={`plate-grid__item ${addClass}`}
                id={id}
                key={id}
                onMouseEnter={() => setGridPoint({ cIndex: cIndex, rIndex: rIndex })}
            >
                {content}
            </div>
        );
    };

    const rows = rowsIndex.map((row, rIndex) => {
        const id = `row_${row}`;
        const classes = clsx('plate-grid__row', {
            'plate-grid__row--highlighted': gridPoint.rIndex === rIndex && rIndex !== 0,
        });
        return (
            <div className={classes} id={id} key={id}>
                {colsIndex.map((col, cIndex) => {
                    return buildCol(cIndex, row, rIndex);
                })}
            </div>
        );
    });

    return (
        <div className="plate-grid" onMouseLeave={() => setGridPoint({ cIndex: null, rIndex: null })}>
            {rows.map((row) => row)}
        </div>
    );
};
