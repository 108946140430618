import { AxiosResponse } from "axios";
import { debounce, unionBy } from "lodash";
import { ERRORS } from "../app/constants";
import { ValidationError } from "../types";
import { validateValue } from "./validation";

interface DebouncedSaveWithErrors {
    callApi: (delta: {
        [key: string]: any;
    }, id: number) => Promise<AxiosResponse<any, any>>;
    setErrors: (value: React.SetStateAction<ValidationError[]>) => void
}

export const debouncedSaveWithErrors = ({ callApi, setErrors }: DebouncedSaveWithErrors) =>
    debounce(async (delta: { [key: string]: any }, id) => {
        if (!id) {
            console.error('No valid ID', id);
            return
        };
        const key = Object.keys(delta)[0];
        const value = delta[key];

        // FE Validation 
        const validationErrors = validateValue({ key, value });

        if (validationErrors.length > 0) {
            setErrors((errors) => unionBy(errors, validationErrors, 'field'));
            return;
        } else {
            setErrors((errors) => errors.filter((error) => error.field !== key));
        }

        // API call with BE Validation
        const response = await callApi(delta, id).catch((e) => {
            // error case
            if (e.code === ERRORS.ERR_BAD_REQUEST) {
                Array.isArray(e.response?.data) &&
                    e.response.data.forEach((error: ValidationError) => {
                        setErrors((errors) =>
                            unionBy(errors, [error], 'field')
                        );
                    });
            }
        });

        if (response && response.status === 200) {
            // success case
            setErrors((errors) => errors.filter((error) => error.field !== key));
        }
    }, 300)
