import React, { useContext, useEffect, useState } from 'react';
import { PatientContext } from '../../contexts/PatientContext';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { CONCEPTION_METHODS, YESNOUNKNOWN } from '../../app/config';
import { GestationalAge } from '../../types';
import { getGestationalAge } from '../../api/patient';

interface PregnancyDProps {
    className?: string;
}

export const PregnancyD: React.FC<PregnancyDProps> = ({ className = '' }) => {
    const { patient } = useContext(PatientContext);
    const { pregnancy } = useContext(PregnancyContext);

    const [gestationalAge, setGestationalAge] = useState<GestationalAge>(null);

    useEffect(() => {
        if (!pregnancy.id) return;
        const getAge = async () => {
            const age = await getGestationalAge(pregnancy.id);
            if (age.days >= 0 && age.weeks >= 0) setGestationalAge(age);
        };
        getAge();
    }, [pregnancy.id]);

    return (
        <div className={className}>
            <h5>Pregnancy Details:</h5>
            <ul>
                <li>
                    <span>Gestational Age (weeks):</span>
                    <span>{gestationalAge && Math.floor(gestationalAge.weeks)}</span>
                </li>
                <li>
                    <span>Last Menstrual Period</span>
                    <span>{pregnancy.lastPeriod}</span>
                </li>

                <li>
                    <span>Crown Rump Length (mm):</span>
                    <span>{pregnancy.crownRump}</span>
                </li>
                <li>
                    <span>CRL Date:</span>
                    <span>{pregnancy.dateCrl}</span>
                </li>
                <li>
                    <span>Multi-fetal Gestation:</span>
                    <span>{YESNOUNKNOWN[pregnancy.multifetalGestation]}</span>
                </li>
                <li>
                    <span>Referring Clinician/Institution:</span>
                    <span>{patient.referringInstitution}</span>
                </li>
                <li>
                    <span>Conception Method:</span>
                    <span>{CONCEPTION_METHODS[pregnancy.conceptionMethod]}</span>
                </li>
            </ul>
        </div>
    );
};
