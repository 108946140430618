import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { TestStatus } from '../app/config';

import { SORTING } from '../app/constants';
import { unionBy } from 'lodash';
import { PatientFromList } from '../types';

export interface PatientListEntry extends PatientFromList {
    isChecked?: boolean;
}

interface PatientListContextProps {
    clearPatientList: () => void;
    currentPage: number;
    endDate: string;
    forceUpdate: () => void;
    patientList: PatientListEntry[];
    patientName: string;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    setEndDate: Dispatch<SetStateAction<string>>;
    setPatientList: Dispatch<SetStateAction<PatientListEntry[]>>;
    setPatientName: Dispatch<SetStateAction<string>>;
    setSortKey: Dispatch<SetStateAction<string>>;
    setSortOrder: Dispatch<SetStateAction<SORTING>>;
    setStartDate: Dispatch<SetStateAction<string>>;
    setTestStatusFilter: Dispatch<SetStateAction<TestStatus>>;
    setTotal: Dispatch<SetStateAction<number>>;
    sortKey: string;
    sortOrder: SORTING;
    startDate: string;
    testStatusFilter: TestStatus;
    total: number;
    updatePatientList: (patientListEntries: PatientListEntry[]) => void;
}

export const PatientListContext = createContext<PatientListContextProps>(null);
PatientListContext.displayName = 'PatientListContext';

export const PatientListContextProvider = (props: any) => {
    const [patientName, setPatientName] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [testStatusFilter, setTestStatusFilter] = useState<TestStatus>();
    const [patientList, setPatientList] = useState<PatientListEntry[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [sortKey, setSortKey] = useState('id');
    const [sortOrder, setSortOrder] = useState<SORTING>(SORTING.ASC);

    const updatePatientList = useCallback(
        (patientListEntries: PatientListEntry[]) => {
            setPatientList(unionBy(patientListEntries, patientList, 'id'));
        },
        [patientList]
    );

    const clearPatientList = useCallback(() => {
        setPatientList([]);
    }, []);

    // reset page if filter changes
    useEffect(() => {
        setCurrentPage(1);
    }, [testStatusFilter]);

    // force a rerendering of the current patient list on page 1
    const forceUpdate = () => {
        setCurrentPage(1);
    };

    return (
        <PatientListContext.Provider
            value={{
                clearPatientList,
                currentPage,
                endDate,
                forceUpdate,
                patientList,
                patientName,
                setCurrentPage,
                setEndDate,
                setPatientList,
                setPatientName,
                setSortKey,
                setSortOrder,
                setStartDate,
                setTestStatusFilter,
                setTotal,
                sortKey,
                sortOrder,
                startDate,
                testStatusFilter,
                total,
                updatePatientList,
            }}
        >
            {props.children}
        </PatientListContext.Provider>
    );
};
