import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import { SAMPLE_CONDITION, REJECTION_REASON } from '../../../app/config';
import { BloodMapsContext } from '../../../contexts/BloodMapsContext';
import { BloodSampleBundlesContext } from '../../../contexts/BloodSampleBundlesContext';
import { EdtaSamplesContext } from '../../../contexts/EdtaSamplesContext';
import { SerumSamplesContext } from '../../../contexts/SerumSamplesContext';
import { BloodMap, BloodSampleBundle, EdtaSample, SerumSample } from '../../../types';
import { DateWrapper } from '../../Wrapper/DateWrapper';
import { InputWrapper } from '../../Wrapper/InputWrapper';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';
import { SelectWrapper } from '../../Wrapper/SelectWrapper';
import { YesNoUnknownWrapper } from '../../Wrapper/YesNoUnknownWrapper';
import { YESNOUNKNOWN } from '../../../app/constants';

interface SampleEditProps {
    submitSample: (bloodSampleBundleId: number) => void;
    dismissHandler: () => void;
    isShown: boolean;
}

export const SampleEdit: React.FC<SampleEditProps> = ({ submitSample, dismissHandler, isShown }) => {
    const { bloodSampleBundleId } = useParams();

    const { bloodSampleBundles, updateBloodSampleBundleAttributeForBloodSampleBundleId } =
        useContext(BloodSampleBundlesContext);
    const { getBloodMapByBloodSampleBundleId, updateBloodMapAttributeForBloodMapId } = useContext(BloodMapsContext);
    const { getEdtaSampleByBloodSampleBundleId, updateEdtaSampleAttributeForEdtaSampleId } =
        useContext(EdtaSamplesContext);
    const { getSerumSampleByBloodSampleBundleId, updateSerumSampleAttributeForSerumSampleId } =
        useContext(SerumSamplesContext);

    const [bloodSampleBundle, setBloodSampleBundle] = useState<BloodSampleBundle>();
    const [bloodMap, setBloodMap] = useState<BloodMap>(null);
    const [edtaSample, setEdtaSample] = useState<EdtaSample>(null);
    const [serumSample, setSerumSample] = useState<SerumSample>(null);

    useEffect(() => {
        const bloodSampleB = bloodSampleBundles.find((sampleBundle) => sampleBundle.id === Number(bloodSampleBundleId));
        setBloodSampleBundle(bloodSampleB);
    }, [bloodSampleBundles, bloodSampleBundleId]);

    useEffect(() => {
        if (bloodSampleBundle) {
            const bloodM = getBloodMapByBloodSampleBundleId(Number(bloodSampleBundle.id));
            const edtaS = getEdtaSampleByBloodSampleBundleId(Number(bloodSampleBundle.id));
            const serumS = getSerumSampleByBloodSampleBundleId(Number(bloodSampleBundle.id));

            setBloodMap(bloodM);
            setEdtaSample(edtaS);
            setSerumSample(serumS);
        } else {
            setBloodMap(null);
            setEdtaSample(null);
            setSerumSample(null);
        }
    }, [
        bloodSampleBundle,
        getBloodMapByBloodSampleBundleId,
        getEdtaSampleByBloodSampleBundleId,
        getSerumSampleByBloodSampleBundleId,
    ]);

    const updateBloodSampleBundleAttribute = (delta: { [key: string]: any }) => {
        updateBloodSampleBundleAttributeForBloodSampleBundleId(Number(bloodSampleBundleId), delta);
    };
    const updateBloodMapAttribute = (delta: { [key: string]: any }) => {
        updateBloodMapAttributeForBloodMapId(bloodMap.id, delta);
    };
    const updateEdtaSampleAttribute = (delta: { [key: string]: any }) => {
        updateEdtaSampleAttributeForEdtaSampleId(edtaSample.id, delta);
    };
    const updateSerumSampleAttribute = (delta: { [key: string]: any }) => {
        updateSerumSampleAttributeForSerumSampleId(serumSample.id, delta);
    };

    const submitForm = () => {
        submitSample(Number(bloodSampleBundleId));
    };

    if (!bloodSampleBundle || !bloodMap || !edtaSample || !serumSample) {
        return <></>;
    }

    return (
        <ModalWrapper
            confirmHandler={submitForm}
            confirmText="Submit"
            dismissHandler={dismissHandler}
            dismissText={'Close'}
            header="Add new sample"
            isShown={isShown}
            restoreFocus={false}
            size="large"
            type="confirm"
        >
            <h6 className="mt-3 mb-3">EDTA for PrePsia Test</h6>
            <Row className="mb-3">
                <Col>
                    <InputWrapper
                        changeHandler={updateEdtaSampleAttribute}
                        id="approxVol"
                        label="Approx. Vol."
                        unit="ml"
                        value={edtaSample.approxVol}
                    />
                </Col>
                <Col>
                    <SelectWrapper
                        clickHandler={updateEdtaSampleAttribute}
                        id="sampleCondition"
                        items={SAMPLE_CONDITION}
                        label="Condition"
                        selected={edtaSample.sampleCondition}
                    />
                </Col>
            </Row>
            <div className="divider mt-4 mb-4" />
            <h6 className="mb-3 mt-3">Serum for PIGF Test</h6>
            <Row className="mb-3">
                <Col>
                    <InputWrapper
                        changeHandler={updateSerumSampleAttribute}
                        id="approxVol"
                        label="Approx. Vol."
                        unit="ml"
                        value={serumSample.approxVol}
                    />
                </Col>
                <Col>
                    <SelectWrapper
                        clickHandler={updateSerumSampleAttribute}
                        id="sampleCondition"
                        items={SAMPLE_CONDITION}
                        label="Condition"
                        selected={serumSample.sampleCondition}
                    />
                </Col>
            </Row>
            <div className="divider mt-4 mb-4" />
            <h6 className="mb-3">Sample</h6>
            <Row className="mb-3">
                <Col>
                    <DateWrapper
                        blurHandler={updateBloodSampleBundleAttribute}
                        id="dateTaken"
                        label="Date taken"
                        value={bloodSampleBundle.dateTaken}
                    />
                </Col>
                <Col>
                    <DateWrapper
                        blurHandler={updateBloodSampleBundleAttribute}
                        id="dateReceived"
                        label="Date received"
                        value={bloodSampleBundle.dateReceived}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <YesNoUnknownWrapper
                        clickHandler={updateBloodSampleBundleAttribute}
                        id="sampleAccepted"
                        label="Sample accepted"
                        selected={bloodSampleBundle.sampleAccepted}
                        noUnknown
                    />
                </Col>
                <Col>
                    {bloodSampleBundle.sampleAccepted === YESNOUNKNOWN.NO && (
                        <SelectWrapper
                            clickHandler={updateBloodSampleBundleAttribute}
                            id="rejectionReason"
                            items={REJECTION_REASON}
                            label="Rejection Reason"
                            selected={bloodSampleBundle.rejectionReason}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputWrapper
                        changeHandler={updateBloodSampleBundleAttribute}
                        id="sampleNotes"
                        label="Sample notes"
                        value={bloodSampleBundle.sampleNotes}
                    />
                </Col>
                <Col />
            </Row>

            <div className="divider mt-4 mb-4" />
            <h6 className="mb-3">Mean Arterial Pressure (MAP) (mmHg)</h6>
            <Row className="mb-3">
                <Col>
                    <DateWrapper
                        blurHandler={updateBloodSampleBundleAttribute}
                        id="mapDateRecorded"
                        label="MAP Date Recorded"
                        value={bloodSampleBundle.mapDateRecorded}
                    />
                </Col>
                <Col />
            </Row>
            <h6>Measurement 1</h6>

            <Row className="mb-3">
                <Col>
                    <InputWrapper
                        changeHandler={updateBloodMapAttribute}
                        id="systolicBpLeftArmMeasurement1"
                        label="Systolic BP left arm"
                        unit="mmHg"
                        value={bloodMap.systolicBpLeftArmMeasurement1}
                    />
                </Col>
                <Col>
                    <InputWrapper
                        changeHandler={updateBloodMapAttribute}
                        id="systolicBpRightArmMeasurement1"
                        label="Systolic BP right arm"
                        unit="mmHg"
                        value={bloodMap.systolicBpRightArmMeasurement1}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <InputWrapper
                        changeHandler={updateBloodMapAttribute}
                        id="diastolicBpLeftArmMeasurement1"
                        label="Diastolic BP left arm"
                        unit="mmHg"
                        value={bloodMap.diastolicBpLeftArmMeasurement1}
                    />
                </Col>
                <Col>
                    <InputWrapper
                        changeHandler={updateBloodMapAttribute}
                        id="diastolicBpRightArmMeasurement1"
                        label="Diastolic BP right arm"
                        unit="mmHg"
                        value={bloodMap.diastolicBpRightArmMeasurement1}
                    />
                </Col>
            </Row>
            <h6>Measurement 2</h6>
            <Row className="mb-3">
                <Col>
                    <InputWrapper
                        changeHandler={updateBloodMapAttribute}
                        id="systolicBpLeftArmMeasurement2"
                        label="Systolic BP left arm"
                        unit="mmHg"
                        value={bloodMap.systolicBpLeftArmMeasurement2}
                    />
                </Col>
                <Col>
                    <InputWrapper
                        changeHandler={updateBloodMapAttribute}
                        id="systolicBpRightArmMeasurement2"
                        label="Systolic BP right arm"
                        unit="mmHg"
                        value={bloodMap.systolicBpRightArmMeasurement2}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <InputWrapper
                        changeHandler={updateBloodMapAttribute}
                        id="diastolicBpLeftArmMeasurement2"
                        label="Diastolic BP left arm"
                        unit="mmHg"
                        value={bloodMap.diastolicBpLeftArmMeasurement2}
                    />
                </Col>
                <Col>
                    <InputWrapper
                        changeHandler={updateBloodMapAttribute}
                        id="diastolicBpRightArmMeasurement2"
                        label="Diastolic BP right arm"
                        unit="mmHg"
                        value={bloodMap.diastolicBpRightArmMeasurement2}
                    />
                </Col>
            </Row>
        </ModalWrapper>
    );
};
