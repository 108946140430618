import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Req } from '../Req/Req';
import { ValidationWrapper } from './ValidationWrapper';

interface InputWrapperProps {
    blurHandler?: (delta: { [key: string]: string }) => void;
    changeHandler: (delta: { [key: string]: string }) => void;
    defaultValue?: any;
    id: string;
    isDisabled?: boolean;
    label?: string;
    placeholder?: string;
    required?: boolean;
    textClass?: string;
    type?: 'text' | 'date';
    unit?: string;
    value?: any;
}

export const InputWrapper: React.FC<InputWrapperProps> = ({
    textClass = '',
    label,
    id,
    changeHandler,
    blurHandler = changeHandler,
    type = 'text',
    required = false,
    placeholder = '',
    unit,
    value,
    isDisabled = false,
}) => {
    return (
        <>
            <ValidationWrapper attribute={id} label={label}>
                <InputGroup>
                    {label && (
                        <InputGroup.Text className={textClass} id={id}>
                            {label} {required && <Req />}
                        </InputGroup.Text>
                    )}
                    <Form.Control
                        aria-describedby={id}
                        aria-label={label}
                        disabled={isDisabled}
                        placeholder={placeholder}
                        type={type}
                        value={value || ''}
                        onBlur={(event) => {
                            blurHandler({ [id]: event.target.value });
                        }}
                        onChange={(event) => {
                            changeHandler({ [id]: event.target.value });
                        }}
                    />
                    {unit && <InputGroup.Text className="unit">{unit}</InputGroup.Text>}
                </InputGroup>
            </ValidationWrapper>
        </>
    );
};
