import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { PlateMapsContext } from '../../contexts/PlateMapsContext';
import { ReagentEntry } from './ReagentEntry';

interface PrepsiaReagentsProps {}

export const PrepsiaReagents: React.FC<PrepsiaReagentsProps> = () => {
    const { plateMapId } = useParams();
    const { getPlateMapById, updatePlateMapAttributeForPlateMapId } = useContext(PlateMapsContext);

    const update = (delta: { [key: string]: string }) => {
        updatePlateMapAttributeForPlateMapId(delta, Number(plateMapId));
    };

    const plateMap = getPlateMapById(Number(plateMapId));

    if (!plateMap) return <></>;

    return (
        <div className="prepsia-reagents">
            <h4>Batch Records: Reagents</h4>
            <h6 className="mt-5">QC Reagents</h6>

            <ReagentEntry id="qcLowBatch" label="QC Low Batch No." plateMap={plateMap} update={update} variant="low" />
            <ReagentEntry
                id="qcBatch"
                label="QC Medium Batch No."
                plateMap={plateMap}
                update={update}
                variant="medium"
            />
            <ReagentEntry
                id="qcHighBatch"
                label="QC High Batch No."
                plateMap={plateMap}
                update={update}
                variant="high"
            />

            <h6 className="mt-5">Calibrator Reagents</h6>
            <ReagentEntry
                id="calBatch"
                label="Calibrator Batch No."
                plateMap={plateMap}
                update={update}
                variant="calibrator"
            />

            <h6 className="mt-5">Blank Reagents</h6>
            <ReagentEntry id="blankBatch" label="Blank Batch No." plateMap={plateMap} update={update} variant="blank" />

            <h6 className="mt-5">International Standard (ISTD) Reagents</h6>
            <ReagentEntry id="istdBatch" label="ISTD Batch No." plateMap={plateMap} update={update} />
            <ReagentEntry id="bilirubinBatch" label="ISTD Bilirubin Batch No." plateMap={plateMap} update={update} />

            <h6 className="mt-5">System Suitability Test</h6>
            <ReagentEntry id="sstBatch" label="SST Batch No." plateMap={plateMap} update={update} />

            <h6 className="mt-5">Sample Preparation</h6>
            <ReagentEntry id="crashBatch" label="Protein Precipitation (CRASH)" plateMap={plateMap} update={update} />
        </div>
    );
};
