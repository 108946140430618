import { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { getPatientById, updatePatientDataForId } from '../api/patient';
import { YESNOUNKNOWN } from '../app/constants';
import { debouncedSaveWithErrors } from '../helper/apiHelper';
import { Patient, ValidationError } from '../types';

interface PatientContextProps {
    clearPatient: () => void;
    loadPatientById: (id: number) => void;
    patient: Patient;
    patientErrors: ValidationError[];
    setPatientErrors: Dispatch<SetStateAction<ValidationError[]>>;
    updatePatientAttribute: (delta: { [key: string]: any }) => void;
}

export const PatientContext = createContext<PatientContextProps>(null);
PatientContext.displayName = 'PatientContext';

const patientDefaults: Patient = {
    anonymised: YESNOUNKNOWN.NO,
    consent: false,
    externalPatientId: '',
    id: null as number,
};

export const PatientContextProvider = (props: any) => {
    const [patient, setPatient] = useState(patientDefaults);
    const [patientErrors, setPatientErrors] = useState<ValidationError[]>([]);
    const [debounceFunctions, setDebounceFunctions] = useState<{ [key: string]: any }>({});

    const clearPatient = useCallback(() => {
        setPatient(patientDefaults);
    }, []);

    const updatePatientAttribute = async (delta: { [key: string]: any }) => {
        const attribute = Object.keys(delta)[0];
        let debounceFunction = debounceFunctions[attribute];

        if (!debounceFunctions[attribute]) {
            debounceFunction = debouncedSaveWithErrors({
                callApi: updatePatientDataForId,
                setErrors: setPatientErrors,
            });
            setDebounceFunctions({ ...debounceFunctions, [attribute]: debounceFunction });
        }

        await debounceFunction(delta, patient.id);
        setPatient({ ...patient, ...delta });
    };

    const loadPatientById = useCallback(async (patientId: number) => {
        const patientWithLinks = await getPatientById(patientId);
        const { _links, ...patientWithoutLinks } = patientWithLinks;
        setPatient(patientWithoutLinks);
    }, []);

    return (
        <PatientContext.Provider
            value={{
                clearPatient,
                loadPatientById,
                patient,
                patientErrors,
                setPatientErrors,
                updatePatientAttribute,
            }}
        >
            {props.children}
        </PatientContext.Provider>
    );
};
