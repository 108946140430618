import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import logo from '../../resources/logo.png';
import { ReactComponent as HeaderIcon } from '../../resources/icons/home.svg';

import './Header.scss';
import clsx from 'clsx';
import { SettingsContext } from '../../contexts/SettingsContext';
import AuthenticationService from '../../helper/AuthenticationService';

interface HeaderProps {
    children?: any;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
    const navigate = useNavigate();
    const headerRef = useRef<HTMLDivElement>();
    const { setHeaderHeight } = useContext(SettingsContext);
    const [isTop, setIsTop] = useState(true);

    const handleLogout = () => {
        AuthenticationService.doLogout({
            redirectUri: window.location.origin + '/home',
        });
    };

    useEffect(() => {
        const checkScrollY = () => setIsTop(window.scrollY === 0);

        window.addEventListener('scroll', checkScrollY);
        checkScrollY();

        return () => window.removeEventListener('scroll', checkScrollY);
    }, []);

    useEffect(() => {
        setHeaderHeight(headerRef?.current?.getBoundingClientRect()?.height || 0);
    }, [setHeaderHeight]);

    return (
        <div className={clsx('header', { 'header--not-top': !isTop })} ref={headerRef}>
            <Navbar className="header__navbar">
                <Container>
                    <Navbar.Brand onClick={() => navigate('/home')}>
                        <img alt="logo" id="logo" src={logo} />
                    </Navbar.Brand>
                    <Button size="sm" variant="outline-danger" onClick={handleLogout}>
                        Logout
                    </Button>
                </Container>
            </Navbar>
            {children && (
                <div className="header__sub-wrapper">
                    <Container className="header__sub">
                        <div className="header__content">{children}</div>
                        <HeaderIcon className="header__home" onClick={() => navigate('/home')} />
                    </Container>
                </div>
            )}
        </div>
    );
};

export default Header;
