import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

import { ReactComponent as SuccessIcon } from '../../../resources/icons/eye.svg';

interface PlateMapSuccessProps {
    dismissHandler: () => void;
    isShown: boolean;
}

export const PlateMapSuccess: React.FC<PlateMapSuccessProps> = ({ dismissHandler, isShown }) => {
    const navigate = useNavigate();

    const { plateMapId } = useParams();

    return (
        <ModalWrapper
            confirmHandler={() => navigate('/home')}
            confirmText="Home"
            dismissHandler={dismissHandler}
            header="Success"
            icon={<SuccessIcon />}
            isShown={isShown}
            type="confirm"
        >
            <p>Plate Map for RUN: {plateMapId} successfully registered</p>
        </ModalWrapper>
    );
};
