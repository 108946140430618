import { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
    createSerumSampleForBloodSampleBundle,
    CreateSerumSampleParams,
    deleteSerumSampleById,
    getSerumSampleByBloodSampleBundleId as getSerumSampleByBundleId,
    updateSerumSampleById,
} from '../api/serumSample';
import { debouncedSaveWithErrors } from '../helper/apiHelper';
import { modifyObjectInArrayById } from '../helper/arrayHelper';
import { SerumSample, SerumSampleWithBloodSampleBundleId, ValidationError } from '../types';

interface SerumSamplesContextProps {
    clearSerumSamples: () => void;
    createNewSerumSampleForBloodSampleBundle: ({
        bloodSampleBundleId,
        data,
    }: CreateSerumSampleParams) => Promise<SerumSample>;
    deleteSerumSampleByBloodSampleBundleId: (bloodSampleBundleId: number) => void;
    loadSerumSamplesForbloodSampleBundlesIds: (bloodSampleBundlesIds: number[]) => void;
    getSerumSampleByBloodSampleBundleId: (bloodSampleBundleId: number) => SerumSampleWithBloodSampleBundleId;
    serumSamples: SerumSampleWithBloodSampleBundleId[];
    serumSamplesErrors: ValidationError[];
    setSerumSamplesErrors: Dispatch<SetStateAction<ValidationError[]>>;
    updateSerumSampleAttributeForSerumSampleId: (serumSampleId: number, delta: { [key: string]: any }) => void;
}

const serumSamplesDefaults: SerumSampleWithBloodSampleBundleId[] = [];

export const SerumSamplesContext = createContext<SerumSamplesContextProps>(null);
SerumSamplesContext.displayName = 'SerumSamplesContext';

export const SerumSamplesContextProvider = (props: any) => {
    const [serumSamples, setSerumSamples] = useState(serumSamplesDefaults);
    const [serumSamplesErrors, setSerumSamplesErrors] = useState<ValidationError[]>([]);
    const [debounceFunctions, setDebounceFunctions] = useState<{ [key: string]: any }>({});

    const clearSerumSamples = useCallback(() => {
        setSerumSamples(serumSamplesDefaults);
    }, []);

    const createNewSerumSampleForBloodSampleBundle = async ({ bloodSampleBundleId, data }: CreateSerumSampleParams) => {
        const serumSampleWithLinks = await createSerumSampleForBloodSampleBundle({ bloodSampleBundleId, data });
        const { _links, ...serumSampleWithoutLinks } = serumSampleWithLinks;
        setSerumSamples([...serumSamples, { ...serumSampleWithoutLinks, bloodSampleBundleId }]);
        return serumSampleWithoutLinks;
    };

    const deleteSerumSampleByBloodSampleBundleId = async (bloodSampleBundleId: number) => {
        const serumSample = getSerumSampleByBloodSampleBundleId(bloodSampleBundleId);
        if (serumSample) {
            await deleteSerumSampleById(serumSample.id);
            setSerumSamples(
                serumSamples.filter((serumSample) => serumSample.bloodSampleBundleId !== bloodSampleBundleId)
            );
        }
    };

    const getSerumSampleByBloodSampleBundleId = (bloodSampleBundleId: number) => {
        return serumSamples.find((serumSample) => serumSample.bloodSampleBundleId === bloodSampleBundleId);
    };

    const loadSerumSamplesForbloodSampleBundlesIds = useCallback(async (bloodSampleBundlesIds: number[]) => {
        const serumSamples: SerumSampleWithBloodSampleBundleId[] = [];

        for (const bloodSampleBundleId of bloodSampleBundlesIds) {
            const serumSampleWithLinks = await getSerumSampleByBundleId(bloodSampleBundleId);
            const { _links, ...serumSampleWithoutLinks } = serumSampleWithLinks;
            serumSamples.push({ ...serumSampleWithoutLinks, bloodSampleBundleId });
        }

        setSerumSamples(serumSamples);
    }, []);

    const updateSerumSampleAttributeForSerumSampleId = useCallback(
        async (serumSampleId: number, delta: { [key: string]: any }) => {
            const attribute = Object.keys(delta)[0];
            let debounceFunction = debounceFunctions[attribute];

            if (!debounceFunctions[attribute]) {
                debounceFunction = debouncedSaveWithErrors({
                    callApi: updateSerumSampleById,
                    setErrors: setSerumSamplesErrors,
                });
                setDebounceFunctions({ ...debounceFunctions, [attribute]: debounceFunction });
            }

            await debounceFunction(delta, serumSampleId);

            const updatedSerumSamples = modifyObjectInArrayById({
                array: serumSamples,
                delta,
                id: serumSampleId,
            });

            setSerumSamples(updatedSerumSamples);
        },
        [serumSamples, debounceFunctions]
    );

    return (
        <SerumSamplesContext.Provider
            value={{
                clearSerumSamples,
                createNewSerumSampleForBloodSampleBundle,
                deleteSerumSampleByBloodSampleBundleId,
                getSerumSampleByBloodSampleBundleId,
                loadSerumSamplesForbloodSampleBundlesIds,
                serumSamples,
                serumSamplesErrors,
                setSerumSamplesErrors,
                updateSerumSampleAttributeForSerumSampleId,
            }}
        >
            {props.children}
        </SerumSamplesContext.Provider>
    );
};
