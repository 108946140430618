import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";

export const getSmoker = async (id: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.patients}/${id}/smoker`);

    return result.data;
};

export const updateSmokerForPatientId = async (delta: { [key: string]: any }, id: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.smokerdiseases}/${id}`, {
        ...delta
    });
}