import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SectionHeader } from '../SectionHeader/SectionHeader';

import { ReactComponent as EditIcon } from '../../resources/icons/edit.svg';

import './RecordDetails.scss';
import { useNavigate } from 'react-router';
import { PatientContext } from '../../contexts/PatientContext';
import { YESNOUNKNOWN } from '../../app/config';
import { YESNOUNKNOWN as YESNOUNKNOWNEnum } from '../../app/constants';
import { PatientD } from './PatientD';
import { BiophysicalD } from './BiophysicalD';
import { PregnancyD } from './PregnancyD';
import { PatientConstent } from './PatientConstent';
import { Clinical } from './Clinical';

interface RecordDetailsProps {
    editable?: boolean;
    className?: string;
}

export const RecordDetails: React.FC<RecordDetailsProps> = ({ editable = false, className }) => {
    const navigate = useNavigate();

    const { patient } = useContext(PatientContext);

    return (
        <div className={`record-details ${className}`}>
            {editable && (
                <SectionHeader>
                    <EditIcon className="icon-as-btn" onClick={() => navigate(`/patientForm/${patient.id}`)} />
                </SectionHeader>
            )}
            <Row>
                <Col className="highlighted mb-5" xl={8} xs={12}>
                    <Row>
                        <Col>
                            <ul className="record-details__main">
                                <li>
                                    <span>Patient ID:</span>
                                    <span>{patient.id}</span>
                                </li>
                                {patient.anonymised === YESNOUNKNOWNEnum.YES && (
                                    <li>
                                        <span>Anonymised:</span>
                                        <span>{YESNOUNKNOWN.YES}</span>
                                    </li>
                                )}
                                {patient.anonymised === YESNOUNKNOWNEnum.NO && (
                                    <>
                                        <li>
                                            <span>Surname:</span>
                                            <span>{patient.surname}</span>
                                        </li>
                                        <li>
                                            <span>Forename:</span>
                                            <span>{patient.forename}</span>
                                        </li>
                                    </>
                                )}
                                <li>
                                    <span>DOB:</span>
                                    <span>{patient.birthDate}</span>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="mb-5" xl={5} xs={12}>
                    <PatientD className="mb-5" />
                    <BiophysicalD className="mb-5" />
                    <PregnancyD className="mb-5" />
                    <PatientConstent className="mb-5" />
                </Col>
                <Col className="mb-5" xl={{ span: 5, offset: 2 }} xs={12}>
                    <Clinical className="mb-5" />
                </Col>
            </Row>
        </div>
    );
};
