import { createContext, useCallback, useState } from 'react';
import { getThrombophilia, updateThrombophiliaForPatientId } from '../api/thrombophilia';
import { Thrombophilia } from '../app/config';
import { debouncedSaveWithErrors } from '../helper/apiHelper';

interface ThrombophiliaContextProps {
    clearThrombophilia: () => void;
    loadThrombophiliaForPatient: (patientId: number) => void;
    thrombophilia: Thrombophilia;
    updateThrombophilia: (delta: { thrombophilia: Thrombophilia }) => void;
}

export const ThrombophiliaContext = createContext<ThrombophiliaContextProps>(null);
ThrombophiliaContext.displayName = 'ThrombophiliaContext';

export const ThrombophiliaContextProvider = (props: any) => {
    const [thrombophilia, setThrombophilia] = useState<Thrombophilia>(null);
    const [thrombophiliaId, setThrombophiliaId] = useState<number>(null);

    const clearThrombophilia = useCallback(() => {
        setThrombophilia(null);
        setThrombophiliaId(null);
    }, []);

    const updateThrombophilia = async (delta: { thrombophilia: Thrombophilia }) => {
        await debouncedSaveWithErrors({
            callApi: updateThrombophiliaForPatientId,
            setErrors: () => {},
        })(delta, thrombophiliaId);
        setThrombophilia(delta.thrombophilia);
    };

    const loadThrombophiliaForPatient = useCallback(async (patientId: number) => {
        const { id, thrombophilia } = await getThrombophilia(patientId);
        setThrombophiliaId(id);
        setThrombophilia(thrombophilia);
    }, []);

    return (
        <ThrombophiliaContext.Provider
            value={{
                clearThrombophilia,
                loadThrombophiliaForPatient,
                thrombophilia,
                updateThrombophilia,
            }}
        >
            {props.children}
        </ThrombophiliaContext.Provider>
    );
};
