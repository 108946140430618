import React, { forwardRef, useContext, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { DateFilter } from './DateFilter';
import { StatusFilter } from './StatusFilter';

import './PatientList.scss';
import { getPatientList } from '../../api/patientList';
import { PatientTable } from './PatientTable';
import { PatientListContext } from '../../contexts/PatientListContext';
import { Pagination } from '../Pagination/Pagination';
import { PATIENTS_PER_PAGE } from '../../app/config';
import { NameSearch } from './NameSearch';

interface PatientListProps {}

export const PatientList = forwardRef<HTMLDivElement, PatientListProps>((props, ref) => {
    // hooks
    const {
        clearPatientList,
        currentPage,
        endDate,
        patientList,
        patientName,
        setCurrentPage,
        setPatientList,
        setTotal,
        sortKey,
        sortOrder,
        startDate,
        testStatusFilter,
        total,
    } = useContext(PatientListContext);

    // useEffect
    useEffect(() => {
        // Abort Controller to cancel function execution after unmount or update
        const abortController = new AbortController();
        const signal = abortController.signal;

        const makeRestCall = async () => {
            try {
                const { patientList, page } = await getPatientList({
                    endDate,
                    page: currentPage - 1,
                    patientName,
                    signal,
                    size: PATIENTS_PER_PAGE,
                    sortKey,
                    sortOrder,
                    startDate,
                    testStatusFilter,
                });
                setTotal(page.totalElements);
                setPatientList(patientList);
            } catch (error: any) {
                if (error.name === 'CanceledError') {
                    // intentional: do nothing if the promise was canceled
                    return;
                }
                console.error(error);
            }
        };
        makeRestCall();

        return () => {
            // this will cancel the fetch on unmount or update
            abortController.abort();
            clearPatientList();
        };
    }, [
        clearPatientList,
        currentPage,
        setPatientList,
        setTotal,
        sortKey,
        sortOrder,
        testStatusFilter,
        patientName,
        startDate,
        endDate,
    ]);

    const minOneChecked = patientList.filter((record) => record.isChecked).length > 0;

    return (
        <>
            {/* TODO use when backend allows name search */}
            {/* <Container className="mt-5">
                <Row className="align-center">
                    <Col xs={{ offset: 3, span: 6 }}>
                        <NameSearch />
                    </Col>
                </Row>
            </Container> */}
            <div className="data-table mt-5">
                <Container fluid>
                    <div className="divider mt-4 mb-4" />
                    <DateFilter />
                    <div className="divider mt-4 mb-4" ref={ref} />
                    <StatusFilter />
                    <div className="divider mt-4 mb-5" />
                    <PatientTable items={patientList} />
                    {/* <TablePagination />*/}
                    <Pagination
                        currentPage={currentPage}
                        perPage={PATIENTS_PER_PAGE}
                        setCurrentPage={setCurrentPage}
                        total={total}
                    />
                    {minOneChecked && (
                        <div className="flex align-center">
                            <div>Marked entries:</div>
                            <div className="ml-3">
                                <Button variant="primary">Add to Plate</Button>
                            </div>
                        </div>
                    )}
                </Container>
            </div>
        </>
    );
});
