import { useCallback, useContext, useEffect } from "react";
import { BloodMapsContext } from "../contexts/BloodMapsContext";
import { EdtaSamplesContext } from "../contexts/EdtaSamplesContext";
import { SerumSamplesContext } from "../contexts/SerumSamplesContext";
import { isValidIntegerId } from "../helper/isValidId";

export function useLoadMapsAndSamplesForbloodSampleBundlesIds(bloodSampleBundlesIds: number[]) {
    const { loadBloodMapsForbloodSampleBundlesIds, clearBloodMaps } = useContext(BloodMapsContext);
    const { loadEdtaSamplesForbloodSampleBundlesIds, clearEdtaSamples } = useContext(EdtaSamplesContext);
    const { loadSerumSamplesForbloodSampleBundlesIds, clearSerumSamples } = useContext(SerumSamplesContext);

    const cleanup = useCallback(() => {
        clearBloodMaps();
        clearEdtaSamples();
        clearSerumSamples();
    }, [
        clearBloodMaps,
        clearEdtaSamples,
        clearSerumSamples
    ])

    useEffect(() => {
        if (bloodSampleBundlesIds.length > 0 && bloodSampleBundlesIds.every(isValidIntegerId)) {
            loadBloodMapsForbloodSampleBundlesIds(bloodSampleBundlesIds);
            loadEdtaSamplesForbloodSampleBundlesIds(bloodSampleBundlesIds);
            loadSerumSamplesForbloodSampleBundlesIds(bloodSampleBundlesIds);
        } else {
            cleanup();
        }

        return cleanup;
    }, [
        bloodSampleBundlesIds,
        cleanup,
        loadBloodMapsForbloodSampleBundlesIds,
        loadEdtaSamplesForbloodSampleBundlesIds,
        loadSerumSamplesForbloodSampleBundlesIds,
    ]);
}