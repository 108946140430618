import React, { useContext } from 'react';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import { PREGNANCY_STATE } from '../../app/constants';
import { PatientContext } from '../../contexts/PatientContext';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { Headline } from '../Headline/Headline';

interface PatientConsentProps {}

export const PatientConsent: React.FC<PatientConsentProps> = () => {
    const { patient, updatePatientAttribute } = useContext(PatientContext);
    const { pregnancy } = useContext(PregnancyContext);

    return (
        <div className="patient-consent mb-5 mt-5 form-section">
            <Headline headline="5. Patient Consent" requiredInfo />
            <p className="mb-4">
                The patient has consented to the use of their clinical, screening test and outcome data for the
                optimisation of PrePsia and the research and verelopment of other predictors of preeclapsia and other
                related conditions.
            </p>
            <Row>
                <Col xs={{ offset: 4, span: 4 }}>
                    <Stack className="mx-auto" direction="horizontal" gap={5}>
                        <Form.Check
                            checked={patient.consent || false}
                            disabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            id="consent"
                            label="Consent"
                            name="consent"
                            type="radio"
                            onChange={() => updatePatientAttribute({ consent: true })}
                        />
                        <Form.Check
                            checked={!patient.consent || false}
                            disabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            id="no_consent"
                            label="No consent"
                            name="consent"
                            type="radio"
                            onChange={() => updatePatientAttribute({ consent: false })}
                        />
                    </Stack>
                </Col>
            </Row>
        </div>
    );
};
