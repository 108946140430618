import React, { useEffect } from 'react';
import { usePrevious } from '../../hooks/usePrevious';
import { InputWrapper } from './InputWrapper';

interface DateWrapperProps {
    blurHandler: (delta: { [key: string]: string }) => void;
    id: string;
    isDisabled?: boolean;
    label?: string;
    placeholder?: string;
    required?: boolean;
    textClass?: string;
    unit?: string;
    value?: any;
}

export const DateWrapper: React.FC<DateWrapperProps> = ({
    textClass = '',
    label,
    id,
    blurHandler,
    required = false,
    placeholder = '',
    unit,
    value,
    isDisabled = false,
}) => {
    // state is saved locally, and then passed to the BE on blur
    // needs to be initiated with the value from the BE or null if there is no value
    const [state, setState] = React.useState<string>(value);
    const previousValue = usePrevious(value);

    // update state if value changes && value is not the same as before
    // -> updates when values are loaded from BE
    useEffect(() => {
        if (value !== previousValue) setState(value);
    }, [value, previousValue]);

    return (
        <InputWrapper
            blurHandler={() => {
                blurHandler({ [id]: state });
            }}
            changeHandler={(delta) => {
                setState(delta[id]);
            }}
            id={id}
            isDisabled={isDisabled}
            label={label}
            placeholder={placeholder}
            required={required}
            textClass={textClass}
            type="date"
            unit={unit}
            value={state}
        />
    );
};
