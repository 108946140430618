import React from 'react';
import { useNavigate } from 'react-router';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

import { ReactComponent as SetupTestIcon } from '../../../resources/icons/eye.svg';

interface PlateMapNewProps {
    createNewTest: () => void;
    isShown: boolean;
}

export const PlateMapNew: React.FC<PlateMapNewProps> = ({ createNewTest, isShown }) => {
    const navigate = useNavigate();

    return (
        <ModalWrapper
            confirmHandler={createNewTest}
            confirmText="Create"
            dismissHandler={() => navigate('/home')}
            header="Set up test"
            icon={<SetupTestIcon />}
            isShown={isShown}
            size="small"
            type="confirm"
        >
            <p>Register a new PlateMap?</p>
        </ModalWrapper>
    );
};
