import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

import './IconButton.scss';

interface IconButtonProps {
    Icon: FunctionComponent;
    label: string;
    notifications?: number;
    clickHandler: () => void;
    isDisabled?: boolean;
}

export const IconButton: React.FC<IconButtonProps> = ({
    Icon,
    label,
    clickHandler,
    notifications,
    isDisabled = false,
}) => {
    const classes = clsx('icon-button', {
        'icon-button--is-disabled': isDisabled,
    });
    return (
        <div className={classes} onClick={clickHandler}>
            {notifications > 0 && <div className="icon-button__notifications">{notifications}</div>}
            <div className="icon-button__icon">
                <Icon />
            </div>
            <p>{label}</p>
        </div>
    );
};
