import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { Links, Outcome } from "../types";
import { mapIncomingValues, mapOutgoingValues } from "./mapping";

interface OutcomeWithLinks extends Outcome, Links { };

export const getOutcomeByPregnancyId = async (id: number) => {
    const axios = HttpService.getAxiosClient();
    
    // TODO remove this mock
    return {} as OutcomeWithLinks;
    
    const result = await axios.get(`${endpoints.data.pregnancies}/${id}/outcome`);
    const mappedResults = mapIncomingValues(result.data.outcome);

    return (Object.fromEntries(Object.entries(mappedResults).filter(([_, v]) => v != null))) as unknown as OutcomeWithLinks;
};

export const updateOutcomeForId = async (delta: { [key: string]: any }, id: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.outcomes}/${id}`, {
        ...mapOutgoingValues(delta)
    });
}