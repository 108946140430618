import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { PatientListContext } from '../../contexts/PatientListContext';
import { formatDateYYYYMMDD } from '../../helper/dateHelper';
import { InputWrapper } from '../Wrapper/InputWrapper';

interface DateFilterProps {}

export const DateFilter: React.FC<DateFilterProps> = () => {
    const { setStartDate, setEndDate } = useContext(PatientListContext);
    const today = formatDateYYYYMMDD(new Date());
    const [start, setStart] = useState('');
    const [end, setEnd] = useState(today);

    const resetDate = () => {
        setStart('');
        setEnd(today);
        setStartDate(null);
        setEndDate(null);
    };

    // we need to use InputWrapper instead of DateWrapper here
    // since the state is handled manually in this component

    return (
        <div className="date-filter">
            <h6>Date Filter:</h6>
            <Row>
                <Col>
                    <InputWrapper
                        blurHandler={() => {
                            setStartDate(start);
                        }}
                        changeHandler={({ startDate }) => setStart(startDate)}
                        id="startDate"
                        label="Start"
                        type="date"
                        value={start}
                    />
                </Col>
                <Col>
                    <InputWrapper
                        blurHandler={() => {
                            setEndDate(end);
                        }}
                        changeHandler={({ endDate }) => setEnd(endDate)}
                        id="endDate"
                        label="End"
                        type="date"
                        value={end}
                    />
                </Col>
                <Col>
                    <Button className="btn--full" variant="primary" onClick={resetDate}>
                        Reset
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
