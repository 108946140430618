// @ts-nocheck

import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { ResultCalculation } from "../types";

export const importResults = async (file1: File, file2: File): Promise<ResultCalculation[]> => {

    const formData = new FormData();
    formData.append('files', file1);
    formData.append('files', file2);

    const axios = HttpService.getAxiosClient();
    
    const result = await axios.post(`${endpoints.result.importResults}`, formData);
    return result.data;
}

export const approveResult = async (plateMapEntryId: number) => {
    console.log('approved', plateMapEntryId);
}

export const rejectResult = async (plateMapEntryId: number) => {
    console.log('rejected', plateMapEntryId);
}
