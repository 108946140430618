import { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { getPregnancyByPatientId, updatePregnancyForId } from '../api/pregnancy';
import { debouncedSaveWithErrors } from '../helper/apiHelper';
import { Pregnancy, ValidationError } from '../types';

interface PregnancyContextProps {
    clearPregnancy: () => void;
    loadPregnancyForPatient: (id: number) => void;
    pregnancy: Pregnancy;
    pregnancyErrors: ValidationError[];
    setPregnancyErrors: Dispatch<SetStateAction<ValidationError[]>>;
    updatePregnancyAttribute: (delta: { [key: string]: any }) => void;
}

export const PregnancyContext = createContext<PregnancyContextProps>(null);
PregnancyContext.displayName = 'PregnancyContext';

const pregnancyDefaults: Pregnancy = {
    bloodSampleBundles: [],
    createdDate: '',
    id: null as number,
    priorFetalOutcomesDetails: [],
    priorOrganLesionDetails: [],
};

export const PregnancyContextProvider = (props: any) => {
    const [pregnancy, setPregnancy] = useState(pregnancyDefaults);
    const [pregnancyErrors, setPregnancyErrors] = useState<ValidationError[]>([]);
    const [debounceFunctions, setDebounceFunctions] = useState<{ [key: string]: any }>({});

    const updatePregnancyAttribute = async (delta: { [key: string]: any }) => {
        const attribute = Object.keys(delta)[0];
        let debounceFunction = debounceFunctions[attribute];

        if (!debounceFunctions[attribute]) {
            debounceFunction = debouncedSaveWithErrors({
                callApi: updatePregnancyForId,
                setErrors: setPregnancyErrors,
            });
            setDebounceFunctions({ ...debounceFunctions, [attribute]: debounceFunction });
        }

        await debounceFunction(delta, pregnancy.id);
        setPregnancy({ ...pregnancy, ...delta });
    };

    const clearPregnancy = useCallback(() => {
        setPregnancy(pregnancyDefaults);
    }, []);

    const loadPregnancyForPatient = useCallback(async (patientId: number) => {
        const pregnancyWithLinks = await getPregnancyByPatientId(patientId);
        const { _links, ...pregnancyWithoutLinks } = pregnancyWithLinks;
        setPregnancy(pregnancyWithoutLinks);
    }, []);

    return (
        <PregnancyContext.Provider
            value={{
                clearPregnancy,
                loadPregnancyForPatient,
                pregnancy,
                pregnancyErrors,
                setPregnancyErrors,
                updatePregnancyAttribute,
            }}
        >
            {props.children}
        </PregnancyContext.Provider>
    );
};
