import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routing } from './Routing';
import { PatientContextProvider } from './contexts/PatientContext';
import { PatientListContextProvider } from './contexts/PatientListContext';
import { SmokerContextProvider } from './contexts/SmokerContext';
import { AutoimmuneDiseaseContextProvider } from './contexts/AutoimmuneDiseaseContext';
import { ChronicKidneyDiseaseContextProvider } from './contexts/ChronicKidneyDiseaseContext';
import { HypertensionContextProvider } from './contexts/HypertensionContext';
import { ComposeComponents } from './helper/ComposeComponents';
import { ThrombophiliaContextProvider } from './contexts/ThrombophiliaContext';
import { PregnancyContextProvider } from './contexts/PregnancyContext';
import { ResidenceContextProvider } from './contexts/ResidenceContext';
import { OutcomeContextProvider } from './contexts/OutcomeContext';
import { SettingsContextProvider } from './contexts/SettingsContext';
import { BloodMapsContextProvider } from './contexts/BloodMapsContext';
import { BloodSampleBundlesContextProvider } from './contexts/BloodSampleBundlesContext';
import { EdtaSamplesContextProvider } from './contexts/EdtaSamplesContext';
import { SerumSamplesContextProvider } from './contexts/SerumSamplesContext';
import { PlateMapsContextProvider } from './contexts/PlateMapsContext';
import { SampleCandidatesContextProvider } from './contexts/SampleCandidatesContext';
import { DiabetesDiseaseContextProvider } from './contexts/DiabetesDiseaseContext';

function App() {
    return (
        <BrowserRouter>
            <ComposeComponents
                components={[
                    PatientContextProvider,
                    SmokerContextProvider,
                    AutoimmuneDiseaseContextProvider,
                    ChronicKidneyDiseaseContextProvider,
                    HypertensionContextProvider,
                    ThrombophiliaContextProvider,
                    PregnancyContextProvider,
                    ResidenceContextProvider,
                    PatientListContextProvider,
                    OutcomeContextProvider,
                    SettingsContextProvider,
                    BloodMapsContextProvider,
                    EdtaSamplesContextProvider,
                    SerumSamplesContextProvider,
                    BloodSampleBundlesContextProvider,
                    PlateMapsContextProvider,
                    SampleCandidatesContextProvider,
                    DiabetesDiseaseContextProvider,
                ]}
            >
                <div className="App">
                    <Routing />
                </div>
            </ComposeComponents>
        </BrowserRouter>
    );
}

export default App;
