import clsx from 'clsx';
import React from 'react';
import { GenericTable } from '../GenericTable/GenericTable';

import './RiskScore.scss';

interface RiskScoreProps {
    score: number;
}

export const RiskScore: React.FC<RiskScoreProps> = ({ score }) => {
    let positionFromLeft = 0;
    let risk = 'Low Risk';
    let cutOff = '1 in 1000';

    // calc
    if (score >= 100) {
        const range = 900; // between 1000 and 100 (included)
        const percentage = 1 - (score - 100) / range;
        positionFromLeft = 33 * percentage; // first 33%
    }

    if (score < 100) {
        const range = 98; // between 1 and 99 (included)
        const percentage = 1 - score / range;
        positionFromLeft = 66 * percentage + 33; // second 66%
    }

    if (score < 100 && score >= 50) {
        risk = 'Medium Risk';
        cutOff = '1 in 100';
    }

    if (score < 50) {
        risk = 'High Risk';
        cutOff = '1 in 10';
    }

    const classes = clsx('risk-score', {
        'risk-score--high': score < 50,
        'risk-score--low': score >= 100,
        'risk-score--medium': score < 100 && score >= 50,
    });

    const tableHeader = ['Risk identifier', 'Cut-off', 'PrePsia screening', 'Result'];
    const tableData = [['Preterm Preeclampsia', cutOff, `1 in ${score}`, <span className='risk-score__risk'>{risk}</span>]];

    return (
        <div className={classes}>
            <h4 className="mb-5">Preeclampsia Risk Score</h4>
            <div className="risk-score__indicator mb-5">
                <div className="risk-score__header">
                    <span>Low risk</span>
                    <span>Medium risk</span>
                    <span>High risk</span>
                </div>
                <div className="risk-score__areas">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="risk-score__values">
                    <span>1000</span>
                    <span>100</span>
                    <span>50</span>
                    <span>1</span>
                </div>
                <div className="risk-score__score" style={{ left: positionFromLeft + '%' }}>
                    1 in {score}
                </div>
            </div>
            <GenericTable data={tableData} header={tableHeader} />
        </div>
    );
};
