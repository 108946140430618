import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Headline } from '../Headline/Headline';

interface SubmitPatientDetailsProps {
    submitHandler: () => void;
}

export const SubmitPatientDetails: React.FC<SubmitPatientDetailsProps> = ({ submitHandler }) => {
    const submitForm = () => {
        // TODO POST
        submitHandler();
    };
    return (
        <div className="submit-patient-details mb-5 mt-5 form-section">
            <Headline headline="6. Submit patient details" requiredInfo />
            <p className="mb-3">
                Add patient record to the MDx Portal database by selcting "Submit details". For each new submission a
                new Patient ID will be generated.
            </p>
            <Row>
                <Col xs={{ offset: 4, span: 4 }}>
                    <Button className="btn--full" variant="primary" onClick={submitForm}>
                        Submit details
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
