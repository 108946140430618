import React, { useContext } from 'react';
import { PregnancyContext } from '../../contexts/PregnancyContext';

interface BiophysicalDProps {
    className?: string;
}

export const BiophysicalD: React.FC<BiophysicalDProps> = ({ className = '' }) => {
    const { pregnancy } = useContext(PregnancyContext);

    return (
        <div className={className}>
            <h5>Biophysical Data:</h5>
            <ul>
                <li>
                    <span>UtAD-Pl (left):</span>
                    <span>{pregnancy.uterineArteryDopplerLeft}</span>
                </li>
                <li>
                    <span>UtAD-Pl (right):</span>
                    <span>{pregnancy.uterineArteryDopplerRight}</span>
                </li>
                <li>
                    <span>Date Recorded:</span>
                    <span>{pregnancy.dopplerDateRecorder}</span>
                </li>
                <li>
                    <span>UtAD-Pl Sonographer:</span>
                    <span>{pregnancy.dopplerSonographer}</span>
                </li>
                <li>
                    <span>Reg/Cert No.:</span>
                    <span>{pregnancy.certNumber}</span>
                </li>
            </ul>
        </div>
    );
};
