import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { BloodMap, Links } from "../types";
import { mapIncomingValues, mapOutgoingValues } from "./mapping";

interface BloodMapWithLinks extends BloodMap, Links { };

export const getBloodMapByBloodSampleBundleId = async (bloodSampleBundleId: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.bloodsamplebundles}/${bloodSampleBundleId}/bloodMap`)

    return mapIncomingValues(result.data) as BloodMapWithLinks;
};

export interface CreateBloodMapParams {
    bloodSampleBundleId: number;
    data?: BloodMap;
}

export const createBloodMapForBloodSampleBundle = async ({ bloodSampleBundleId, data = {} }: CreateBloodMapParams) => {
    const axios = HttpService.getAxiosClient();

    const result = await axios.post(`${endpoints.data.bloodmaps}`, {
        bloodSampleBundle: `${endpoints.data.bloodsamplebundles}/${bloodSampleBundleId}`,
        data
    });

    return result.data as BloodMapWithLinks;
}

export const deleteBloodMapById = async (bloodMapId: number) => {
    const axios = HttpService.getAxiosClient();

    return await axios.delete(`${endpoints.data.bloodmaps}/${bloodMapId}`);
}

export const updateBloodMapById = async (delta: { [key: string]: any }, bloodMapId: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.bloodmaps}/${bloodMapId}`, {
        ...mapOutgoingValues(delta)
    });
}

export const getBloodSampleBundleIdForBloodMapId = async (bloodMapId: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.bloodmaps}/${bloodMapId}/bloodSampleBundle`);

    return result.data.id
}