import React from 'react';
import './SectionHeader.scss';

interface SectionHeaderProps {
    title?: string;
    children?: any;
    hideDivider?: boolean;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({ title = '', children, hideDivider = false }) => {
    return (
        <>
            {!hideDivider && <div className="divider mt-4 mb-4" />}
            <div className="section-header mb-4 ">
                <span className="section-header__title">{title}</span>
                <div className="section-header__actions">{children}</div>
            </div>
        </>
    );
};
