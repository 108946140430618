import React, { useContext, useState } from 'react';
import { TableOutcome } from './TableOutcome';

import { ReactComponent as EditIcon } from '../../resources/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../resources/icons/delete.svg';
import { ReactComponent as BloodTypeIcon } from '../../resources/icons/bloodtype.svg';
import { ReactComponent as RefreshIcon } from '../../resources/icons/refresh.svg';
import { ReactComponent as EyeIcon } from '../../resources/icons/eye.svg';
import { ReactComponent as AddPIGFIcon } from '../../resources/icons/addPIGF.svg';
import { Form } from 'react-bootstrap';
import { ModalWrapper } from '../Wrapper/ModalWrapper';
import { useNavigate } from 'react-router';
import { PatientListContext, PatientListEntry } from '../../contexts/PatientListContext';
import { deletePatientById } from '../../api/patient';

import './TableEntry.scss';

interface TableEntryProps extends PatientListEntry {
    handleCheckbox: () => void;
    isMinimal?: boolean;
}

export const TableEntry: React.FC<TableEntryProps> = ({
    birthDate,
    forename,
    handleCheckbox,
    id,
    isChecked,
    isMinimal = false,
    pregnancies,
    surname,
}) => {
    const navigate = useNavigate();
    const { forceUpdate } = useContext(PatientListContext);

    const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);
    const [toDeleteId, setToDeleteId] = useState<number>(null);

    const currentPregnancy = pregnancies[0];

    const handleDelete = async () => {
        try {
            await deletePatientById(toDeleteId);
            setShowDeleteOverlay(false);
            forceUpdate();
        } catch (e) {
            setShowDeleteOverlay(false);
            console.error(e);
        }
    };

    return (
        <>
            <div className="table-entry data-table__item">
                <Form.Check checked={isChecked} type="checkbox" onClick={handleCheckbox} />
                <div>
                    {isMinimal && id}
                    {!isMinimal && (
                        <button className="as-link" onClick={() => navigate(`/patientRecord/${id}`)}>
                            {id}
                        </button>
                    )}
                </div>
                <div>{surname}</div>
                <div>{forename}</div>
                <div>{birthDate}</div>
                {!isMinimal && (
                    <>
                        <div>{currentPregnancy.testRun}</div>
                        <div>{currentPregnancy.testStatus}</div>
                        <div className="table-entry__risk text-center">
                            {/* TODO reintegrate when BE supports risk results */}
                            {/* TODO check if risk result is needed in text form */}
                            {/* <span>{currentPregnancy.riskResult}</span>{' '} */}
                            {/* {currentPregnancy.riskResult !== 'FAILED' && <EyeIcon className='icon-as-btn' onClick={() => navigate(`/riskResult/${id}`)} />} */}
                        </div>
                        <div className="text-center">
                            {true && (
                                <AddPIGFIcon
                                    className="icon-as-btn"
                                    onClick={() => navigate(`/patientRecord/${id}/addPIGF`)}
                                />
                            )}
                        </div>
                        <div className="table-entry__outcome text-center">
                            {/* TODO reintegrate when BE supports outcomes */}
                            {/* <TableOutcome
                                addHandler={() => {
                                    navigate(`/patientRecord/${id}/addOutcome`);
                                }}
                                outcome={currentPregnancy.outcome}
                                viewHandler={() => {
                                    navigate(`/patientRecord/${id}`);
                                }}
                            /> */}
                        </div>
                        <div className="table-entry__actions">
                            <BloodTypeIcon
                                className="icon-as-btn"
                                onClick={() => navigate(`/patientRecord/${id}/addBloodSample`)}
                            />

                            {/* TODO reintegrate when BE supports refresh */}
                            {/* <RefreshIcon
                                    className="icon-as-btn"
                                    onClick={() => navigate(`/patientRecord/${id}/doSomething`)}
                                /> */}

                            <EditIcon className="icon-as-btn" onClick={() => navigate(`/patientForm/${id}`)} />
                            <DeleteIcon
                                className="icon-as-btn"
                                onClick={() => {
                                    setShowDeleteOverlay(true);
                                    setToDeleteId(id);
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
            {!isMinimal && showDeleteOverlay && (
                <ModalWrapper
                    confirmHandler={handleDelete}
                    dismissHandler={() => {
                        setShowDeleteOverlay(false);
                        setToDeleteId(null);
                    }}
                    header="Deleting"
                    icon="danger"
                    isShown={showDeleteOverlay}
                    type="confirm"
                >
                    <p>Are you sure, you want to delete this Dataset including data of</p>
                    <ul>
                        <li> Patient </li>
                        <li> Pregnancy </li>
                        <li> Sample </li>
                        <li> Test results </li>
                        <li> Risk results </li>
                    </ul>
                    <p>It is currently not possible to delete patients with an existing blood sample.</p>
                </ModalWrapper>
            )}
        </>
    );
};
