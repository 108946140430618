import { createContext, useCallback, useState } from 'react';
import { createPlateMap, getFilledPlateMapById, getPlateMaps, updatePlateMapById } from '../api/plateMaps';
import { PLATEMAPS_PER_PAGE } from '../app/config';
import { debouncedSaveWithErrors } from '../helper/apiHelper';
import { modifyObjectInArrayById } from '../helper/arrayHelper';
import { PlateMap, ValidationError } from '../types';

interface PlateMapsContextProps {
    createNewPlateMap: () => Promise<PlateMap>;
    currentPage: number;
    loadPlateMaps: () => void;
    loadPlateMapById: (plateMapId: number) => void;
    getPlateMapById: (plateMapId: number) => PlateMap;
    plateMaps: PlateMap[];
    plateMapsErrors: ValidationError[];
    setCurrentPage: (currentPage: number) => void;
    totalPlateMaps: number;
    updatePlateMapAttributeForPlateMapId: (delta: { [key: string]: any }, plateMapId: number) => void;
}

export const PlateMapsContext = createContext<PlateMapsContextProps>(null);
PlateMapsContext.displayName = 'PlateMapsContext';

const PlateMapsDefaults: PlateMap[] = [];

export const PlateMapsContextProvider = (props: any) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPlateMaps, setTotalPlateMaps] = useState(0);
    const [plateMaps, setPlateMaps] = useState(PlateMapsDefaults);
    const [debounceFunctions, setDebounceFunctions] = useState<{ [key: string]: any }>({});
    const [plateMapsErrors, setPlateMapsErrors] = useState<ValidationError[]>([]);

    const createNewPlateMap = async () => {
        const plateMap = await createPlateMap();
        const { _links, ...plateMapWithoutLinks } = plateMap;

        setPlateMaps([plateMapWithoutLinks]);

        return plateMapWithoutLinks;
    };

    const getPlateMapById = (plateMapId: number) => {
        return plateMaps.find((plateMap) => plateMap.id === plateMapId);
    };

    const loadPlateMapById = useCallback(async (plateMapId: number) => {
        const { _links, ...plateMapWithoutLinks } = await getFilledPlateMapById(plateMapId);
        setPlateMaps([plateMapWithoutLinks]);
    }, []);

    const loadPlateMaps = useCallback(async () => {
        const loadedPlateMaps = await getPlateMaps(PLATEMAPS_PER_PAGE, currentPage - 1);
        setTotalPlateMaps(loadedPlateMaps.page.totalElements);
        const loadedPlateMapsWithoutLinks: PlateMap[] = [];

        loadedPlateMaps.data.forEach((plateMap) => {
            const { _links, ...plateMapWithoutLinks } = plateMap;
            loadedPlateMapsWithoutLinks.push(plateMapWithoutLinks);
        });

        setPlateMaps(loadedPlateMapsWithoutLinks);
    }, [currentPage]);

    const updatePlateMapAttributeForPlateMapIdWithoutSaving = useCallback(
        (delta: { [key: string]: any }, plateMapId: number) => {
            const updatedPlateMaps = modifyObjectInArrayById({
                array: plateMaps,
                delta,
                id: plateMapId,
            });

            setPlateMaps(updatedPlateMaps);
        },
        [plateMaps]
    );

    const updatePlateMapAttributeForPlateMapId = useCallback(
        async (delta: { [key: string]: any }, plateMapId: number) => {
            const attribute = Object.keys(delta)[0];
            let debounceFunction = debounceFunctions[attribute];

            if (!debounceFunctions[attribute]) {
                debounceFunction = debouncedSaveWithErrors({
                    callApi: updatePlateMapById,
                    setErrors: setPlateMapsErrors,
                });
                setDebounceFunctions({ ...debounceFunctions, [attribute]: debounceFunction });
            }

            await debounceFunction(delta, plateMapId);

            updatePlateMapAttributeForPlateMapIdWithoutSaving(delta, plateMapId);
        },
        [debounceFunctions, updatePlateMapAttributeForPlateMapIdWithoutSaving]
    );

    return (
        <PlateMapsContext.Provider
            value={{
                createNewPlateMap,
                currentPage,
                getPlateMapById,
                loadPlateMapById,
                loadPlateMaps,
                plateMaps,
                plateMapsErrors,
                setCurrentPage,
                totalPlateMaps,
                updatePlateMapAttributeForPlateMapId,
            }}
        >
            {props.children}
        </PlateMapsContext.Provider>
    );
};
