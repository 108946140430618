import React from 'react';
import { ButtonGroup, InputGroup, Stack, ToggleButton } from 'react-bootstrap';
import { YESNOUNKNOWN } from '../../app/constants';
import { Req } from '../Req/Req';
import './YesNoUnknownWrapper.scss';

interface YesNoUnknownWrapperProps {
    attribute?: string;
    clickHandler: (event: any) => void;
    isDisabled?: boolean;
    id: string;
    label: string;
    noUnknown?: boolean;
    required?: boolean;
    selected: string;
}

export const YesNoUnknownWrapper: React.FC<YesNoUnknownWrapperProps> = ({
    attribute,
    isDisabled = false,
    label,
    id,
    clickHandler,
    required = false,
    selected,
    noUnknown = false,
}) => {
    let item_attribute = id;

    if (attribute) {
        item_attribute = attribute;
    }

    return (
        <Stack className="yesnounknown-wrapper" direction="horizontal" gap={3}>
            <InputGroup>
                <InputGroup.Text id={id}>
                    {label} {required && <Req />}
                </InputGroup.Text>
            </InputGroup>
            <ButtonGroup>
                <ToggleButton
                    checked={selected === YESNOUNKNOWN.YES}
                    disabled={isDisabled}
                    id={id + '_yes'}
                    name={id + '_' + item_attribute}
                    type="radio"
                    value="yes"
                    variant="outline-primary"
                    onClick={() => clickHandler({ [item_attribute]: YESNOUNKNOWN.YES })}
                >
                    Yes
                </ToggleButton>
                <ToggleButton
                    checked={selected === YESNOUNKNOWN.NO}
                    disabled={isDisabled}
                    id={id + '_no'}
                    name={id + '_' + item_attribute}
                    type="radio"
                    value="no"
                    variant="outline-primary"
                    onClick={() => clickHandler({ [item_attribute]: YESNOUNKNOWN.NO })}
                >
                    No
                </ToggleButton>
                {!noUnknown && (
                    <ToggleButton
                        checked={selected === YESNOUNKNOWN.UNKNOWN}
                        disabled={isDisabled}
                        id={id + '_unknown'}
                        name={id + '_' + item_attribute}
                        type="radio"
                        value="unknown"
                        variant="outline-primary"
                        onClick={() => clickHandler({ [item_attribute]: YESNOUNKNOWN.UNKNOWN })}
                    >
                        Unknown
                    </ToggleButton>
                )}
            </ButtonGroup>
        </Stack>
    );
};
