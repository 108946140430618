import React from 'react';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

interface PlateMapErrorProps {
    deleteHandler: () => void;
    dismissHandler: () => void;
    isErrorShown: boolean;
    failedSamples: string[];
}

export const PlateMapError: React.FC<PlateMapErrorProps> = ({
    deleteHandler,
    dismissHandler,
    failedSamples,
    isErrorShown,
}) => {
    return (
        <ModalWrapper
            confirmHandler={deleteHandler}
            confirmText="Delete from PlateMap"
            dismissHandler={dismissHandler}
            dismissText="Back"
            header="Error"
            icon="danger"
            isShown={isErrorShown}
            type="confirm"
        >
            <p>Following Sample(s) can't be regstered to Plate Map due to expired date(s):</p>
            <ul>
                {failedSamples.map((failedSample) => (
                    <li>{failedSample}</li>
                ))}
            </ul>
        </ModalWrapper>
    );
};
