import { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import {
    createBloodSampleBundleForPregnancy,
    CreateBloodSampleBundleParams,
    deleteBloodSampleBundleById,
    getBloodSampleBundlesByPregnancyId,
    updateBloodSampleBundleById,
} from '../api/bloodSampleBundles';
import { debouncedSaveWithErrors } from '../helper/apiHelper';
import { modifyObjectInArrayById } from '../helper/arrayHelper';
import { BloodSampleBundle, ValidationError } from '../types';
import { BloodMapsContext } from './BloodMapsContext';
import { EdtaSamplesContext } from './EdtaSamplesContext';
import { SerumSamplesContext } from './SerumSamplesContext';

interface BloodSampleBundlesContextProps {
    bloodSampleBundles: BloodSampleBundle[];
    bloodSampleBundlesErrors: ValidationError[];
    bloodSampleBundlesIds: number[];
    clearBloodSampleBundles: () => void;
    createNewBloodMeasuementForPregnancy: ({
        pregnancyId,
        data,
    }: CreateBloodSampleBundleParams) => Promise<BloodSampleBundle>;
    deleteBloodSampleBundle: (bloodSampleBundleId: number) => void;
    loadBloodSampleBundlesForPregnancy: (pregnancyId: number) => void;
    setBloodSampleBundleErrors: Dispatch<SetStateAction<ValidationError[]>>;
    updateBloodSampleBundleAttributeForBloodSampleBundleId: (
        bloodSampleBundleId: number,
        delta: { [key: string]: any }
    ) => void;
}

const bloodSampleBundlesDefaults: BloodSampleBundle[] = [];

export const BloodSampleBundlesContext = createContext<BloodSampleBundlesContextProps>(null);
BloodSampleBundlesContext.displayName = 'BloodSampleBundlesContext';

export const BloodSampleBundlesContextProvider = (props: any) => {
    const { deleteBloodMapByBloodSampleBundleId } = useContext(BloodMapsContext);
    const { deleteEdtaSampleByBloodSampleBundleId } = useContext(EdtaSamplesContext);
    const { deleteSerumSampleByBloodSampleBundleId } = useContext(SerumSamplesContext);

    const [bloodSampleBundlesIds, setbloodSampleBundlesIds] = useState<number[]>([]);
    const [bloodSampleBundles, setBloodSampleBundles] = useState(bloodSampleBundlesDefaults);
    const [bloodSampleBundlesErrors, setBloodSampleBundleErrors] = useState<ValidationError[]>([]);
    const [debounceFunctions, setDebounceFunctions] = useState<{ [key: string]: any }>({});

    const clearBloodSampleBundles = useCallback(() => {
        setBloodSampleBundles(bloodSampleBundlesDefaults);
    }, []);

    const deleteBloodSampleBundle = async (bloodSampleBundleId: number) => {
        await deleteBloodMapByBloodSampleBundleId(bloodSampleBundleId);
        await deleteEdtaSampleByBloodSampleBundleId(bloodSampleBundleId);
        await deleteSerumSampleByBloodSampleBundleId(bloodSampleBundleId);
        await deleteBloodSampleBundleById(bloodSampleBundleId);
        setBloodSampleBundles(
            bloodSampleBundles.filter((bloodSampleBundle) => bloodSampleBundle.id !== bloodSampleBundleId)
        );
        setbloodSampleBundlesIds(bloodSampleBundlesIds.filter((id) => id !== bloodSampleBundleId));
    };

    const loadBloodSampleBundlesForPregnancy = useCallback(async (pregnancyId: number) => {
        const bloodSampleBundlesWithLinks = await getBloodSampleBundlesByPregnancyId(pregnancyId);
        const bloodSampleBundlesWithoutLinks = bloodSampleBundlesWithLinks.map((bloodSampleBundleWithLinks) => {
            const { _links, ...bloodSampleBundleWithoutLinks } = bloodSampleBundleWithLinks;
            return bloodSampleBundleWithoutLinks;
        });

        setBloodSampleBundles(bloodSampleBundlesWithoutLinks);
        setbloodSampleBundlesIds(bloodSampleBundlesWithoutLinks.map((bloodSampleBundle) => bloodSampleBundle.id));
    }, []);

    const createNewBloodMeasuementForPregnancy = async ({ data, pregnancyId }: CreateBloodSampleBundleParams) => {
        const bloodSampleBundle = await createBloodSampleBundleForPregnancy({ data, pregnancyId });
        setBloodSampleBundles([...bloodSampleBundles, bloodSampleBundle]);
        setbloodSampleBundlesIds([...bloodSampleBundlesIds, bloodSampleBundle.id]);
        return bloodSampleBundle;
    };

    const updateBloodSampleBundleAttributeForBloodSampleBundleIdWithoutSaving = useCallback(
        (bloodSampleBundleId: number, delta: { [key: string]: any }) => {
            const updatedBloodSampleBundles = modifyObjectInArrayById({
                array: bloodSampleBundles,
                delta,
                id: bloodSampleBundleId,
            });

            setBloodSampleBundles(updatedBloodSampleBundles);
        },
        [bloodSampleBundles]
    );

    const updateBloodSampleBundleAttributeForBloodSampleBundleId = useCallback(
        async (bloodSampleBundleId: number, delta: { [key: string]: any }) => {
            const attribute = Object.keys(delta)[0];
            let debounceFunction = debounceFunctions[attribute];

            if (!debounceFunctions[attribute]) {
                debounceFunction = debouncedSaveWithErrors({
                    callApi: updateBloodSampleBundleById,
                    setErrors: setBloodSampleBundleErrors,
                });
                setDebounceFunctions({ ...debounceFunctions, [attribute]: debounceFunction });
            }

            await debounceFunction(delta, bloodSampleBundleId);
            updateBloodSampleBundleAttributeForBloodSampleBundleIdWithoutSaving(bloodSampleBundleId, delta);
        },
        [debounceFunctions, updateBloodSampleBundleAttributeForBloodSampleBundleIdWithoutSaving]
    );

    return (
        <BloodSampleBundlesContext.Provider
            value={{
                bloodSampleBundles,
                bloodSampleBundlesErrors,
                bloodSampleBundlesIds,
                clearBloodSampleBundles,
                createNewBloodMeasuementForPregnancy,
                deleteBloodSampleBundle,
                loadBloodSampleBundlesForPregnancy,
                setBloodSampleBundleErrors,
                updateBloodSampleBundleAttributeForBloodSampleBundleId,
            }}
        >
            {props.children}
        </BloodSampleBundlesContext.Provider>
    );
};
