import React from 'react';

interface Props {
    components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
    children: React.ReactNode;
}

export const ComposeComponents = (props: Props) => {
    const { components = [], children, ...rest } = props;

    return (
        <>
            {components.reduceRight((acc, Comp) => {
                return <Comp {...rest}>{acc}</Comp>;
            }, children)}
        </>
    );
};
