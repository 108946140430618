import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { getPatientList } from '../../api/patientList';
import { TestStatus, TESTSTATUS } from '../../app/config';
import { PatientListContext } from '../../contexts/PatientListContext';
import './StatusFilter.scss';

interface StatusFilterProps {}
interface FilterAndNotification {
    filter: TestStatus;
    notificationCount?: number;
}

const isRejected = (input: PromiseSettledResult<unknown>): input is PromiseRejectedResult =>
    input.status === 'rejected';

export const StatusFilter: React.FC<StatusFilterProps> = () => {
    const { testStatusFilter, setTestStatusFilter, endDate, startDate, patientName } = useContext(PatientListContext);
    const [testStatusFiltersAndNotifications, setTestStatusFiltersAndNotifications] =
        useState<FilterAndNotification[]>(null);

    const toggleTestStatus = (key: TestStatus) => {
        if (testStatusFilter === key) {
            setTestStatusFilter(null);
        } else {
            setTestStatusFilter(key);
        }
    };

    useEffect(() => {
        const makeRestCall = async () => {
            const promises = Object.keys(TESTSTATUS).map(async (key) => {
                // one call per filter
                return await getPatientList({
                    endDate,
                    page: 0,
                    size: 0,
                    startDate,
                    testStatusFilter: key as TestStatus,
                });
            });

            // update when all calls are done
            Promise.allSettled(promises).then((values) => {
                const filterAndNotifications = Object.keys(TESTSTATUS).map((key, index) => {
                    const item = values[index];
                    if (isRejected(item)) {
                        return { filter: key as TestStatus, notificationCount: 0 };
                    }
                    return { filter: key as TestStatus, notificationCount: item.value.page.totalElements };
                });

                setTestStatusFiltersAndNotifications(filterAndNotifications);
            });
        };
        makeRestCall();
    }, [endDate, startDate, patientName]);

    if (!testStatusFiltersAndNotifications) return <></>;

    return (
        <div className="status-filter">
            <h6>Status Filter:</h6>
            <Row>
                {testStatusFiltersAndNotifications.map((item) => {
                    return (
                        <Col key={item.filter}>
                            <Button
                                className="status-filter__button btn--full"
                                variant={testStatusFilter === item.filter ? 'primary' : 'outline-primary'}
                                onClick={() => toggleTestStatus(item.filter)}
                            >
                                {/* TODO remove filter check once results are available in the backend */}
                                {item.notificationCount !== 0 && item.filter !== 'ToRepeat' && (
                                    <span className="status-filter__notification-count">{item.notificationCount}</span>
                                )}
                                {TESTSTATUS[item.filter]}
                            </Button>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};
