import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PatientForm from './pages/PatientForm';
import Home from './pages/Home';
import { PatientRecord } from './pages/PatientRecord';
import { RiskResult } from './pages/RiskResult';
import { PlateMap } from './pages/PlateMap';
import { BulkImport } from './pages/BulkImport';

interface RoutingProps {}

export const Routing: React.FC<RoutingProps> = () => {
    return (
        <Routes>
            <Route element={<Home />} path="/home" />
            <Route element={<Home />} path="/home/toreport" />

            <Route element={<PatientForm />} path="/patientForm/:patientId" />
            <Route element={<PatientForm isNew={true} />} path="/patientForm/new" />

            <Route element={<PatientRecord />} path="/patientRecord/:patientId" />
            <Route element={<PatientRecord />} path="/patientRecord/:patientId/addBloodSample" />
            <Route element={<PatientRecord />} path="/patientRecord/:patientId/addOutcome" />
            <Route element={<PatientRecord />} path="/patientRecord/:patientId/addPIGF" />
            <Route element={<PatientRecord />} path="/patientRecord/:patientId/:bloodSampleBundleId" />

            <Route element={<RiskResult />} path="/riskResult/:patientId" />

            <Route element={<PlateMap />} path="/plateMap/:plateMapId" />
            <Route element={<PlateMap isNew={true} />} path="/plateMap/new" />

            <Route element={<BulkImport />} path="/bulkImport" />

            <Route element={<Navigate to="/home" replace />} path="*" />
        </Routes>
    );
};
