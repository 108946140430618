import { PlateMapEntry, PlateMapItem } from '../types';

export const mapPlateMapEntry = (entry: PlateMapEntry) => {
    const { xPosition, yPosition, edtaSample } = entry;

    let id: number | string = edtaSample?.id;
    let title = 'populated item';

    if (entry.edtaSample?.bloodSampleBundle?.pregnancy?.patient) {
        const externalId = entry.edtaSample.bloodSampleBundle.pregnancy.patient.externalPatientId;
        const forename = entry.edtaSample.bloodSampleBundle.pregnancy.patient.forename;
        const surname = entry.edtaSample.bloodSampleBundle.pregnancy.patient.surname;

        id = entry.edtaSample.bloodSampleBundle.pregnancy.patient.id;
        title = `Anonymised \n ${externalId}`;

        if (forename !== null && surname !== null) {
            title = `${forename} ${surname} \n ${externalId}`;
        }
    }

    const item = {
        content: id,
        title: title,
        variant: edtaSample ? 'filled' : 'empty',
        xPosition: xPosition + 1,
        yPosition: yPosition + 1,
    };
    return item as PlateMapItem;
};


export const mapPosition = (x: number, y: number) => {
    const mapping = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

    return `${mapping[y]}${x + 1}`;
}