import { createContext, useCallback, useState } from 'react';
import { getHypertension, updateHypertensionForPatientId } from '../api/hypertension';
import { Hypertension } from '../app/config';
import { debouncedSaveWithErrors } from '../helper/apiHelper';

interface HypertensionContextProps {
    clearHypertension: () => void;
    loadHypertensionForPatient: (patientId: number) => void;
    hypertension: Hypertension;
    updateHypertension: (delta: { hypertension: Hypertension }) => void;
}

export const HypertensionContext = createContext<HypertensionContextProps>(null);
HypertensionContext.displayName = 'HypertensionContext';

export const HypertensionContextProvider = (props: any) => {
    const [hypertension, setHypertension] = useState<Hypertension>(null);
    const [hypertensionId, setHypertensionId] = useState<number>(null);

    const clearHypertension = useCallback(() => {
        setHypertension(null);
        setHypertensionId(null);
    }, []);

    const updateHypertension = async (delta: { hypertension: Hypertension }) => {
        await debouncedSaveWithErrors({
            callApi: updateHypertensionForPatientId,
            setErrors: () => {},
        })(delta, hypertensionId);
        setHypertension(delta.hypertension);
    };

    const loadHypertensionForPatient = useCallback(async (patientId: number) => {
        const { id, hypertension } = await getHypertension(patientId);
        setHypertensionId(id);
        setHypertension(hypertension);
    }, []);

    return (
        <HypertensionContext.Provider
            value={{
                clearHypertension,
                hypertension,
                loadHypertensionForPatient,
                updateHypertension,
            }}
        >
            {props.children}
        </HypertensionContext.Provider>
    );
};
