import { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { getResidenceByPatientId, updateResidenceForId } from '../api/residence';
import { debouncedSaveWithErrors } from '../helper/apiHelper';
import { Residence, ValidationError } from '../types';

interface ResidenceContextProps {
    clearResidence: () => void;
    loadResidenceForPatient: (patientId: number) => void;
    residence: Residence;
    residenceErrors: ValidationError[];
    setResidenceErrors: Dispatch<SetStateAction<ValidationError[]>>;
    updateResidenceAttribute: (delta: { [key: string]: any }) => void;
}

export const ResidenceContext = createContext<ResidenceContextProps>(null);
ResidenceContext.displayName = 'ResidenceContext';

const residenceDefaults: Residence = {
    id: null as number,
};

export const ResidenceContextProvider = (props: any) => {
    const [residence, setResidence] = useState<Residence>(residenceDefaults);
    const [debounceFunctions, setDebounceFunctions] = useState<{ [key: string]: any }>({});
    const [residenceErrors, setResidenceErrors] = useState<ValidationError[]>([]);

    const clearResidence = useCallback(() => {
        setResidence(residenceDefaults);
    }, []);

    const updateResidenceAttribute = async (delta: { [key: string]: any }) => {
        const attribute = Object.keys(delta)[0];
        let debounceFunction = debounceFunctions[attribute];

        if (!debounceFunctions[attribute]) {
            debounceFunction = debouncedSaveWithErrors({
                callApi: updateResidenceForId,
                setErrors: setResidenceErrors,
            });
            setDebounceFunctions({ ...debounceFunctions, [attribute]: debounceFunction });
        }

        await debounceFunction(delta, residence.id);

        if (attribute === 'country') {
            setResidence({ ...residence, ...delta, region: null });
        } else {
            setResidence({ ...residence, ...delta });
        }
    };

    const loadResidenceForPatient = useCallback(async (patientId: number) => {
        const residenceWithLinks = await getResidenceByPatientId(patientId);
        const { _links, ...residenceWithoutLinks } = residenceWithLinks;
        setResidence(residenceWithoutLinks);
    }, []);

    return (
        <ResidenceContext.Provider
            value={{
                clearResidence,
                loadResidenceForPatient,
                residence,
                residenceErrors,
                setResidenceErrors,
                updateResidenceAttribute,
            }}
        >
            {props.children}
        </ResidenceContext.Provider>
    );
};
