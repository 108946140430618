import React from 'react';
import { useNavigate } from 'react-router';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

import { ReactComponent as CheckIcon } from '../../../resources/icons/check.svg';
import { ResultCalculation } from '../../../types';
import { BulkFailedSamples } from './BulkFailedSamples';

interface BulkSuccessProps {
    dismissHandler: () => void;
    isShown: boolean;
    results: ResultCalculation[];
}

export const BulkSuccess: React.FC<BulkSuccessProps> = ({ dismissHandler, isShown, results }) => {
    const navigate = useNavigate();

    if (!results || results.length === 0) return null;

    const totalSamplesCount = results.length;
    // get samples that have no valuations outside of ranges
    const passedSamples = results.filter(
        (result) => result.resultValuations.filter((val) => !val.withInRanges).length === 0
    );
    // get samples that have valuations outside of ranges
    const failedSamples = results.filter(
        (result) => result.resultValuations.filter((val) => !val.withInRanges).length > 0
    );

    const hasSampleErrors = totalSamplesCount !== passedSamples.length;

    return (
        <ModalWrapper
            confirmHandler={() => {
                navigate(`/home/toreport`);
            }}
            confirmText="View"
            dismissHandler={dismissHandler}
            dismissText="Close"
            header="Calculation Complete"
            icon={<CheckIcon className="icon c-success" />}
            isShown={isShown}
            type="confirm"
        >
            <h5>PrePsia results successfully generated.</h5>
            <p>
                Samples Passed: {passedSamples.length}/{totalSamplesCount}
            </p>

            {hasSampleErrors && (
                <>
                    <div className="divider mt-5 mb-5" />
                    <div>
                        <h5>Suboptimal results</h5>
                        <p>
                            The following <strong>{totalSamplesCount - passedSamples.length}</strong> sample results are
                            flagged as sub-optimal and need confirming.
                        </p>
                        <p>Please register those patients to be repeated by rejecting the result manually.</p>
                        <p>Any patients that can be reported please approve the result.</p>
                    </div>
                    <BulkFailedSamples failedSamples={failedSamples} />
                </>
            )}
        </ModalWrapper>
    );
};
