import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { EdtaSample, Links } from "../types";
import { mapIncomingValues, mapOutgoingValues } from "./mapping";

interface EdtaSampleWithLinks extends EdtaSample, Links {};

export const getEdtaSampleByBloodSampleBundleId = async (bloodSampleBundleId: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.bloodsamplebundles}/${bloodSampleBundleId}/edtaSample`)

    return mapIncomingValues(result.data) as EdtaSampleWithLinks;
};

export interface CreateEdtaSampleParams {
    bloodSampleBundleId: number;
    data?: EdtaSample;
}

export const createEdtaSampleForBloodSampleBundle = async ({ bloodSampleBundleId, data = {} }: CreateEdtaSampleParams) => {
    const axios = HttpService.getAxiosClient();

    const result = await axios.post(`${endpoints.data.edtasamples}`, {
        bloodSampleBundle: `${endpoints.data.bloodsamplebundles}/${bloodSampleBundleId}`,
        data
    });

    return result.data as EdtaSampleWithLinks;
}

export const deleteEdtaSampleById = async (edtaSampleId: number) => {
    const axios = HttpService.getAxiosClient();

    return await axios.delete(`${endpoints.data.edtasamples}/${edtaSampleId}`);
}

export const updateEdtaSampleById = async (delta: { [key: string]: any }, edtaSampleId: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.edtasamples}/${edtaSampleId}`, {
        ...mapOutgoingValues(delta)
    });
}

export const getBloodSampleBundleIdForEdtaSampleId = async (edtaSampleId: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.edtasamples}/${edtaSampleId}/bloodSampleBundle`);

    return result.data.id
}