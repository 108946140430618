import React, { useState } from 'react';
import { SampleCandidatesTable } from '../../SampleCandidatesTable/SampleCandidatesTable';

import { ModalWrapper } from '../../Wrapper/ModalWrapper';

interface AddToPlateProps {
    addToPlateMap: (samplesIds: number[]) => void;
    dismissHandler: () => void;
    isShown: boolean;
}

export const AddToPlate: React.FC<AddToPlateProps> = ({ addToPlateMap, dismissHandler, isShown }) => {
    const [selectedIds, setSelectedIds] = useState<number[]>();

    const addToPlateMapsBySamplesIds = () => {
        addToPlateMap(selectedIds);
    };

    return (
        <ModalWrapper
            confirmHandler={addToPlateMapsBySamplesIds}
            confirmText="Add to plate map"
            dismissHandler={dismissHandler}
            header="Add entries to plate map"
            isShown={isShown}
            type="confirm"
        >
            <SampleCandidatesTable setSelectedIds={setSelectedIds} />
        </ModalWrapper>
    );
};
