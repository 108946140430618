import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { PIGFResult } from '../components/PIGFResult/PIGFResult';
import { PrePsiaResults } from '../components/PrePsiaResults/PrePsiaResults';
import { RecordDetails } from '../components/RecordDetails/RecordDetails';
import { SampleRegister } from '../components/SampleRegister/SampleRegister';
import { Outcome } from '../components/Outcome/Outcome';

import './PatientForm.scss';
import { useLoadPatientForId } from '../hooks/useLoadPatientForId';
import { ScrollTop } from '../helper/ScrollTop';
import { SettingsContext } from '../contexts/SettingsContext';
import { useLoadBloodSampleBundleForPregnancyId } from '../hooks/useLoadBloodSampleBundlesForPregnancyId';
import { PregnancyContext } from '../contexts/PregnancyContext';
import { useLoadMapsAndSamplesForbloodSampleBundlesIds } from '../hooks/useLoadMapsAndSamplesForBloodSampleBundlesIds';
import { BloodSampleBundlesContext } from '../contexts/BloodSampleBundlesContext';
import { PREGNANCY_STATE } from '../app/constants';

interface PatientRecordProps {}

export const PatientRecord: React.FC<PatientRecordProps> = () => {
    const { patientId } = useParams();
    const { pathname } = useLocation();

    const [yPosition, setYPosition] = useState(0);
    const [forceOutcomeOverlay, setForceOutcomeOverlay] = useState(false);
    const [forcePIGFOverlay, setForcePIGFOverlay] = useState(false);
    const [forceBloodSampleOverlay, setForceBloodSampleOverlay] = useState(false);

    const outcomeRef = useRef<HTMLDivElement>(null);
    const pigfRef = useRef<HTMLDivElement>(null);
    const bloodSampleRef = useRef<HTMLDivElement>(null);

    const { headerHeight } = useContext(SettingsContext);
    const { pregnancy } = useContext(PregnancyContext);
    const { bloodSampleBundlesIds } = useContext(BloodSampleBundlesContext);

    useLoadPatientForId(patientId);
    useLoadBloodSampleBundleForPregnancyId(pregnancy.id);
    useLoadMapsAndSamplesForbloodSampleBundlesIds(bloodSampleBundlesIds);

    useEffect(() => {
        if (pathname.indexOf('addOutcome') !== -1) {
            const currentScrollPosition = window.pageYOffset;
            setYPosition(outcomeRef?.current?.getBoundingClientRect().top - headerHeight + currentScrollPosition || 0);
            setForceOutcomeOverlay(true);
        }
    }, [headerHeight, outcomeRef, pathname]);

    useEffect(() => {
        if (pathname.indexOf('addBloodSample') !== -1) {
            const currentScrollPosition = window.pageYOffset;
            setYPosition(
                bloodSampleRef?.current?.getBoundingClientRect().top - headerHeight + currentScrollPosition || 0
            );
            setForceBloodSampleOverlay(true);
        }
    }, [headerHeight, bloodSampleRef, pathname]);

    useEffect(() => {
        if (pathname.indexOf('addPIGF') !== -1) {
            const currentScrollPosition = window.pageYOffset;
            setYPosition(pigfRef?.current?.getBoundingClientRect().top - headerHeight + currentScrollPosition || 0);
            setForcePIGFOverlay(true);
        }
    }, [headerHeight, outcomeRef, pathname]);

    return (
        <div className="patient-record-page">
            <Header>
                <>Patient Record: {patientId}</>
            </Header>
            <Container className="mt-5">
                <RecordDetails editable />
                <SampleRegister forceOverlay={forceBloodSampleOverlay} ref={bloodSampleRef} />
                <PrePsiaResults />
                <PIGFResult forceOverlay={forcePIGFOverlay} ref={pigfRef} />
                {pregnancy.pregnancyState === PREGNANCY_STATE.OUTCOME ||
                    (pregnancy.pregnancyState === PREGNANCY_STATE.ARCHIVED && (
                        <Outcome forceOverlay={forceOutcomeOverlay} ref={outcomeRef} />
                    ))}
            </Container>
            <Footer />
            <ScrollTop yPosition={yPosition} />
        </div>
    );
};
