import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { SerumSample, Links } from "../types";
import { mapIncomingValues, mapOutgoingValues } from "./mapping";

interface SerumSampleWithLinks extends SerumSample, Links { };

export const getSerumSampleByBloodSampleBundleId = async (bloodSampleBundleId: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.bloodsamplebundles}/${bloodSampleBundleId}/serumSample`)

    return mapIncomingValues(result.data) as SerumSampleWithLinks;
};

export interface CreateSerumSampleParams {
    bloodSampleBundleId: number;
    data?: SerumSample;
}

export const createSerumSampleForBloodSampleBundle = async ({ bloodSampleBundleId, data = {} }: CreateSerumSampleParams) => {
    const axios = HttpService.getAxiosClient();

    const result = await axios.post(`${endpoints.data.serumsamples}`, {
        bloodSampleBundle: `${endpoints.data.bloodsamplebundles}/${bloodSampleBundleId}`,
        data
    });

    return result.data as SerumSampleWithLinks;
}

export const deleteSerumSampleById = async (serumSampleId: number) => {
    const axios = HttpService.getAxiosClient();

    return await axios.delete(`${endpoints.data.serumsamples}/${serumSampleId}`);
}

export const updateSerumSampleById = async (delta: { [key: string]: any }, serumSampleId: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.serumsamples}/${serumSampleId}`, {
        ...mapOutgoingValues(delta)
    });
}

export const getBloodSampleBundleIdForSerumSampleId = async (serumSampleId: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.serumsamples}/${serumSampleId}/bloodSampleBundle`);

    return result.data.id
}