import React from 'react';

import { ReactComponent as EyeIcon } from '../../resources/icons/eye.svg';
import { GenericTable } from '../GenericTable/GenericTable';
import { SectionHeader } from '../SectionHeader/SectionHeader';

interface PrePsiaResultsProps {}

export const PrePsiaResults: React.FC<PrePsiaResultsProps> = () => {
    const header = ['Result No.', 'Date performed', 'PrePsia Risk Result', '', ''];
    // TODO fill with data when BE is ready
    const data = [
        [
            '1',
            '10/06/2022',
            'High Risk',
            <EyeIcon
                className="icon-as-btn icon-as-btn--small"
                onClick={() => {
                    console.log('view');
                }}
            />,
            '',
        ],
    ];

    return (
        <div className="prepsia-results">
            <SectionHeader title="PrePsia Results" />
            {/* TODO uncomment when BE is ready */}
            {/* <GenericTable data={data} header={header} /> */}
        </div>
    );
};
