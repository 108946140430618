import React, { useContext, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { exportRunFileById } from '../../api/plateMaps';
import { MAX_VISIBLE_PLATEMAPS, PLATEMAPS_PER_PAGE } from '../../app/config';
import { PlateMapsContext } from '../../contexts/PlateMapsContext';
import { downloadFile } from '../../helper/downloadHelper';
import { Pagination } from '../Pagination/Pagination';

import './PlateMapList.scss';

interface PlateMapListProps {}

export const PlateMapList: React.FC<PlateMapListProps> = () => {
    const navigate = useNavigate();

    const { currentPage, loadPlateMaps, plateMaps, setCurrentPage, totalPlateMaps } = useContext(PlateMapsContext);

    useEffect(() => {
        loadPlateMaps();
    }, [loadPlateMaps]);

    const gotoPlateMap = (plateMapId: number) => {
        navigate(`/plateMap/${plateMapId}`);
    };

    const exportRunFile = async (plateMapId: number) => {
        const runFileData: string = await exportRunFileById(plateMapId);

        downloadFile({ content: runFileData, fileName: `Prepsia_Test_Run_${plateMapId}.csv`, inputType: 'string' });
    };

    if (plateMaps.length === 0) {
        return (
            <Container className="mt-3 plate-map-list">
                <div className="divider mb-3" />
                <div className="plate-map-list__entry">No plate maps found</div>
                <div className="divider mt-3" />
            </Container>
        );
    }

    return (
        <Container className="mt-3 plate-map-list">
            <div className="divider mb-3" />
            {plateMaps.map((plateMap) => (
                <div className="ml-3 plate-map-list__entry" key={plateMap.id}>
                    <div>Prepsia Test Run {plateMap.id} </div>
                    <div>
                        <Button onClick={() => gotoPlateMap(plateMap.id)}>View</Button>
                        <Button variant="outline-secondary ml-3" onClick={() => exportRunFile(plateMap.id)}>
                            Export Run File
                        </Button>
                    </div>
                </div>
            ))}
            <Pagination
                currentPage={currentPage}
                perPage={PLATEMAPS_PER_PAGE}
                setCurrentPage={setCurrentPage}
                total={Math.min(MAX_VISIBLE_PLATEMAPS, totalPlateMaps)}
            />
            <div className="divider mt-3" />
        </Container>
    );
};
