import { INTERPREGNANCY_INTERVAL, NUMBER_PRIOR_BIRTHS, NUMBER_PRIOR_GESTATIONS, NUMBER_PRIOR_MISCARRIAGES } from "../app/config";
import { YESNOUNKNOWN } from "../app/constants";

export const mapIncomingValues = (data: any) => {
    // ANONYMISED
    // convert to string
    if (typeof data.anonymised !== 'undefined') {
        if (data.anonymised === true) data.anonymised = YESNOUNKNOWN.YES;
        else if (data.anonymised === false) data.anonymised = YESNOUNKNOWN.NO;
    }

    // MULTIFETAL GESTATION
    // convert to string
    if (typeof data.multifetalGestation !== 'undefined') {
        if (data.multifetalGestation === true) data.multifetalGestation = YESNOUNKNOWN.YES;
        else if (data.multifetalGestation === false) data.multifetalGestation = YESNOUNKNOWN.NO;
    }

    // INTERPREGNANCY INTERVAL
    // convert to string
    if (typeof data.interpregnancyInterval !== 'undefined') {
        data.interpregnancyInterval = Object.keys(INTERPREGNANCY_INTERVAL)[data.interpregnancyInterval - 1];
    }

    // NUMBER PRIOR BIRTHS
    // convert to string
    if (typeof data.numberOfPriorBirth !== 'undefined') {
        data.numberOfPriorBirth = Object.keys(NUMBER_PRIOR_BIRTHS)[data.numberOfPriorBirth];
    }

    // NUMBER PRIOR GESTATIONS
    // convert to string
    if (typeof data.numberOfPriorGestations !== 'undefined') {
        data.numberOfPriorGestations = Object.keys(NUMBER_PRIOR_GESTATIONS)[data.numberOfPriorGestations];
    }

    // NUMBER PRIOR MISCARRIAGES
    // convert to string
    if (typeof data.numberOfPriorMiscarriages !== 'undefined') {
        data.numberOfPriorMiscarriages = Object.keys(NUMBER_PRIOR_MISCARRIAGES)[data.numberOfPriorMiscarriages];
    }

    // SAMPLE ACCEPTED
    // convert to string
    if (typeof data.sampleAccepted !== 'undefined') {
        if (data.sampleAccepted === true) data.sampleAccepted = YESNOUNKNOWN.YES;
        else if (data.sampleAccepted === false) data.sampleAccepted = YESNOUNKNOWN.NO;
    }

    return data;
}

export const mapOutgoingValues = (data: any) => {
    // ANONYMISED
    // convert to boolean
    // clear forename and surname when anonymised
    if (typeof data.anonymised !== 'undefined') {
        if (data.anonymised === YESNOUNKNOWN.YES) {
            data.anonymised = true;
            // TODO: check if we need to clear these values or not
            // data.forename = null;
            // data.surname = null;
        }
        else if (data.anonymised === YESNOUNKNOWN.NO) data.anonymised = false;
    }

    // COUNTRY
    // clear region when country is changed
    if (typeof data.country !== 'undefined') {
        data.region = null;
    }

    // MULTIFETAL GESTATION
    // convert to boolean
    if (typeof data.multifetalGestation !== 'undefined') {
        if (data.multifetalGestation === YESNOUNKNOWN.YES) data.multifetalGestation = true;
        else if (data.multifetalGestation === YESNOUNKNOWN.NO) data.multifetalGestation = false;
    }

    // INTERPREGNANCY_INTERVAL
    if (typeof data.interpregnancyInterval !== 'undefined') {
        // convert to number
        data.interpregnancyInterval = Object.keys(INTERPREGNANCY_INTERVAL).indexOf(data.interpregnancyInterval) + 1;
    }

    // NUMBER PRIOR GESTATIONS
    // convert to number
    if (typeof data.numberOfPriorGestations !== 'undefined') {
        data.numberOfPriorGestations = Object.keys(NUMBER_PRIOR_GESTATIONS).indexOf(data.numberOfPriorGestations);
    }

    // NUMBER PRIOR BIRTHS
    // convert to number
    if (typeof data.numberOfPriorBirth !== 'undefined' && data.numberOfPriorBirth !== null) {
        data.numberOfPriorBirth = Object.keys(NUMBER_PRIOR_BIRTHS).indexOf(data.numberOfPriorBirth);
    }

    // NUMBER PRIOR MISCARRIAGES
    // convert to number
    if (typeof data.numberOfPriorMiscarriages !== 'undefined' && data.numberOfPriorMiscarriages !== null) {
        data.numberOfPriorMiscarriages = Object.keys(NUMBER_PRIOR_MISCARRIAGES).indexOf(data.numberOfPriorMiscarriages);
    }

    // WEIGHT
    // convert to number
    // add fixed metric measurement value
    if (typeof data.weight !== 'undefined') {
        data.weight = Number(data.weight.replace(',', '.'));
        data.unitTypeWeight = 'METRIC';
    }

    // HEIGHT
    // add fixed metric measurement value
    if (typeof data.height !== 'undefined') {
        data.unitTypeHeight = 'METRIC';
    }

    // CROWN RUMP LENGTH
    // convert to number
    if (typeof data.crownRump !== 'undefined') {
        data.crownRump = Number(data.crownRump.replace(',', '.'));
    }

    // UTERINE ARTERY DOPPLER
    // convert to number
    if (typeof data.uterineArteryDopplerLeft !== 'undefined') {
        data.uterineArteryDopplerLeft = Number(data.uterineArteryDopplerLeft.replace(',', '.'));
    }
    if (typeof data.uterineArteryDoppleRight !== 'undefined') {
        data.uterineArteryDoppleRight = Number(data.uterineArteryDoppleRight.replace(',', '.'));
    }

    // SAMPLE ACCEPTED
    // convert to boolean
    if (typeof data.sampleAccepted !== 'undefined') {
        if (data.sampleAccepted === YESNOUNKNOWN.YES) data.sampleAccepted = true;
        else if (data.sampleAccepted === YESNOUNKNOWN.NO) data.sampleAccepted = false;
    }

    // TODO check if we need this or not
    /* 
    // PRIOR FETAL OUTCOME
    // clear prior fetal outcome details
    if (data.priorFetalOutcome !== YESNOUNKNOWN.YES) {
        data.priorFetalOutcomesDetails = [];
    }

    // PRIOR ORGAN LESION
    // clear prior organ lesion details
    if (data.priorOrganLesion !== YESNOUNKNOWN.YES) {
        data.priorOrganLesionDetails = [];
    }
    */


    return data;
}