import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PlateItem } from '../../PlateItem/PlateItem';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

interface PlateMapLegendProps {
    dismissHandler: () => void;
    isShown: boolean;
}

export const PlateMapLegend: React.FC<PlateMapLegendProps> = ({ dismissHandler, isShown }) => {
    return (
        <ModalWrapper dismissHandler={dismissHandler} header="Plate Map Keys" isShown={isShown} size="small">
            <Row className="mb-3">
                <Col>
                    <div className="flex align-center">
                        <div style={{ height: '64px', width: '64px' }}>
                            <PlateItem variant="low" />
                        </div>
                        <span className="ml-2">QC Low</span>
                    </div>
                </Col>
                <Col>
                    <div className="flex align-center">
                        <div style={{ height: '64px', width: '64px' }}>
                            <PlateItem />
                        </div>
                        <span className="ml-2">Blank</span>
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <div className="flex align-center">
                        <div style={{ height: '64px', width: '64px' }}>
                            <PlateItem variant="medium" />
                        </div>
                        <span className="ml-2">QC Medium</span>
                    </div>
                </Col>
                <Col>
                    <div className="flex align-center">
                        <div style={{ height: '64px', width: '64px' }}>
                            <PlateItem content="..." variant="calibrator" />
                        </div>
                        <span className="ml-2">Calibrator (1-7)</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="flex align-center">
                        <div style={{ height: '64px', width: '64px' }}>
                            <PlateItem variant="high" />
                        </div>
                        <span className="ml-2">QC High</span>
                    </div>
                </Col>
                <Col>
                    <div className="flex align-center">
                        <div style={{ height: '64px', width: '64px' }}>
                            <PlateItem content="ID" variant="filled" />
                        </div>
                        <span className="ml-2">Populated Item</span>
                    </div>
                </Col>
            </Row>
        </ModalWrapper>
    );
};
