import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PREGNANCY_STATE } from '../../app/constants';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { Headline } from '../Headline/Headline';
import { DateWrapper } from '../Wrapper/DateWrapper';
import { InputWrapper } from '../Wrapper/InputWrapper';

interface BiophysicalDataProps {}

export const BiophysicalData: React.FC<BiophysicalDataProps> = () => {
    // const { patientForm, updatePatientFormAttribute } = useContext(PatientFormContext);
    const { pregnancy, updatePregnancyAttribute } = useContext(PregnancyContext);

    return (
        <div className="biophysical-data mb-5 mt-5 form-section">
            <Headline headline="4. Biophysical data" requiredInfo />
            <Row className="mb-3">
                <Col>
                    <InputWrapper
                        changeHandler={updatePregnancyAttribute}
                        id="uterineArteryDopplerLeft"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Uterine Artery Doppler Pulsatity Index (left)"
                        value={pregnancy.uterineArteryDopplerLeft}
                    />
                </Col>
                <Col>
                    <DateWrapper
                        blurHandler={updatePregnancyAttribute}
                        id="dopplerDateRecorder"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Date recorded"
                        value={pregnancy.dopplerDateRecorder}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <InputWrapper
                        changeHandler={updatePregnancyAttribute}
                        id="uterineArteryDopplerRight"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Uterine Artery Doppler Pulsatity Index (right)"
                        value={pregnancy.uterineArteryDopplerRight}
                    />
                </Col>
                <Col>
                    <InputWrapper
                        changeHandler={updatePregnancyAttribute}
                        id="certNumber"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Reg/Cert No."
                        value={pregnancy.certNumber}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <InputWrapper
                        changeHandler={updatePregnancyAttribute}
                        id="dopplerSonographer"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="UtAD-PI Sonographer"
                        value={pregnancy.dopplerSonographer}
                    />
                </Col>
                <Col />
            </Row>
        </div>
    );
};
