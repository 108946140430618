import { unionBy } from 'lodash';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { GenericTable } from '../GenericTable/GenericTable';

interface FailedSamplesProps {}

export const FailedSamples: React.FC<FailedSamplesProps> = () => {
    const failedHeader = ['Pos', 'ID', 'QC'];

    // TODO GET DATA AND MAP
    const [failedData, setFailedData] = useState([
        { id: 'MDX01-001845', isChecked: false, pos: 'B12', reason: 'Fail Reason?' },
        { id: 'MDX01-001815', isChecked: false, pos: 'C4', reason: 'Fail Reason?' },
        { id: 'MDX01-021845', isChecked: false, pos: 'D6', reason: 'Fail Reason?' },
    ]);

    const changeHandler = (item: any) => {
        item.isChecked = !item.isChecked;

        setFailedData(unionBy(failedData, item, 'id'));
    };

    const allChecked = failedData.filter((item) => !item.isChecked).length === 0;

    const failedDataWithCheckbox = failedData.map((item) => {
        return [
            <Form.Check checked={item.isChecked} type="checkbox" onChange={() => changeHandler(item)} />,
            item.pos,
            item.id,
            item.reason,
        ];
    });

    const failedHeaderWithCheckbox = [
        <Form.Check
            checked={allChecked}
            type="checkbox"
            onClick={() => {
                failedData.forEach((item) => changeHandler(item));
            }}
        />,
        ...failedHeader,
    ];

    return (
        <div className="failed-samples">
            <p>The following {} samples failed QC:</p>
            <GenericTable data={failedDataWithCheckbox} header={failedHeaderWithCheckbox} />
        </div>
    );
};
