import { createContext, useCallback, useState } from 'react';
import { getAutoimmuneDisease, updateAutoimmuneDiseaseForPatientId } from '../api/autoimmuneDisease';
import { Autoimmune } from '../app/config';
import { debouncedSaveWithErrors } from '../helper/apiHelper';

interface AutoimmuneDiseaseContextProps {
    clearAutoimmuneDisease: () => void;
    loadAutoimmuneDiseaseForPatient: (patientId: number) => void;
    autoimmuneDisease: Autoimmune;
    updateAutoimmuneDisease: (delta: { autoimmune: Autoimmune }) => void;
}

export const AutoimmuneDiseaseContext = createContext<AutoimmuneDiseaseContextProps>(null);
AutoimmuneDiseaseContext.displayName = 'AutoimmuneDiseaseContext';

export const AutoimmuneDiseaseContextProvider = (props: any) => {
    const [autoimmuneDisease, setAutoimmuneDisease] = useState<Autoimmune>(null);
    const [autoimmuneDiseaseId, setAutoimmuneDiseaseId] = useState<number>(null);

    const clearAutoimmuneDisease = useCallback(() => {
        setAutoimmuneDisease(null);
        setAutoimmuneDiseaseId(null);
    }, []);

    const updateAutoimmuneDisease = async (delta: { autoimmune: Autoimmune }) => {
        await debouncedSaveWithErrors({
            callApi: updateAutoimmuneDiseaseForPatientId,
            setErrors: () => {},
        })(delta, autoimmuneDiseaseId);
        setAutoimmuneDisease(delta.autoimmune);
    };

    const loadAutoimmuneDiseaseForPatient = useCallback(async (patientId: number) => {
        const { id, autoimmune } = await getAutoimmuneDisease(patientId);
        setAutoimmuneDiseaseId(id);
        setAutoimmuneDisease(autoimmune);
    }, []);

    return (
        <AutoimmuneDiseaseContext.Provider
            value={{
                autoimmuneDisease,
                clearAutoimmuneDisease,
                loadAutoimmuneDiseaseForPatient,
                updateAutoimmuneDisease,
            }}
        >
            {props.children}
        </AutoimmuneDiseaseContext.Provider>
    );
};
