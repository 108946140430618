import clsx from 'clsx';
import React from 'react';

import './GenericTable.scss';

interface GenericTableProps {
    className?: string;
    rowClickHandler?: (rIndex: number) => void;
    data: (string | number | JSX.Element)[][];
    header: (string | JSX.Element)[];
}

export const GenericTable: React.FC<GenericTableProps> = ({ className, data, header, rowClickHandler = () => {} }) => {
    const classes = clsx('generic-table', className);
    return (
        <div className={classes}>
            <div className="generic-table__header">
                {header.map((title, index) => (
                    <div key={index}>{title}</div>
                ))}
            </div>
            <div className="generic-table__entries">
                {data.map((entry, index) => (
                    <div key={index} onClick={() => rowClickHandler(index)}>
                        {entry.map((item: any, index) => (
                            <div key={index}>{item}</div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
