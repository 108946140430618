import { ValidationError } from "../types";

export const validateValue = ({ key, value }: { key: string, value: any }): ValidationError[] => {
    const validationErrors: ValidationError[] = [];

    switch (key) {
        case 'height':
        case 'weight':
        case 'uterineArteryDopplerLeft':
        case 'uterineArteryDopplerRight':
            isNaN(Number(value.replace(',', '.'))) && validationErrors.push({ code: 'invalidValue', field: key });
            break;
    }

    return validationErrors;
}