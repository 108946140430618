import { createContext, useCallback, useState } from 'react';
import { getDiabetesDisease, updateDiabetesDiseaseForPatientId } from '../api/diabetesDisease';
import { DiabetesMeillitus } from '../app/config';
import { debouncedSaveWithErrors } from '../helper/apiHelper';

interface DiabetesDiseaseContextProps {
    clearDiabetesDisease: () => void;
    loadDiabetesDiseaseForPatient: (patientId: number) => void;
    diabetesDisease: DiabetesMeillitus;
    updateDiabetesDisease: (delta: { diabetes: DiabetesMeillitus }) => void;
}

export const DiabetesDiseaseContext = createContext<DiabetesDiseaseContextProps>(null);
DiabetesDiseaseContext.displayName = 'DiabetesDiseaseContext';

export const DiabetesDiseaseContextProvider = (props: any) => {
    const [diabetesDisease, setDiabetesDisease] = useState<DiabetesMeillitus>(null);
    const [diabetesDiseaseId, setDiabetesDiseaseId] = useState<number>(null);

    const clearDiabetesDisease = useCallback(() => {
        setDiabetesDisease(null);
        setDiabetesDiseaseId(null);
    }, []);

    const updateDiabetesDisease = async (delta: { diabetes: DiabetesMeillitus }) => {
        await debouncedSaveWithErrors({
            callApi: updateDiabetesDiseaseForPatientId,
            setErrors: () => {},
        })(delta, diabetesDiseaseId);
        setDiabetesDisease(delta.diabetes);
    };

    const loadDiabetesDiseaseForPatient = useCallback(async (patientId: number) => {
        const { id, diabetes } = await getDiabetesDisease(patientId);
        setDiabetesDiseaseId(id);
        setDiabetesDisease(diabetes);
    }, []);

    return (
        <DiabetesDiseaseContext.Provider
            value={{
                clearDiabetesDisease,
                diabetesDisease,
                loadDiabetesDiseaseForPatient,
                updateDiabetesDisease,
            }}
        >
            {props.children}
        </DiabetesDiseaseContext.Provider>
    );
};
