import React from 'react';
import { PlateMap } from '../../types';
import { InputWrapper } from '../Wrapper/InputWrapper';
import './ConsumableEntry.scss';

interface ConsumableEntryProps {
    id: string;
    changeHandler: (delta: { [key: string]: string }) => void;
    label: string;
    plateMap: PlateMap;
}

export const ConsumableEntry: React.FC<ConsumableEntryProps> = ({ id, label, changeHandler, plateMap }) => {
    return (
        <div className="consumable-entry">
            <div>{label}</div>
            <InputWrapper
                changeHandler={changeHandler}
                id={`${id}SerialNo`}
                isDisabled={plateMap.readonly}
                label="Serial No."
                value={plateMap[id + 'SerialNo']}
            />
            <InputWrapper
                changeHandler={changeHandler}
                id={`${id}BatchNo`}
                isDisabled={plateMap.readonly}
                label="Batch No."
                value={plateMap[id + 'BatchNo']}
            />
        </div>
    );
};
