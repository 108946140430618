import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { ValidationError } from '../../../types';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';
import { ReactComponent as ErrorIcon } from '../../../resources/icons/danger.svg';
import { getBloodSampleBundleIdForBloodMapId } from '../../../api/bloodMap';
import { getBloodSampleBundleIdForEdtaSampleId } from '../../../api/edtaSample';
import { getBloodSampleBundleIdForSerumSampleId } from '../../../api/serumSample';

interface SampleErrorProps {
    dismissHandler: () => void;
    isSampleErrorShown: boolean;
    sampleErrors: ValidationError[];
}

export const SampleError: React.FC<SampleErrorProps> = ({ dismissHandler, isSampleErrorShown, sampleErrors }) => {
    const { patientId } = useParams();
    const navigate = useNavigate();

    const hasNoValidSample = sampleErrors.some((error) => error.field === 'bloodSampleBundles');

    const mapError = (error: string) => {
        switch (error) {
            case 'dateTaken':
                return 'Date Taken';
            case 'dateReceived':
                return 'Date Received';
            case 'rejectionReason':
                return 'Rejection Reason';
            case 'sampleNotes':
                return 'Sample Notes';
            case 'approxVol':
                return 'Approx. Vol.';
            case 'condition':
                return 'Condition';
            case 'mapDateRecorded':
                return 'MAP Date Recorded';
            case 'systolicBpLeftArmMeasurement1':
                return 'Systolic BP Left Arm Measurement 1';
            case 'diastolicBpLeftArmMeasurement1':
                return 'Diastolic BP Left Arm Measurement 1';
            case 'systolicBpLeftArmMeasurement2':
                return 'Systolic BP Left Arm Measurement 2';
            case 'diastolicBpLeftArmMeasurement2':
                return 'Diastolic BP Left Arm Measurement 2';
            case 'systolicBpRightArmMeasurement1':
                return 'Systolic BP Right Arm Measurement 1';
            case 'diastolicBpRightArmMeasurement1':
                return 'Diastolic BP Right Arm Measurement 1';
            case 'systolicBpRightArmMeasurement2':
                return 'Systolic BP Right Arm Measurement 2';
            case 'diastolicBpRightArmMeasurement2':
                return 'Diastolic BP Right Arm Measurement 2';
            default:
                return error;
        }
    };

    const onExitedHandler = async () => {
        const errorOrder = [
            'approxVol',
            'condition',
            'mapDateRecorded',
            'systolicBpLeftArmMeasurement1',
            'systolicBpRightArmMeasurement1',
            'diastolicBpLeftArmMeasurement1',
            'diastolicBpRightArmMeasurement1',
            'systolicBpLeftArmMeasurement2',
            'diastolicBpLeftArmMeasurement2',
            'systolicBpRightArmMeasurement2',
            'diastolicBpRightArmMeasurement2',
            'dateTaken',
            'dateReceived',
            'rejectionReason',
            'sampleNotes',
        ];

        const firstErrorId = errorOrder.find((error) =>
            sampleErrors.find((sampleError) => sampleError.field === error)
        );
        const firstError = sampleErrors.find((sampleError) => sampleError.field === firstErrorId);

        if (firstErrorId) {
            const object = firstError.objectName;
            let bloodSampleBundleId = firstError.arguments[0];

            if (object === 'bloodmap') {
                bloodSampleBundleId = await getBloodSampleBundleIdForBloodMapId(firstError.arguments[0]);
            }
            if (object === 'edtasample') {
                bloodSampleBundleId = await getBloodSampleBundleIdForEdtaSampleId(firstError.arguments[0]);
            }
            if (object === 'serumsample') {
                bloodSampleBundleId = await getBloodSampleBundleIdForSerumSampleId(firstError.arguments[0]);
            }

            navigate(`/patientRecord/${patientId}/${bloodSampleBundleId}`);

            const element = document.querySelector(`#${firstErrorId}`)?.nextElementSibling as HTMLElement;
            element?.focus();
        }
    };

    return (
        <ModalWrapper
            className="error-overlay"
            dismissHandler={dismissHandler}
            dismissText="Back"
            header="Error"
            icon={<ErrorIcon />}
            isShown={isSampleErrorShown}
            restoreFocus={false}
            size="small"
            type="dismiss"
            onExited={onExitedHandler}
        >
            <p>
                <strong>Sample not registered.</strong>
            </p>
            {hasNoValidSample ? (
                <p>There is no accepted sample to be registered. Please check again.</p>
            ) : (
                <>
                    <p>The following fields still require adjustment:</p>
                    <ul>{sampleErrors.map((error) => mapError(error.field) && <li key={error.objectName + '.' + error.field}>{mapError(error.field)}</li>)}</ul>
                    <p>Please adjust these fields before any PrePsia risk calculation is performed.</p>
                </>
            )}
        </ModalWrapper>
    );
};
