import React from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Req } from '../Req/Req';
import './ChecklistWrapper.scss';
import { ValidationWrapper } from './ValidationWrapper';

interface ChecklistWrapperProps {
    id: string;
    isDisabled?: boolean;
    items: { [key: string]: string };
    label: string;
    labelAdd?: string;
    selected?: string[];
    required?: boolean;
    changeHandler: (object: { [key: string]: boolean }) => void;
}

export const ChecklistWrapper: React.FC<ChecklistWrapperProps> = ({
    id,
    isDisabled = false,
    label,
    labelAdd = '',
    items,
    selected = [],
    required = false,
    changeHandler,
}) => {
    let rows: any[] = [];
    let cols: any[] = [];
    const colCount = 3;

    Object.keys(items).forEach((key, index) => {
        if (index % colCount === 0) {
            cols = [];
        }
        cols.push({ [key]: items[key] });
        if (index % colCount === colCount - 1) {
            rows.push(cols);
        }
    });

    return (
        <ValidationWrapper attribute={id} label={label}>
            <div className="checklist-wrapper mb-3">
                <Row className="mb-3">
                    <Col>
                        <div className="flex">
                            <strong>{labelAdd + label}</strong> {required && <Req />}
                        </div>
                    </Col>
                </Row>
                {rows.map((row, ri) => (
                    <Row className="mb-3" key={`r_${ri}`}>
                        {row.map((col: { [key: string]: string }, ci: number) => (
                            <Col key={`c_${ci}`}>
                                <InputGroup className="checklist-wrapper__item" key={col.key}>
                                    {Object.keys(col).map((key, ii) => (
                                        <Form.Check
                                            checked={selected.indexOf(key) !== -1}
                                            disabled={isDisabled}
                                            id={key}
                                            key={`i_${ii}`}
                                            label={col[key]}
                                            type="checkbox"
                                            onChange={(event) => {
                                                changeHandler({ [key]: event.target.checked });
                                            }}
                                        />
                                    ))}
                                </InputGroup>
                            </Col>
                        ))}
                    </Row>
                ))}
            </div>
        </ValidationWrapper>
    );
};
