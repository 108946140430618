import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { BulkError } from '../components/Overlays/BulkError/BulkError';
import { BulkGenericError } from '../components/Overlays/BulkGenericError/BulkGenericError';
import { BulkSuccess } from '../components/Overlays/BulkSuccess/BulkSuccess';
import { UploadCard } from '../components/UploadCard/UploadCard';
import { ScrollTop } from '../helper/ScrollTop';
import { importResults } from '../api/result';
import { ResultCalculation } from '../types';

interface BulkImportProps {}

export const BulkImport: React.FC<BulkImportProps> = () => {
    const [filesUploaded, setFilesUploaded] = useState<File[]>([]);
    const [isCalculationShown, setIsCalculationShown] = useState(false);
    const [calculationResults, setCalculationResults] = useState<ResultCalculation[]>([]);
    const [isFailedShown, setIsFailedShown] = useState(false); // TODO check if needed
    const [isGenericErrorShown, setIsGenericErrorShown] = useState(false);
    const [error, setError] = useState({
        header: 'Error',
        message: 'There has been an error.',
    });

    const checkFileForUpload = (file: File, afterUpload: any) => {
        if (
            file.name.toLocaleLowerCase().indexOf('hilic') === -1 &&
            file.name.toLocaleLowerCase().indexOf('c18') === -1
        ) {
            setError({
                header: 'Incorrect Results File',
                message:
                    'This result file does not match the current work-list. Please ensure that the correct results file is uploaded.',
            });
            setIsGenericErrorShown(true);
            return;
        }
        if (file.type !== 'text/xml') {
            setError({
                header: 'Incorrect File Format',
                message: `This file format is not .XML. Please upload the results file in the correct .XML file format.`,
            });
            setIsGenericErrorShown(true);
            return;
        }
        if (file.size > 5 * 1000 * 1000) {
            setError({
                header: 'Maximum File Size Exceeded',
                message: `This result file has exceeded the maximum file size capacity for upload. Please ensure that the correct results file is uploaded.`,
            });
            setIsGenericErrorShown(true);
            return;
        }

        afterUpload('success');
        setFilesUploaded([...filesUploaded, file]);
    };

    const uploadFiles = async () => {
        const results = await importResults(filesUploaded[0], filesUploaded[1]);

        setCalculationResults(results);
        setIsCalculationShown(true);
    };

    return (
        <div className="bulk-import-page">
            <Header>
                <>Bulk upload results</>
            </Header>
            <Container className="mt-5">
                <Row className="mb-3">
                    <Col>
                        <h4 className="mb-4">Prepsia Results</h4>

                        <UploadCard
                            className="mb-4"
                            header="HILIC Data Upload"
                            text={
                                <>
                                    Please select a .xml file containing <strong>PrePsia HILIC data ONLY</strong> for
                                    the specific run selected.
                                </>
                            }
                            uploadHandler={checkFileForUpload}
                        />

                        <UploadCard
                            header="Reverse Phase (RP) Upload"
                            text={
                                <>
                                    Please select a .xml file containing{' '}
                                    <strong>PrePsia Reverse Phase data ONLY</strong> for the specific run selected.
                                </>
                            }
                            uploadHandler={checkFileForUpload}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="flex justify-end">
                            <Button
                                disabled={filesUploaded.length !== 2}
                                variant={filesUploaded.length !== 2 ? 'outline-primary' : 'primary'}
                                onClick={() => {
                                    uploadFiles();
                                }}
                            >
                                Upload files
                            </Button>
                        </div>
                    </Col>
                </Row>
                {/* TODO */}
                {false && (
                    <>
                        <div className="divider mt-5 mb-5" />
                        <Row>
                            <Col>
                                <h4 className="mb-4">PIGF Results</h4>
                                <div className="flex justify-between align-center">
                                    <span>Enter the PIGF results for run [...]</span>
                                    <Button
                                        disabled={filesUploaded.length !== 2}
                                        variant="primary"
                                        onClick={() => {
                                            // TODO
                                            setIsFailedShown(true);
                                        }}
                                    >
                                        Enter results
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
            <Footer />

            <BulkSuccess
                dismissHandler={() => setIsCalculationShown(false)}
                isShown={isCalculationShown}
                results={calculationResults}
            />

            <BulkError dismissHandler={() => setIsFailedShown(false)} isShown={isFailedShown} />
            <BulkGenericError
                dismissHandler={() => setIsGenericErrorShown(false)}
                errorHeader={error.header}
                errorMessage={error.message}
                isShown={isGenericErrorShown}
            />

            <ScrollTop />
        </div>
    );
};
