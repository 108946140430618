import { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { getSampleCandidates } from '../api/samplescandidates';
import { SampleCandidateWithSelection } from '../types';

interface SampleCandidatesContextProps {
    loadCandidates: () => Promise<void>;
    sampleCandidates: SampleCandidateWithSelection[];
    setSampleCandidates: Dispatch<SetStateAction<SampleCandidateWithSelection[]>>;
}

export const SampleCandidatesContext = createContext<SampleCandidatesContextProps>(null);
SampleCandidatesContext.displayName = 'SampleCandidatesContext';

const SampleCandidatesDefault: SampleCandidateWithSelection[] = [];

export const SampleCandidatesContextProvider = (props: any) => {
    const [sampleCandidates, setSampleCandidates] = useState(SampleCandidatesDefault);

    const loadCandidates = useCallback(async () => {
        const candidates = await getSampleCandidates();

        const candidatesWithSelection = candidates.map(
            (candidate) =>
                ({
                    ...candidate,
                    isSelected: false,
                } as SampleCandidateWithSelection)
        );

        setSampleCandidates(candidatesWithSelection);
    }, []);

    return (
        <SampleCandidatesContext.Provider
            value={{
                loadCandidates,
                sampleCandidates,
                setSampleCandidates,
            }}
        >
            {props.children}
        </SampleCandidatesContext.Provider>
    );
};
