import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Req } from '../Req/Req';
import { ValidationWrapper } from './ValidationWrapper';

interface SelectWrapperProps {
    clickHandler: (delta: { [key: string]: any }) => void;
    id: string;
    isDisabled?: boolean;
    items: { [key: string]: any };
    label: string;
    required?: boolean;
    selected: any;
}

export const SelectWrapper: React.FC<SelectWrapperProps> = ({
    clickHandler,
    id,
    isDisabled = false,
    items,
    label,
    required = false,
    selected,
}) => {
    return (
        <ValidationWrapper attribute={id} label={label}>
            <InputGroup>
                <InputGroup.Text id={id}>
                    <span dangerouslySetInnerHTML={{ __html: label }} /> {required && <Req />}
                </InputGroup.Text>
                <Form.Select
                    aria-describedby={id}
                    disabled={isDisabled}
                    value={selected || 'select'}
                    onChange={(event) => clickHandler({ [id]: event.target.value })}
                >
                    <option value="select" aria-disabled disabled>
                        Select
                    </option>
                    {Object.keys(items).map((key) => (
                        <option key={key} value={key}>
                            {items[key]}
                        </option>
                    ))}
                </Form.Select>
            </InputGroup>
        </ValidationWrapper>
    );
};
