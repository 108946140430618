import { endpoints, TestStatus } from "../app/config";
import { formatDateYYYYMMDD } from "../helper/dateHelper";
import HttpService from "../helper/HttpService";

interface GetPatientList {
    size: number;
    page: number;
    sortKey?: string;
    sortOrder?: string;
    testStatusFilter?: TestStatus;
    startDate?: string;
    endDate?: string;
    patientName?: string;
    signal?: AbortSignal;
}

export const getPatientList = async ({ testStatusFilter, size, page, sortKey = 'id', sortOrder = 'asc', startDate, endDate, patientName, signal = null }: GetPatientList) => {
    const axios = HttpService.getAxiosClient();
    const end = endDate || formatDateYYYYMMDD(new Date());
    const start = startDate || '1970-01-01';
    const name = patientName || '';
    let result;

    // we can pass a signal to axios to cancel the request on command (see PatientList.tsx)
    // this will throw an error we can catch in the component
    if (testStatusFilter) {
        result = await axios.get(`${endpoints.search.findAllWithTypeAndDate(testStatusFilter)}&size=${size}&page=${page}&sort=${sortKey},${sortOrder}&start=${start}&end=${end}&name=${name}`, { signal });
    } else {
        result = await axios.get(`${endpoints.search.findAllWithDate()}&size=${size}&page=${page}&sort=${sortKey},${sortOrder}&start=${start}&end=${end}&name=${name}`, { signal });
    }

    return {
        page: result.data.page,
        patientList: result.data._embedded.patients,
    };
};