import React, { useContext } from 'react';
import { DiabetesDiseaseContext } from '../../contexts/DiabetesDiseaseContext';
import { AutoimmuneDiseaseContext } from '../../contexts/AutoimmuneDiseaseContext';
import { HypertensionContext } from '../../contexts/HypertensionContext';
import { SmokerContext } from '../../contexts/SmokerContext';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import {
    AUTOIMMUNE,
    DIABETES_MEILLITUS,
    HYPERTENSION,
    INTERPREGNANCY_INTERVAL,
    NUMBER_PRIOR_BIRTHS,
    NUMBER_PRIOR_GESTATIONS,
    NUMBER_PRIOR_MISCARRIAGES,
    PRIOR_FETAL_OUTCOME_DETAILS,
    PRIOR_TREATMENT,
    PRIO_ORGAN_LESION_DETAILS,
    THROMBOPHILIA,
    YESNOUNKNOWN,
} from '../../app/config';
import { ThrombophiliaContext } from '../../contexts/ThrombophiliaContext';
import { ChronicKidneyDiseaseContext } from '../../contexts/ChronicKidneyDiseaseContext';

interface ClinicalProps {
    className?: string;
}

export const Clinical: React.FC<ClinicalProps> = ({ className = '' }) => {
    const { pregnancy } = useContext(PregnancyContext);
    const { smokerStatus } = useContext(SmokerContext);
    const { hypertension } = useContext(HypertensionContext);
    const { autoimmuneDisease } = useContext(AutoimmuneDiseaseContext);
    const { diabetesDisease } = useContext(DiabetesDiseaseContext);
    const { thrombophilia } = useContext(ThrombophiliaContext);
    const { chronicKidneyDisease } = useContext(ChronicKidneyDiseaseContext);

    return (
        <div className={className}>
            <h5>Clinical History and Risk Factors:</h5>
            <ul>
                <li>
                    <span>Diabetes:</span>
                    <span>{DIABETES_MEILLITUS[diabetesDisease]}</span>
                </li>
                <li>
                    <span>Thrombophilia:</span>
                    <span>{THROMBOPHILIA[thrombophilia]}</span>
                </li>
                <li>
                    <span>Autoimmune Disease:</span>
                    <span>{AUTOIMMUNE[autoimmuneDisease]}</span>
                </li>
                <li>
                    <span>Hypertension:</span>
                    <span>{HYPERTENSION[hypertension]}</span>
                </li>
                <li>
                    <span>Smoker:</span>
                    <span>{YESNOUNKNOWN[smokerStatus]}</span>
                </li>
                <li>
                    <span>Chronic Kidney Disease</span>
                    <span>{YESNOUNKNOWN[chronicKidneyDisease]}</span>
                </li>
            </ul>
            <ul>
                <li>
                    <span>Number of prior gestations:</span>
                    <span>{NUMBER_PRIOR_GESTATIONS[pregnancy.numberOfPriorGestations]}</span>
                </li>
                <li className="indented">
                    <span>Number of prior births:</span>
                    <span>{NUMBER_PRIOR_BIRTHS[pregnancy.numberOfPriorBirth]}</span>
                </li>
                <li className="indented">
                    <span>Number of prior miscarriages:</span>
                    <span>{NUMBER_PRIOR_MISCARRIAGES[pregnancy.numberOfPriorMiscarriages]}</span>
                </li>
                <li>
                    <span>Inter-pregnancy interval:</span>
                    <span>{INTERPREGNANCY_INTERVAL[pregnancy.interpregnancyInterval]}</span>
                </li>
            </ul>
            <ul>
                <li>
                    <span>Different previous father</span>
                    <span>{YESNOUNKNOWN[pregnancy.differentPreviousFather]}</span>
                </li>
            </ul>
            <ul>
                <li>
                    <span>Previous Pre-eclampsia</span>
                    <span>{YESNOUNKNOWN[pregnancy.prevPreeclampsiaPregnancy]}</span>
                </li>
                <li className="indented">
                    <span>Prior Treatment:</span>
                    <span>{PRIOR_TREATMENT[pregnancy.priorTreatment]}</span>
                </li>
            </ul>
            <ul>
                <li>
                    <span>History of adverse pregnancy outcome:</span>
                    <span>{YESNOUNKNOWN[pregnancy.historyAdverseOutcomeAvailable]}</span>
                </li>
                <li className="indented">
                    <span>Prior fetal otucome:</span>
                    <span>{YESNOUNKNOWN[pregnancy.priorFetalOutcome]}</span>
                </li>
                <li className="more-indented">
                    <span>Details</span>
                    <span>
                        {pregnancy.priorFetalOutcomesDetails.map((detail, index) => (
                            <span>
                                {PRIOR_FETAL_OUTCOME_DETAILS[detail]}
                                {index !== pregnancy.priorFetalOutcomesDetails.length - 1 && <>, </>}
                            </span>
                        ))}
                    </span>
                </li>
                <li className="indented">
                    <span>Prior maternal outcome</span>
                    <span>{YESNOUNKNOWN[pregnancy.priorMaternalOutcome]}</span>
                </li>
                <li className="more-indented">
                    <span>ICU Admission:</span>
                    <span>{YESNOUNKNOWN[pregnancy.priorIcuAdmission]}</span>
                </li>
                <li className="more-indented">
                    <span>HELLP Syndrome:</span>
                    <span>{YESNOUNKNOWN[pregnancy.priorHellpSyndrome]}</span>
                </li>
                <li className="more-indented">
                    <span>Target Organ Lesion:</span>
                    <span>{YESNOUNKNOWN[pregnancy.priorOrganLesion]}</span>
                </li>
                <li className="more-indented">
                    <span>TOL Details</span>
                    <span>
                        {pregnancy.priorOrganLesionDetails.map((detail) => (
                            <span>{PRIO_ORGAN_LESION_DETAILS[detail]}</span>
                        ))}
                    </span>
                </li>
            </ul>
        </div>
    );
};
