import React from 'react';
import './Scale.scss';

interface ScaleProps {
    value?: number;
    variant?: 'primary' | 'secondary' | 'info';
    showMedian?: boolean;
}

export const Scale: React.FC<ScaleProps> = ({ value = 1, variant = 'primary', showMedian }) => {
    const positionLeft = (value / 2) * 100;
    return (
        <div className={`scale scale--${variant}`}>
            <div className="scale__center"></div>
            <div className="scale__line"></div>

            {showMedian ? (
                <div className="scale__median">1</div>
            ) : (
                <div className="scale__value" style={{ left: positionLeft + '%' }}></div>
            )}
        </div>
    );
};
