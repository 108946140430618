import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { BloodSampleBundle, Links } from "../types";
import { mapIncomingValues, mapOutgoingValues } from "./mapping";

interface BloodSampleBundleWithLinks extends BloodSampleBundle, Links { }

export const getBloodSampleBundlesByPregnancyId = async (pregnancyId: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.pregnancies}/${pregnancyId}/bloodSampleBundles`);

    const mappedResults = result.data._embedded.bloodsamplebundles.map(mapIncomingValues);

    return mappedResults as BloodSampleBundleWithLinks[];
};

export interface CreateBloodSampleBundleParams {
    data?: BloodSampleBundle;
    pregnancyId: number;
}

export const createBloodSampleBundleForPregnancy = async ({ data = {}, pregnancyId }: CreateBloodSampleBundleParams) => {
    const axios = HttpService.getAxiosClient();

    const result = await axios.post(`${endpoints.data.bloodsamplebundles}`, {
        pregnancy: `${endpoints.data.pregnancies}/${pregnancyId}`,
        ...data
    });

    return result.data as BloodSampleBundle;
}

export const deleteBloodSampleBundleById = async (bloodSampleBundleId: number) => {
    const axios = HttpService.getAxiosClient();

    return await axios.delete(`${endpoints.data.bloodsamplebundles}/${bloodSampleBundleId}`);
}


export const updateBloodSampleBundleById = async (delta: { [key: string]: any }, id: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.bloodsamplebundles}/${id}`, {
        ...mapOutgoingValues(delta)
    });
}

export const getBloodSampleBundleForPigfByPregnancyId = async (pregnancyId: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.patient.bloodsamplebundleForPigf}?pregnancyId=${pregnancyId}`);

    return result.data.bloodsampleBundleId as number;
}
