import { useCallback, useContext, useEffect } from "react";
import { AutoimmuneDiseaseContext } from "../contexts/AutoimmuneDiseaseContext";
import { ChronicKidneyDiseaseContext } from "../contexts/ChronicKidneyDiseaseContext";
import { HypertensionContext } from "../contexts/HypertensionContext";
import { isValidIntegerId } from "../helper/isValidId";
import { PatientContext } from "../contexts/PatientContext";
import { PregnancyContext } from "../contexts/PregnancyContext";
import { ResidenceContext } from "../contexts/ResidenceContext";
import { SmokerContext } from "../contexts/SmokerContext";
import { ThrombophiliaContext } from "../contexts/ThrombophiliaContext";
import { DiabetesDiseaseContext } from "../contexts/DiabetesDiseaseContext";

export function useLoadPatientForId(patientId: number | string) {
    const { loadAutoimmuneDiseaseForPatient, clearAutoimmuneDisease } = useContext(AutoimmuneDiseaseContext);
    const { loadChronicKidneyDiseaseForPatient, clearChronicKidneyDisease } = useContext(ChronicKidneyDiseaseContext);
    const { loadHypertensionForPatient, clearHypertension } = useContext(HypertensionContext);
    const { loadPatientById, clearPatient } = useContext(PatientContext);
    const { loadPregnancyForPatient, clearPregnancy } = useContext(PregnancyContext);
    const { loadResidenceForPatient, clearResidence } = useContext(ResidenceContext);
    const { loadSmokerStatusForPatient, clearSmokerStatus } = useContext(SmokerContext);
    const { loadThrombophiliaForPatient, clearThrombophilia } = useContext(ThrombophiliaContext);
    const { loadDiabetesDiseaseForPatient, clearDiabetesDisease } = useContext(DiabetesDiseaseContext);

    const cleanup = useCallback(() => {
        clearAutoimmuneDisease();
        clearChronicKidneyDisease();
        clearHypertension();
        clearPatient();
        clearPregnancy();
        clearResidence();
        clearSmokerStatus();
        clearThrombophilia();
        clearDiabetesDisease();
    }, [
        clearAutoimmuneDisease,
        clearChronicKidneyDisease,
        clearHypertension,
        clearPatient,
        clearPregnancy,
        clearResidence,
        clearSmokerStatus,
        clearThrombophilia,
        clearDiabetesDisease,
    ])

    useEffect(() => {
        // TODO add error handling ?
        if (isValidIntegerId(patientId)) {
            const id = Number(patientId);
            loadAutoimmuneDiseaseForPatient(id);
            loadChronicKidneyDiseaseForPatient(id);
            loadHypertensionForPatient(id);
            loadPatientById(id);
            loadPregnancyForPatient(id);
            loadResidenceForPatient(id);
            loadSmokerStatusForPatient(id);
            loadThrombophiliaForPatient(id);
            loadDiabetesDiseaseForPatient(id);
        } else {
            cleanup();
        }

        return cleanup;
    }, [
        cleanup,
        loadAutoimmuneDiseaseForPatient,
        loadChronicKidneyDiseaseForPatient,
        loadHypertensionForPatient,
        loadPatientById,
        loadPregnancyForPatient,
        loadResidenceForPatient,
        loadSmokerStatusForPatient,
        loadThrombophiliaForPatient,
        loadDiabetesDiseaseForPatient,
        patientId,
    ]);
}