import clsx from 'clsx';
import React from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';
import { ReactComponent as DangerIcon } from '../../resources/icons/danger.svg';
import { ReactComponent as WarningIcon } from '../../resources/icons/warning.svg';

import './ModalWrapper.scss';

type ModalType = 'dismiss' | 'confirm' | 'choice';
type ModalIcon = 'danger' | 'warning';
type ModalSize = 'small' | 'default' | 'large';

interface ModalWrapperProps {
    alternativeHandler?: () => void;
    alternativeText?: string;
    children: any;
    className?: string;
    confirmHandler?: () => void;
    confirmText?: string;
    dismissHandler: () => void;
    dismissText?: string;
    header: string;
    icon?: any | ModalIcon;
    isShown: boolean;
    onExited?: () => void;
    restoreFocus?: boolean;
    size?: ModalSize;
    type?: ModalType;
}

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
    alternativeHandler = () => {},
    alternativeText = '',
    className = '',
    children,
    confirmHandler = () => {},
    confirmText = 'Confirm',
    dismissHandler,
    dismissText = 'Cancel',
    header,
    icon = <></>,
    isShown,
    onExited = () => {},
    restoreFocus = true,
    size = 'default',
    type = 'dismiss',
}) => {
    let buttonRow;

    switch (type) {
        case 'confirm':
            buttonRow = (
                <Stack direction="horizontal" gap={3}>
                    <Button variant="outline-primary" onClick={dismissHandler}>
                        {dismissText}
                    </Button>
                    <Button variant="primary" onClick={confirmHandler}>
                        {confirmText}
                    </Button>
                </Stack>
            );
            break;

        case 'choice':
            buttonRow = (
                <Stack direction="horizontal" gap={3}>
                    <Button variant="outline-primary" onClick={alternativeHandler}>
                        {alternativeText}
                    </Button>
                    <Button variant="primary" onClick={confirmHandler}>
                        {confirmText}
                    </Button>
                </Stack>
            );
            break;

        case 'dismiss':
        default:
            buttonRow = <Button onClick={dismissHandler}>Close</Button>;
            break;
    }

    const classes = clsx(className, `modal--${size}`, {
        [`modal--${icon}`]: typeof icon === 'string',
    });

    return (
        <Modal
            className={classes}
            restoreFocus={restoreFocus}
            show={isShown}
            size="lg"
            centered
            onExited={onExited}
            onHide={dismissHandler}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {icon === 'danger' && <DangerIcon />}
                    {icon === 'warning' && <WarningIcon />}
                    {typeof icon !== 'string' && icon}
                    {header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>{buttonRow}</Modal.Footer>
        </Modal>
    );
};
