import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AUTOIMMUNE, DIABETES_MEILLITUS, HYPERTENSION, THROMBOPHILIA } from '../../app/config';
import { PREGNANCY_STATE } from '../../app/constants';
import { AutoimmuneDiseaseContext } from '../../contexts/AutoimmuneDiseaseContext';
import { ChronicKidneyDiseaseContext } from '../../contexts/ChronicKidneyDiseaseContext';
import { DiabetesDiseaseContext } from '../../contexts/DiabetesDiseaseContext';
import { HypertensionContext } from '../../contexts/HypertensionContext';
import { PatientContext } from '../../contexts/PatientContext';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { SmokerContext } from '../../contexts/SmokerContext';
import { ThrombophiliaContext } from '../../contexts/ThrombophiliaContext';
import { SelectWrapper } from '../Wrapper/SelectWrapper';
import { YesNoUnknownWrapper } from '../Wrapper/YesNoUnknownWrapper';

interface RiskFactorsProps {}

export const RiskFactors: React.FC<RiskFactorsProps> = () => {
    const { patient, updatePatientAttribute } = useContext(PatientContext);
    const { smokerStatus, updateSmokerStatus } = useContext(SmokerContext);
    const { autoimmuneDisease, updateAutoimmuneDisease } = useContext(AutoimmuneDiseaseContext);
    const { chronicKidneyDisease, updateChronicKidneyDisease } = useContext(ChronicKidneyDiseaseContext);
    const { hypertension, updateHypertension } = useContext(HypertensionContext);
    const { thrombophilia, updateThrombophilia } = useContext(ThrombophiliaContext);
    const { pregnancy } = useContext(PregnancyContext);
    const { diabetesDisease, updateDiabetesDisease } = useContext(DiabetesDiseaseContext);

    return (
        <div className="risk-factors">
            <Row className="mb-3">
                <Col>
                    <YesNoUnknownWrapper
                        clickHandler={updatePatientAttribute}
                        id="familialPreeclampsia"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Familial Preeclampsia"
                        selected={patient.familialPreeclampsia}
                    />
                </Col>
                <Col>
                    <YesNoUnknownWrapper
                        attribute="status"
                        clickHandler={updateSmokerStatus}
                        id="smoker"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Smoker"
                        selected={smokerStatus}
                        required
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <SelectWrapper
                        clickHandler={updateDiabetesDisease as (delta: { [key: string]: any }) => void}
                        id="diabetes"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        items={DIABETES_MEILLITUS}
                        label="Diabetes Meillitus"
                        selected={diabetesDisease}
                    />
                </Col>
                <Col>
                    <YesNoUnknownWrapper
                        attribute="status"
                        clickHandler={updateChronicKidneyDisease}
                        id="chronicKidneyDisease"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Chronic Kidney Disease"
                        selected={chronicKidneyDisease}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <SelectWrapper
                        clickHandler={updateThrombophilia as (delta: { [key: string]: any }) => void}
                        id="thrombophilia"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        items={THROMBOPHILIA}
                        label="Thrombophilia"
                        selected={thrombophilia}
                    />
                </Col>
                <Col>
                    <SelectWrapper
                        clickHandler={updateHypertension as (delta: { [key: string]: any }) => void}
                        id="hypertension"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        items={HYPERTENSION}
                        label="Hypertension"
                        selected={hypertension}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <SelectWrapper
                        clickHandler={updateAutoimmuneDisease as (delta: { [key: string]: any }) => void}
                        id="autoimmune"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        items={AUTOIMMUNE}
                        label="Autoimmune Disease"
                        selected={autoimmuneDisease}
                    />
                </Col>
                <Col />
            </Row>
        </div>
    );
};
