import { PlateMapItem } from '../../types';

export const PLATE_MAP_DEFAULTS: PlateMapItem[] = [
    {
        xPosition: 1,
        yPosition: 1,
    },
    {
        content: '1',
        variant: 'high',
        xPosition: 10,
        yPosition: 1,
    },
    {
        content: '1',
        variant: 'low',
        xPosition: 11,
        yPosition: 1,
    },
    {
        content: '7',
        variant: 'calibrator',
        xPosition: 2,
        yPosition: 1,
    },
    {
        content: '6',
        variant: 'calibrator',
        xPosition: 3,
        yPosition: 1,
    },
    {
        content: '5',
        variant: 'calibrator',
        xPosition: 4,
        yPosition: 1,
    },
    {
        content: '4',
        variant: 'calibrator',
        xPosition: 5,
        yPosition: 1,
    },
    {
        content: '3',
        variant: 'calibrator',
        xPosition: 6,
        yPosition: 1,
    },
    {
        content: '2',
        variant: 'calibrator',
        xPosition: 7,
        yPosition: 1,
    },
    {
        content: '1',
        variant: 'calibrator',
        xPosition: 8,
        yPosition: 1,
    },
    {
        content: '1',
        variant: 'medium',
        xPosition: 9,
        yPosition: 1,
    },
    {
        content: '2',
        variant: 'low',
        xPosition: 6,
        yPosition: 2,
    },
    {
        content: '3',
        variant: 'low',
        xPosition: 9,
        yPosition: 3,
    },
    {
        content: '2',
        variant: 'medium',
        xPosition: 4,
        yPosition: 4,
    },
    {
        content: '4',
        variant: 'low',
        xPosition: 11,
        yPosition: 5,
    },
    {
        content: '5',
        variant: 'low',
        xPosition: 8,
        yPosition: 6,
    },
    {
        content: '6',
        variant: 'low',
        xPosition: 1,
        yPosition: 7,
    },
    {
        content: '3',
        variant: 'calibrator',
        xPosition: 10,
        yPosition: 8,
    },
    {
        content: '2',
        variant: 'calibrator',
        xPosition: 11,
        yPosition: 8,
    },
    {
        content: '1',
        variant: 'calibrator',
        xPosition: 12,
        yPosition: 8,
    },
    {
        content: '2',
        variant: 'high',
        xPosition: 5,
        yPosition: 8,
    },
    {
        content: '7',
        variant: 'calibrator',
        xPosition: 6,
        yPosition: 8,
    },
    {
        content: '6',
        variant: 'calibrator',
        xPosition: 7,
        yPosition: 8,
    },
    {
        content: '5',
        variant: 'calibrator',
        xPosition: 8,
        yPosition: 8,
    },
    {
        content: '4',
        variant: 'calibrator',
        xPosition: 9,
        yPosition: 8,
    },
];
