import { createContext, Dispatch, SetStateAction, useState } from 'react';

interface SettingsContextProps {
    headerHeight: number;
    setHeaderHeight: Dispatch<SetStateAction<number>>;
}

export const SettingsContext = createContext<SettingsContextProps>(null);
SettingsContext.displayName = 'SettingsContext';

export const SettingsContextProvider = (props: any) => {
    const [headerHeight, setHeaderHeight] = useState(0);

    return (
        <SettingsContext.Provider
            value={{
                headerHeight,
                setHeaderHeight,
            }}
        >
            {props.children}
        </SettingsContext.Provider>
    );
};
