export enum SORTING {
    ASC = 'asc',
    DESC = 'desc'
}

export enum UNITTYPES {
    IMPERIAL = 'IMPERIAL',
    METRIC = 'METRIC'
}

export enum STATE {
    INIT = 'INIT',
    PENDING = 'PENDING',
    DONE = 'DONE',
}

export enum ERRORS {
    ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
}

export enum YESNOUNKNOWN {
    YES = 'YES',
    NO = 'NO',
    UNKNOWN = 'UNKNOWN'
}

export enum POST_EVENT {
    SUBMIT_PATIENT_FORM = 'SUBMIT_PATIENT_FORM',
    REGISTER_SAMPLE = 'REGISTER_SAMPLE',
    REGISTER_PLATEMAP = 'REGISTER_PLATEMAP',
    CONFIRM_FALED_RESULTS = 'CONFIRM_FALED_RESULTS',
    CONFIRM_RESULT = 'CONFIRM_RESULT',
    REGISTER_OUTCOME = 'REGISTER_OUTCOME',
    CANCEL = 'CANCEL'
}

export enum OBJECTS {
    PATIENT = 'patient',
    PREGNANCY = 'pregnancy',
    BLOOD_SAMPLE_BUNDLE = 'bloodsamplebundle',
    BLOOD_MAP = 'bloodmap',
    EDTA_SAMPLE = 'edtasample',
    SERUM_SAMPLE = 'serumsample',
    RESIDENCE = 'residence',
}

export enum PREGNANCY_STATE {
    CANCEL = 'CANCEL',
    DRAFT_PATIENT = 'DRAFT_PATIENT',
    DRAFT_SAMPLE = 'DRAFT_SAMPLE',
    WAIT_FOR_PLATE_MAP_PRE_PSIA_ASSIGNMENT = 'WAIT_FOR_PLATE_MAP_PRE_PSIA_ASSIGNMENT',
    WAIT_FOR_RESULT = 'WAIT_FOR_RESULT',
    OUTCOME = 'OUTCOME',
    ARCHIVED = 'ARCHIVED',
}