import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";
import { SampleCandidate } from "../types";

export const getSampleCandidates = async () => {
    const axios = HttpService.getAxiosClient();

    const result = await axios.get(`${endpoints.platemap.samplecandidates}`);

    return result.data as SampleCandidate[];
};
