import React from 'react';
import { useNavigate } from 'react-router';
import { FailedSamples } from '../../FailedSamples/FailedSamples';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

interface BulkErrorProps {
    dismissHandler: () => void;
    isShown: boolean;
}

export const BulkError: React.FC<BulkErrorProps> = ({ dismissHandler, isShown }) => {
    const navigate = useNavigate();
    return (
        <ModalWrapper
            confirmHandler={() => navigate(`/platemap`)}
            confirmText="Add now"
            dismissHandler={dismissHandler}
            dismissText="Add later"
            header="Failed Samples"
            icon="danger"
            isShown={isShown}
            type="confirm"
        >
            <FailedSamples />
        </ModalWrapper>
    );
};
