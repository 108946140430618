import { createContext, useCallback, useState } from 'react';
import { getSmoker, updateSmokerForPatientId } from '../api/smoker';
import { YesNoUnkown } from '../app/config';
import { debouncedSaveWithErrors } from '../helper/apiHelper';

interface SmokerContextProps {
    clearSmokerStatus: () => void;
    loadSmokerStatusForPatient: (patientId: number) => void;
    smokerStatus: YesNoUnkown;
    updateSmokerStatus: (delta: { status: YesNoUnkown }) => void;
}

export const SmokerContext = createContext<SmokerContextProps>(null);
SmokerContext.displayName = 'SmokerContext';

export const SmokerContextProvider = (props: any) => {
    const [smokerStatus, setSmokerStatus] = useState<YesNoUnkown>(null);
    const [smokerId, setSmokerId] = useState<number>(null);

    const clearSmokerStatus = useCallback(() => {
        setSmokerStatus(null);
        setSmokerId(null);
    }, []);

    const updateSmokerStatus = async (delta: { status: YesNoUnkown }) => {
        await debouncedSaveWithErrors({ callApi: updateSmokerForPatientId, setErrors: () => {} })(delta, smokerId);
        setSmokerStatus(delta.status);
    };

    const loadSmokerStatusForPatient = useCallback(async (patientId: number) => {
        const { id, status } = await getSmoker(patientId);
        setSmokerId(id);
        setSmokerStatus(status);
    }, []);

    return (
        <SmokerContext.Provider
            value={{
                clearSmokerStatus,
                loadSmokerStatusForPatient,
                smokerStatus,
                updateSmokerStatus,
            }}
        >
            {props.children}
        </SmokerContext.Provider>
    );
};
