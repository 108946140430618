import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

import { ReactComponent as SuccessIcon } from '../../../resources/icons/eye.svg';

interface PatientRegisterSuccessProps {
    dismissHandler: () => void;
    isShown: boolean;
}

export const PatientRegisterSuccess: React.FC<PatientRegisterSuccessProps> = ({ dismissHandler, isShown }) => {
    const navigate = useNavigate();
    const { patientId } = useParams();

    return (
        <ModalWrapper
            alternativeHandler={() => {
                navigate('/home');
            }}
            alternativeText="Home"
            confirmHandler={() => {
                navigate(`/patientRecord/${patientId}`);
            }}
            confirmText="View"
            dismissHandler={dismissHandler}
            header="Success"
            icon={<SuccessIcon />}
            isShown={isShown}
            size="small"
            type="choice"
        >
            Patient deails successfully registered.
        </ModalWrapper>
    );
};
