import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import './Footer.scss';

export default function Footer() {
    return (
        <Navbar bg="white" className='mt-5'>
            <Container className="footer-container">
                <Nav>
                    <Nav.Item>
                        Metabolomic Diagnostics. company no. 496661
                    </Nav.Item>
                </Nav>
                <Nav className="justify-content-end">
                    <Nav.Link> Terms &amp; Conditions </Nav.Link>
                    <Nav.Link> Privacy Policy </Nav.Link>
                    <Nav.Link> Manage Cookies </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}
