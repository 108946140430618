import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { COUNTRIES, COUNTRIES_DATA, ETHNICITIES, RACES_OTHER, RACES_US } from '../../app/config';
import { InputWrapper } from '../Wrapper/InputWrapper';
import { SelectWrapper } from '../Wrapper/SelectWrapper';
import { PREGNANCY_STATE, UNITTYPES, YESNOUNKNOWN } from '../../app/constants';
import { PatientContext } from '../../contexts/PatientContext';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { ResidenceContext } from '../../contexts/ResidenceContext';
import { YesNoUnknownWrapper } from '../Wrapper/YesNoUnknownWrapper';

import { Headline } from '../Headline/Headline';
import { DateWrapper } from '../Wrapper/DateWrapper';

interface PatientDetailsProps {}

export const PatientDetails: React.FC<PatientDetailsProps> = () => {
    const { patient, updatePatientAttribute } = useContext(PatientContext);
    const { pregnancy, updatePregnancyAttribute } = useContext(PregnancyContext);
    const { residence, updateResidenceAttribute } = useContext(ResidenceContext);

    const updateCountry = async (delta: { [key: string]: any }) => {
        // clear race and ethnicity on change
        await updatePatientAttribute({
            raceUs: null,
        });
        await updatePatientAttribute({
            raceOther: null,
        });
        await updatePatientAttribute({
            ethnicity: null,
        });

        updateResidenceAttribute(delta);
    };

    // COUNTRIES AND STATES
    let states: any = {};

    if (!!residence?.country) {
        states = COUNTRIES_DATA[residence?.country];
    }

    return (
        <div className="patient-details form-section mb-5">
            <Headline headline="1. Patient details" requiredInfo />
            <Row className="mb-5">
                <Col>
                    <YesNoUnknownWrapper
                        clickHandler={updatePatientAttribute}
                        id="anonymised"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Anonymise patient data input"
                        selected={patient.anonymised}
                        noUnknown
                        required
                    />
                </Col>
                <Col>
                    <InputWrapper
                        changeHandler={updatePatientAttribute}
                        id="externalPatientId"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Laboratory ID"
                        value={patient.externalPatientId}
                        required
                    />
                </Col>
            </Row>
            {patient.anonymised === YESNOUNKNOWN.NO && (
                <Row className="mb-3">
                    <Col>
                        <InputWrapper
                            changeHandler={updatePatientAttribute}
                            id="surname"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            label="Surname"
                            value={patient.surname}
                            required
                        />
                    </Col>
                    <Col></Col>
                </Row>
            )}
            <Row className="mb-3">
                {patient.anonymised === YESNOUNKNOWN.NO ? (
                    <Col>
                        <InputWrapper
                            changeHandler={updatePatientAttribute}
                            id="forename"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            label="Forename(s)"
                            value={patient.forename}
                            required
                        />
                    </Col>
                ) : (
                    <Col />
                )}
                <Col>
                    <InputWrapper
                        changeHandler={updatePatientAttribute}
                        id="height"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Height"
                        placeholder={patient.unitTypeHeight !== UNITTYPES.IMPERIAL ? 'm' : 'ft'}
                        textClass="small"
                        unit={patient.unitTypeHeight !== UNITTYPES.IMPERIAL ? 'm' : 'ft'}
                        value={patient.height}
                        required
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <DateWrapper
                        blurHandler={updatePatientAttribute}
                        id="birthDate"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Date of Birth"
                        value={patient.birthDate}
                        required
                    />
                </Col>
                <Col>
                    <InputWrapper
                        changeHandler={updatePregnancyAttribute}
                        id="weight"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        label="Weight"
                        placeholder={pregnancy.unitTypeWeight !== UNITTYPES.IMPERIAL ? 'kg' : 'lb'}
                        textClass="small"
                        unit={pregnancy.unitTypeWeight !== UNITTYPES.IMPERIAL ? 'kg' : 'lb'}
                        value={pregnancy.weight}
                        required
                    />
                </Col>
            </Row>
            <Row className="mb-4 mt-4">
                <div className="divider" />
            </Row>
            <Row className="mb-3">
                <Col>
                    <SelectWrapper
                        clickHandler={updateCountry}
                        id="country"
                        isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        items={COUNTRIES}
                        label="Current country of residence"
                        selected={residence.country}
                        required
                    />
                </Col>
                <Col />
            </Row>

            {!!residence.country && (
                <Row className="mb-3">
                    <Col>
                        <InputWrapper
                            changeHandler={updateResidenceAttribute}
                            id="zipCode"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            label="Postal / ZIP Code"
                            value={residence.zipCode}
                            required
                        />
                    </Col>
                    <Col>
                        {residence.country === COUNTRIES.USA && (
                            <SelectWrapper
                                clickHandler={updatePatientAttribute}
                                id="ethnicity"
                                isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                                items={ETHNICITIES}
                                label="Ethnicity"
                                selected={patient.ethnicity}
                                required
                            />
                        )}
                    </Col>
                </Row>
            )}
            <Row className="mb-3">
                <Col>
                    <SelectWrapper
                        clickHandler={updateResidenceAttribute}
                        id="region"
                        isDisabled={!!!residence.country || pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                        items={states}
                        label="State/Region/County"
                        selected={residence.region}
                        required
                    />
                </Col>
                <Col>
                    {residence.country === COUNTRIES.USA ? (
                        <SelectWrapper
                            clickHandler={updatePatientAttribute}
                            id="raceUs"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            items={RACES_US}
                            label="Race"
                            selected={patient.raceUs}
                            required
                        />
                    ) : (
                        <SelectWrapper
                            clickHandler={updatePatientAttribute}
                            id="raceOther"
                            isDisabled={pregnancy.pregnancyState !== PREGNANCY_STATE.DRAFT_PATIENT}
                            items={RACES_OTHER}
                            label="Race"
                            selected={patient.raceOther}
                            required
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};
