import React, { useContext, useEffect, useState } from 'react';
import { GenericTable } from '../GenericTable/GenericTable';
import { PREGNANCY_STATE, YESNOUNKNOWN } from '../../app/constants';
import { BloodSampleBundlesContext } from '../../contexts/BloodSampleBundlesContext';
import { EdtaSamplesContext } from '../../contexts/EdtaSamplesContext';
import { SerumSamplesContext } from '../../contexts/SerumSamplesContext';
import { SAMPLE_CONDITION } from '../../app/config';
import { ReactComponent as EditIcon } from '../../resources/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../resources/icons/delete.svg';
import { useNavigate, useParams } from 'react-router';

import './SampleTable.scss';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { Button } from 'react-bootstrap';

interface SampleTableProps {
    registerSample: () => void;
}

export const SampleTable: React.FC<SampleTableProps> = ({ registerSample }) => {
    const navigate = useNavigate();
    const { patientId } = useParams();
    const { pregnancy } = useContext(PregnancyContext);
    const { bloodSampleBundles, deleteBloodSampleBundle } = useContext(BloodSampleBundlesContext);
    const { edtaSamples, getEdtaSampleByBloodSampleBundleId } = useContext(EdtaSamplesContext);
    const { serumSamples, getSerumSampleByBloodSampleBundleId } = useContext(SerumSamplesContext);
    const [data, setData] = useState<any[]>([]);

    const header = ['Sample Id', 'Taken', 'Received', 'Accepted', 'Type / Volume / Condition', 'Notes', ''];

    useEffect(() => {
        if (edtaSamples.length > 0 && serumSamples.length > 0 && bloodSampleBundles.length > 0) {
            const tableData: any[] = [];
            bloodSampleBundles.forEach((bloodSampleBundle) => {
                const edtaSample = getEdtaSampleByBloodSampleBundleId(bloodSampleBundle.id);
                const serumSample = getSerumSampleByBloodSampleBundleId(bloodSampleBundle.id);

                tableData.push([
                    bloodSampleBundle.id,
                    bloodSampleBundle.dateTaken,
                    bloodSampleBundle.dateReceived,
                    YESNOUNKNOWN[bloodSampleBundle.sampleAccepted],
                    <>
                        <span> EDTA </span>
                        {edtaSample?.approxVol && (
                            <span>
                                {edtaSample?.approxVol} <small>ml</small>
                            </span>
                        )}
                        <span>{SAMPLE_CONDITION[edtaSample?.sampleCondition]}</span>
                        <br />
                        <span>PIGF</span>
                        {serumSample?.approxVol && (
                            <span>
                                {serumSample?.approxVol} <small>ml</small>
                            </span>
                        )}
                        <span>{SAMPLE_CONDITION[serumSample?.sampleCondition]}</span>
                    </>,
                    bloodSampleBundle.sampleNotes,
                    <>
                        {pregnancy.pregnancyState === PREGNANCY_STATE.DRAFT_SAMPLE && (
                            <>
                                <EditIcon
                                    className="icon-as-btn edit-icon"
                                    onClick={() => navigate(`/patientRecord/${patientId}/${bloodSampleBundle.id}`)}
                                />
                                {/* TODO ADD POPUP? */}
                                <DeleteIcon
                                    className="icon-as-btn delete-icon"
                                    onClick={() => deleteBloodSampleBundle(bloodSampleBundle.id)}
                                />
                            </>
                        )}
                    </>,
                ]);
            });
            setData(tableData);
        } else {
            setData([]);
        }

    }, [
        bloodSampleBundles,
        deleteBloodSampleBundle,
        edtaSamples,
        getEdtaSampleByBloodSampleBundleId,
        getSerumSampleByBloodSampleBundleId,
        navigate,
        patientId,
        registerSample,
        serumSamples,
        pregnancy.pregnancyState,
    ]);

    return (
        <>
            {data && data.length > 0 && (
                <>
                    <GenericTable className="sample-table" data={data} header={header} />
                    {pregnancy.pregnancyState === PREGNANCY_STATE.DRAFT_SAMPLE && (
                        <div className="flex justify-end">
                            <Button onClick={() => registerSample()}>Submit</Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
