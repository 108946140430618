import React, { useState } from 'react';
import { ResultCalculation, ResultValuation } from '../../../types';
import { mapPosition } from '../../../helper/plateMapHelper';

import './BulkFailedSamples.scss';

import { ReactComponent as DeleteIcon } from '../../../resources/icons/delete.svg';
import { ReactComponent as AddIcon } from '../../../resources/icons/add.svg';
import { ReactComponent as TriangleIcon } from '../../../resources/icons/triangle.svg';
import { approveResult, rejectResult } from '../../../api/result';

interface BulkFailedSamplesProps {
    failedSamples: ResultCalculation[];
}

export const BulkFailedSamples: React.FC<BulkFailedSamplesProps> = ({ failedSamples }) => {
    return (
        <div className="bulk-failed-samples">
            <div className="bulk-failed-samples__row">
                <span title="PlateMap position">Position</span>
                <span title="Quality Control">QC</span>
                <span>Actions</span>
            </div>
            {failedSamples.map((failedSample) => (
                <BulkSample failedSample={failedSample} />
            ))}
        </div>
    );
};

const BulkSample = ({ failedSample }: { failedSample: ResultCalculation }) => {
    const [metaboliteDetailsShown, setMetaboliteDetailsShown] = useState(false);
    const [status, setStatus] = useState<'pending' | 'approved' | 'rejected'>('pending');

    const position = mapPosition(failedSample.plateMapEntry.xPosition, failedSample.plateMapEntry.yPosition);

    const rejectSample = async () => {
        const result = await rejectResult(failedSample.plateMapEntry.id);
        console.log('rejected sample', failedSample.id, result);
        setStatus('rejected');
    };

    const approveSample = async () => {
        const result = await approveResult(failedSample.plateMapEntry.id);
        console.log('approved sample', failedSample.id, result);
        setStatus('approved');
    };

    const getRangeName = (valuation: ResultValuation) => {
        if (valuation.afterRange) {
            return 'high';
        }
        if (valuation.beforeRange) {
            return 'low';
        }
        return 'normal';
    };

    return (
        <div className="bulk-failed-samples__row">
            <div>{position}</div>
            <div>
                {metaboliteDetailsShown ? (
                    <>
                        <div onClick={() => setMetaboliteDetailsShown(!metaboliteDetailsShown)}>
                            <span className="bulk-failed-samples__toggle">
                                <TriangleIcon /> hide failed metabolites
                            </span>
                        </div>
                        <div className="bulk-failed-samples__metabolites">
                            {failedSample.resultValuations
                                .filter((valuation: ResultValuation) => valuation.afterRange || valuation.beforeRange)
                                .map((valuation: ResultValuation) => (
                                    <span>
                                        <small
                                            className={`bulk-failed-samples__range bulk-failed-samples__range--${getRangeName(
                                                valuation
                                            )}`}
                                        >
                                            ({getRangeName(valuation)})
                                        </small>
                                        {valuation.metaboliteId}
                                    </span>
                                ))}
                        </div>
                    </>
                ) : (
                    <div onClick={() => setMetaboliteDetailsShown(!metaboliteDetailsShown)}>
                        <span className="bulk-failed-samples__toggle">
                            <TriangleIcon className="rotate-180" /> show failed metabolites
                        </span>
                    </div>
                )}
            </div>
            <div className="bulk-failed-samples__actions">
                {status === 'approved' && (
                    <span className="bulk-failed-samples__action bulk-failed-samples__action--approved">approved</span>
                )}
                {status === 'rejected' && (
                    <span className="bulk-failed-samples__action bulk-failed-samples__action--rejected">rejected</span>
                )}
                {status === 'pending' && (
                    <>
                        <button
                            className="bulk-failed-samples__action btn btn-outline-danger btn-sm"
                            onClick={rejectSample}
                        >
                            <DeleteIcon className="icon icon--small" /> reject
                        </button>
                        <button className="bulk-failed-samples__action btn btn-success btn-sm" onClick={approveSample}>
                            <AddIcon className="icon icon--small" /> approve
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};
