export const toggleArrayItem = (array: any[] = [], item: any) =>
    array.includes(item) ? array.filter((i: any) => i !== item) : [...array, item];

export const addArrayItem = (array: any[] = [], item: any) => (array.includes(item) ? [...array] : [...array, item]);

export const removeArrayItem = (array: any[] = [], item: any) =>
    array.includes(item) ? array.filter((i: any) => i !== item) : [...array];

export const modifyObjectInArrayById = ({array, id, delta}: {
    array: any[],
    delta: any,
    id: string | number,
}) => {
    return array.map((item: any) => {
        if (item.id === id) {
            return {
                ...item,
                ...delta
            };
        }
        return item;
    });
};