import React from 'react';
import { PlateMap } from '../../types';
import { PlateItem } from '../PlateItem/PlateItem';
import { DateWrapper } from '../Wrapper/DateWrapper';
import { InputWrapper } from '../Wrapper/InputWrapper';

import './ReagentEntry.scss';

interface ReagentEntryProps {
    update: (delta: { [key: string]: string }) => void;
    id: string;
    label: string;
    plateMap: PlateMap;
    variant?: 'low' | 'medium' | 'high' | 'blank' | 'calibrator' | 'filled' | 'none';
    variantLabel?: string;
}

export const ReagentEntry: React.FC<ReagentEntryProps> = ({
    label,
    id,
    update,
    plateMap,
    variant = 'none',
    variantLabel,
}) => {
    return (
        <div className="reagent-entry">
            <div>
                {variant !== 'none' && <PlateItem variant={variant} />}
                {variant === 'none' && variantLabel}
            </div>
            <InputWrapper
                changeHandler={update}
                id={`${id}No`}
                isDisabled={plateMap.readonly}
                label={label}
                value={plateMap[id + 'No']}
            />
            <DateWrapper
                blurHandler={update}
                id={`${id}ExpiringDate`}
                isDisabled={plateMap.readonly}
                label="Expiry Date"
                value={plateMap[id + 'ExpiringDate']}
            />
        </div>
    );
};
