import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { AddToPlate } from '../components/Overlays/AddToPlate/AddToPlate';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { PlateMapError } from '../components/Overlays/PlateMapError/PlateMapError';
import { PlateGrid } from '../components/PlateGrid/PlateGrid';
import { PrepsiaConsumables } from '../components/PlateMapEntries/PrepsiaConsumables';
import { PrepsiaReagents } from '../components/PlateMapEntries/PrepsiaReagents';

import { ReactComponent as LegendIcon } from '../resources/icons/info.svg';
import './PlateMap.scss';
import { PlateMapSuccess } from '../components/Overlays/PlateMapSuccess/PlateMapSuccess';
import { PlateMapNew } from '../components/Overlays/PlateMapNew/PlateMapNew';
import { PlateMapLegend } from '../components/Overlays/PlateMapLegend/PlateMapLegend';
import { ScrollTop } from '../helper/ScrollTop';
import { PlateMapsContext } from '../contexts/PlateMapsContext';
import {
    autoPopulatePlateMapById,
    clearPlateMapById,
    exportRunFileById,
    manualPopulatePlateMapByIdWithSamplesById,
    registerPlateMapById,
} from '../api/plateMaps';
import { downloadFile } from '../helper/downloadHelper';
import { isValidIntegerId } from '../helper/isValidId';
import { PlateMap as PlateMapI } from '../types';

interface PlateMapProps {
    isNew?: boolean;
}

export const PlateMap: React.FC<PlateMapProps> = ({ isNew = false }) => {
    const navigate = useNavigate();
    const { plateMapId } = useParams();

    const { createNewPlateMap, loadPlateMapById, plateMaps } = useContext(PlateMapsContext);

    const [isPrepsiaLegendShown, setIsPrepsiaLegendShown] = useState(false);
    const [isSuccessShown, setIsSuccessShown] = useState(false);
    const [isManualOverlayShown, setIsManualOverlayShown] = useState(false);
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [failedSamples, setFailedSamples] = useState([]);

    const [isForceGridUpdate, setIsForceGridUpdate] = useState(false);

    useEffect(() => {
        if (isValidIntegerId(plateMapId)) {
            loadPlateMapById(Number(plateMapId));
        }
    }, [loadPlateMapById, plateMapId]);

    const autoPopulate = async () => {
        await autoPopulatePlateMapById(Number(plateMapId));
        setIsForceGridUpdate(true);
    };

    const manualPopulate = () => {
        setIsManualOverlayShown(true);
    };

    const resetForceUpdate = useCallback(() => setIsForceGridUpdate(false), []);

    const clear = async () => {
        await clearPlateMapById(Number(plateMapId));
        setIsForceGridUpdate(true);
    };

    const addToPlate = async (samplesIds: number[]) => {
        if (samplesIds && samplesIds.length > 0) {
            await manualPopulatePlateMapByIdWithSamplesById({ plateMapId: Number(plateMapId), samplesIds });

            setIsManualOverlayShown(false);
            setIsForceGridUpdate(true);
        }
    };

    const removeFromPlate = () => {
        console.log('removeFromPlate');
        // TODO
    };

    const exportRunFile = async () => {
        const runFileData: string = await exportRunFileById(Number(plateMapId));

        downloadFile({ content: runFileData, fileName: `Prepsia_Test_Run_${plateMapId}.csv`, inputType: 'string' });
    };

    const registerPlate = async () => {
        const registerResult = await registerPlateMapById(Number(plateMapId));

        if (registerResult.removedEntries.length > 0) {
            // TODO - handle errors
        } else {
            setIsSuccessShown(true);
            exportRunFile();
            navigate(`/home/`);
        }
    };

    const createNewTest = async () => {
        const plateMap = await createNewPlateMap();

        navigate(`/plateMap/${plateMap.id}`);
    };

    const plateMap: PlateMapI | undefined = plateMaps.find((plateMap) => plateMap.id === Number(plateMapId));
    const plateMapReadonly = plateMap && plateMap.readonly;

    return (
        <div className="plate-map-page">
            <Header>
                <>Plate Map for Run: {plateMapId}</>
            </Header>
            <Container className="mt-5 mb-5">
                <h4 className="mb-4">Plate Map</h4>
                <p>
                    Automatically populate plate map from <strong>pending</strong> patient sample register.
                </p>

                <div className="button-row">
                    <Button disabled={plateMapReadonly} onClick={autoPopulate}>
                        Auto Populate
                    </Button>
                    <Button disabled={plateMapReadonly} variant="outline-secondary" onClick={manualPopulate}>
                        Manual Populate
                    </Button>
                    <Button disabled={plateMapReadonly} variant="outline-danger" onClick={clear}>
                        Clear
                    </Button>
                </div>

                <Tabs className="mb-3" defaultActiveKey="prepsia" id="plates">
                    <Tab eventKey="prepsia" title="PrePsia Setup">
                        <div className="button-row">
                            <LegendIcon className="legend" onClick={() => setIsPrepsiaLegendShown(true)} />
                        </div>
                        <PlateGrid afterUpdateCallback={resetForceUpdate} isForceGridUpdate={isForceGridUpdate} />
                        <div className="divider mt-5 mb-5"></div>
                        <PrepsiaReagents />
                        <div className="divider mt-5 mb-5" />
                        <PrepsiaConsumables />
                    </Tab>
                </Tabs>

                <div className="button-row mt-5">
                    <Button disabled={plateMapReadonly} variant="primary" onClick={registerPlate}>
                        Register Plate
                    </Button>
                </div>
            </Container>
            <Footer />

            <PlateMapLegend dismissHandler={() => setIsPrepsiaLegendShown(false)} isShown={isPrepsiaLegendShown} />

            <AddToPlate
                addToPlateMap={addToPlate}
                dismissHandler={() => setIsManualOverlayShown(false)}
                isShown={isManualOverlayShown}
            />

            <PlateMapSuccess dismissHandler={() => setIsSuccessShown(false)} isShown={isSuccessShown} />

            <PlateMapNew createNewTest={createNewTest} isShown={isNew} />

            <PlateMapError
                deleteHandler={removeFromPlate}
                dismissHandler={() => {
                    setIsErrorShown(false);
                }}
                failedSamples={failedSamples}
                isErrorShown={isErrorShown}
            />

            <ScrollTop />
        </div>
    );
};
