import React, { forwardRef, useContext, useEffect, useState } from 'react';

import { ReactComponent as AddIcon } from '../../resources/icons/add.svg';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import { SampleEdit } from '../Overlays/SampleEdit/SampleEdit';
import { SampleError } from '../Overlays/SampleError/SampleError';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { SampleTable } from '../SampleTable/SampleTable';
import { SampleCreate } from '../Overlays/SampleCreate/SampleCreate';
import { BloodSampleBundlesContext } from '../../contexts/BloodSampleBundlesContext';
import { useNavigate, useParams } from 'react-router';
import { postEvent } from '../../api/postEvent';
import { POST_EVENT, PREGNANCY_STATE } from '../../app/constants';
import { SampleSuccess } from '../Overlays/SampleSuccess/SampleSuccess';
import { useStateEventErrors } from '../../hooks/useStateEventErrors';

interface SampleRegisterProps {
    forceOverlay?: boolean;
}

export const SampleRegister = forwardRef<HTMLDivElement, SampleRegisterProps>(({ forceOverlay }, ref) => {
    const { patientId, bloodSampleBundleId } = useParams();
    const navigate = useNavigate();

    const [isEditOverlayShown, setIsEditOverlayShown] = useState(false);
    const [isCreateOverlayShown, setIsCreateOverlayShown] = useState(false);
    const [isSampleErrorShown, setIsSampleErrorShown] = useState(false);
    const [isSampleSuccessShown, setIsSampleSuccessShown] = useState(false);
    const [sampleErrors, setSampleErrors] = useState([]);

    const { pregnancy, loadPregnancyForPatient } = useContext(PregnancyContext);
    const { createNewBloodMeasuementForPregnancy } = useContext(BloodSampleBundlesContext);

    useStateEventErrors(sampleErrors);

    const registerSample = async () => {
        const errors = await postEvent({
            postEvent: POST_EVENT.REGISTER_SAMPLE,
            pregnancyId: pregnancy.id,
        });

        if (errors.length > 0) {
            setIsSampleErrorShown(true);
            setSampleErrors(errors);
        } else {
            setIsSampleSuccessShown(true);
            navigate(`/patientRecord/${patientId}/`);
            // reload pregnancy to get the updated pregnancy state
            loadPregnancyForPatient(Number(patientId));
        }
    };

    const createSample = async () => {
        try {
            const bloodSampleBundle = await createNewBloodMeasuementForPregnancy({
                pregnancyId: pregnancy.id,
            });
            setIsCreateOverlayShown(false);
            navigate(`/patientRecord/${patientId}/${bloodSampleBundle.id}`);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (bloodSampleBundleId) {
            setIsEditOverlayShown(true);
        } else {
            setIsEditOverlayShown(false);
        }
    }, [bloodSampleBundleId]);

    useEffect(() => {
        if (pregnancy.pregnancyState === PREGNANCY_STATE.DRAFT_SAMPLE) {
            setIsCreateOverlayShown(forceOverlay);
        }
    }, [forceOverlay, pregnancy.pregnancyState]);

    return (
        <div className="sample-register" ref={ref}>
            <SectionHeader title="Sample Register">
                {pregnancy.pregnancyState === PREGNANCY_STATE.DRAFT_SAMPLE && (
                    <AddIcon className="icon-as-btn" onClick={() => setIsCreateOverlayShown(true)} />
                )}
            </SectionHeader>

            <SampleTable registerSample={registerSample} />

            <SampleCreate
                confirmHandler={createSample}
                dismissHandler={() => setIsCreateOverlayShown(false)}
                isShown={isCreateOverlayShown}
            />
            <SampleEdit
                dismissHandler={() => navigate(`/patientRecord/${patientId}`)}
                isShown={isEditOverlayShown}
                submitSample={registerSample}
            />
            <SampleError
                dismissHandler={() => setIsSampleErrorShown(false)}
                isSampleErrorShown={isSampleErrorShown}
                sampleErrors={sampleErrors}
            />
            <SampleSuccess dismissHandler={() => setIsSampleSuccessShown(false)} isShown={isSampleSuccessShown} />
        </div>
    );
});
