import { endpoints } from "../app/config";
import HttpService from "../helper/HttpService";

export const getDiabetesDisease = async (id: number) => {
    const axios = HttpService.getAxiosClient();
    const result = await axios.get(`${endpoints.data.patients}/${id}/diabetesMellitus`);

    return result.data;
};

export const updateDiabetesDiseaseForPatientId = async (delta: { [key: string]: any }, id: number) => {
    const axios = HttpService.getAxiosClient();
    return await axios.patch(`${endpoints.data.diabetesdiseases}/${id}`, {
        ...delta
    });
}