import countryData from '../resources/countries.json';
import { BioInput } from '../types';

// ENDPOINTS
const API_DATA_BASE = process.env.REACT_APP_API_DATA_BASE;
const API_PATIENT_BASE = process.env.REACT_APP_API_PATIENT_BASE;
const API_PLATEMAP_BASE = process.env.REACT_APP_API_PLATEMAP_BASE;
const API_RESULT_BASE = process.env.REACT_APP_API_RESULT_BASE;
const SEARCH_BASE = API_DATA_BASE + '/patients/search';

export const endpoints = {
    data: {
        _debug: API_DATA_BASE,
        autoimmunediseases: API_DATA_BASE + '/autoimmunediseases',
        bloodmaps: API_DATA_BASE + '/bloodmaps',
        bloodsamplebundles: API_DATA_BASE + '/bloodsamplebundles',
        chronickidneydiseases: API_DATA_BASE + '/chronickidneydiseases',
        diabetesdiseases: API_DATA_BASE + '/diabetesdiseases',
        edtasamples: API_DATA_BASE + '/edtasamples',
        hypertensiondisease: API_DATA_BASE + '/hypertensiondisease',
        outcomes: API_DATA_BASE + '/outcomes',
        patientList: API_DATA_BASE + '/patients?projection=patientList',
        patients: API_DATA_BASE + '/patients',
        platemaps: API_DATA_BASE + '/platemaps',
        pregnancies: API_DATA_BASE + '/pregnancies',
        residences: API_DATA_BASE + '/residences',
        resultValuations: API_DATA_BASE + '/resultvaluations',
        serumsamples: API_DATA_BASE + '/serumsamples',
        smoker: API_DATA_BASE + '/smoker',
        smokerdiseases: API_DATA_BASE + '/smokerdiseases',
        thrombophiliadiseases: API_DATA_BASE + '/thrombophiliadiseases',
    },
    patient: {
        bloodsamplebundleForPigf: API_PATIENT_BASE + '/bloodsamplebundleForPigf',
        gestationalAge: API_PATIENT_BASE + '/gestationalAge',
        postEvent: API_PATIENT_BASE + '/postEvent',
    },
    platemap: {
        autopopulate: API_PLATEMAP_BASE + '/autopopulate',
        clear: API_PLATEMAP_BASE + '/clear',
        csvexport: API_PLATEMAP_BASE + '/csvexport',
        manualPopulate: API_PLATEMAP_BASE + '/manualPopulate',
        register: API_PLATEMAP_BASE + '/register',
        samplecandidates: API_PLATEMAP_BASE + '/samplecandidates',
    },
    result: {
        importResults: API_RESULT_BASE + '/importResults',
    },
    search: {
        findAllWithDate: () => SEARCH_BASE + '/findAllPatientsWithDate?projection=patientList',
        findAllWithType: (type: string) => SEARCH_BASE + `/findAll${type}Patients?projection=patientList`,
        findAllWithTypeAndDate: (type: string) =>
            SEARCH_BASE + `/findAll${type}PatientsWithDate?projection=patientList`,
        findAllWithTypeAndDateAndName: (type: string) =>
            SEARCH_BASE + `/findAll${type}PatientsWithDateAndName?projection=patientList`,
    },
};

/* eslint-disable sort-keys */

// PATIENT DATA
// ==============

export const COUNTRIES: any = {};
const COUNTRIES_AND_STATES: any = {};

Object.keys(countryData).forEach((country) => {
    const key: string = country.replace(/ |,/g, '_').toUpperCase();
    COUNTRIES[key] = country;

    COUNTRIES_AND_STATES[key] = {};
    // @ts-ignore // TODO: fix this type error
    countryData[country].forEach((state: string) => {
        const stateKey = state.replace(/ |,/g, '_').toUpperCase();
        COUNTRIES_AND_STATES[key][stateKey] = state;
    });
});
export const COUNTRIES_DATA = COUNTRIES_AND_STATES;

export const ETHNICITIES = {
    HISPANIC_LATINA: 'Hispanic or Latina',
    NON_HISPANIC_LATINA: 'Not Hispanic or Latina',
    NOT_AVAILABLE: 'Not Stated/Not Available',
};
export type Ethnicities = keyof typeof ETHNICITIES;

export const RACES_OTHER = {
    WHITE: 'White',
    BLACK: 'Black',
    SOUTH_ASIAN: 'South Asian',
    EAST_ASIAN: 'East Asian',
    MIXED: 'Mixed',
};
export type RacesOther = keyof typeof RACES_OTHER;

export const RACES_US = {
    WHITE: 'White',
    INDIAN: 'American Indian or Alaska Native',
    ASIAN: 'Asian',
    BLACK: 'Black or African American',
    HAWAIIAN: 'Native Hawaiian or Other Pacific Islander',
    MORE_THAN_ONE: 'More than one race',
    UNKNOWN: 'Unknown or Not Stated',
};
export type RacesUS = keyof typeof RACES_US;

// PREGNANCY DATA
// ==============
export const CONCEPTION_METHODS = {
    SPONTANEOUS: 'Spontaneous',
    IVF: 'IVF',
    OOCYTE_DONATION: 'Oocyte donation',
    ICSI: 'ICSI',
};
export type ConceptionMethods = keyof typeof CONCEPTION_METHODS;

export const MULTIFETAL_GESTATION = {
    NO: 'No - Singleton',
    YES: 'Yes - Twins (2 or or more)',
};
export type MultifetalGestation = keyof typeof MULTIFETAL_GESTATION;

// HEALTH DATA
// ==============

export const YESNOUNKNOWN = {
    YES: 'Yes',
    NO: 'No',
    UNKNOWN: 'Unknown',
};
export type YesNoUnkown = keyof typeof YESNOUNKNOWN;

export const THROMBOPHILIA = {
    NONE: 'None',
    UNKNOWN: 'Unknown',
    YES_FACTOR_V: 'Yes - Factor V Leiden',
    YES_PROTEIN_C: 'Yes - Protein C deficiency ',
    YES_PROTEIN_S: 'Yes - Protein S deficiency',
    YES_ANTITHROMBIN: 'Yes - Antithrombin deficiency',
    YES_OTHER: 'Yes - Other',
};
export type Thrombophilia = keyof typeof THROMBOPHILIA;

export const HYPERTENSION = {
    NO: 'No',
    UNKNOWN: 'Unknown',
    YES_CHRONIC: 'Yes - Chronic Hypertension (pre-pregnancy)',
    YES_GESTATIONAL: 'Yes - Gestational Hypertension (current pregnancy)',
};
export type Hypertension = keyof typeof HYPERTENSION;

export const AUTOIMMUNE = {
    NO: 'No',
    UNKNOWN: 'Unknown',
    YES_ANTIPHOSPHOLIPID: 'Yes - Antiphospholipid syndrome (Hughes syndrome)',
    YES_SYSTEMIC: 'Yes - Systemic Lupus erythematous',
    YES_OTHER: 'Yes - Other',
};
export type Autoimmune = keyof typeof AUTOIMMUNE;

export const INTERPREGNANCY_INTERVAL = {
    YEAR_1: '1y',
    YEAR_2: '2y',
    YEAR_3: '3y',
    YEAR_4: '4y',
    YEAR_5: '5y',
    YEAR_6: '6y',
    YEAR_7: '7y',
    YEAR_8_PLUS: '8y+',
};
export type InterpregnancyInterval = keyof typeof INTERPREGNANCY_INTERVAL;

export const NUMBER_PRIOR_BIRTHS = {
    BIRTH_0: '0',
    BIRTH_1: '1',
    BIRTH_2: '2',
    BIRTH_3: '3',
    BIRTH_4: '4',
    BIRTH_5_PLUS: '5+',
};
export type NumberPriorBirths = keyof typeof NUMBER_PRIOR_BIRTHS;

export const NUMBER_PRIOR_GESTATIONS = {
    GESTATION_0: '0',
    GESTATION_1: '1',
    GESTATION_2: '2',
    GESTATION_3: '3',
    GESTATION_4: '4',
    GESTATION_5_PLUS: '5+',
};
export type NumberPriorGestations = keyof typeof NUMBER_PRIOR_GESTATIONS;

export const NUMBER_PRIOR_MISCARRIAGES = {
    MISCARRIAGE_0: '0',
    MISCARRIAGE_1: '1',
    MISCARRIAGE_2: '2',
    MISCARRIAGE_3: '3',
    MISCARRIAGE_4: '4',
    MISCARRIAGE_5_PLUS: '5+',
};
export type NumberPriorMiscarriages = keyof typeof NUMBER_PRIOR_MISCARRIAGES;

export const PRIOR_TREATMENT = {
    NONE: 'None',
    ASPIRIN: 'Aspirin',
    CLOPIDOGREL: 'Clopidogrel',
    LMWHEPARIN:
        'LMWHeparin (Lovenox generic name, enoxaparin manufactured by Sanofi-Aventis. Fragmin generic name, dalteparin manufactured by Pfizer - USA)',
    HEPARIN: 'Heparin',
};
export type PriorTreatment = keyof typeof PRIOR_TREATMENT;

export const PRIOR_FETAL_OUTCOME_DETAILS = {
    SMALL: 'Small for Gestational Age',
    APRUPTIO_PLACENTA: 'Abruptio placenta',
    STILLBIRTH: 'Stillbirth',
    PREMATURITY_IATROGENIC: 'Prematurity - iatrogenic',
    PREMATURITY_NON_IATROGENIC: 'Prematurity - non-iatrogenic',
    DISTRESS_SONOGRAPHIC: 'Prior acute fetal distress - Sonographic alterations',
    DISTRESS_CARDIOTOCOGRAPHY: 'Prior acute fetal distress - Cardiotocography alterations',
    OLIGOHYDAMNIOS: 'Oligohydramnios',
    ICU: 'Baby in the ICU',
    MULTIPLE_LOSSES: 'Multiple prior gestational losses',
};
export type PriorFetalOutcomeDetailsStrings = keyof typeof PRIOR_FETAL_OUTCOME_DETAILS;
export type PriorFetalOutcomeDetails = PriorFetalOutcomeDetailsStrings[];

export const PRIO_ORGAN_LESION_DETAILS = {
    VISUAL_ALTERATIONS: 'Visual Alterations',
    SEIZURES: 'Seizures',
    HEPATIC_ISSUES: 'Hepatic issues',
    RENAL_COMPLICATIONS: 'Renal complications',
    HAEMATOLOGICAL_ISSUES: 'Haematological issues',
    STEROID_SHOT: 'Steroid shot',
};
export type PrioOrganLesionDetailsStrings = keyof typeof PRIO_ORGAN_LESION_DETAILS;
export type PrioOrganLesionDetails = PrioOrganLesionDetailsStrings[];

export const DIABETES_MEILLITUS = {
    NONE: 'None',
    UNKNOWN: 'Unknown',
    TYPE_1: 'Chronic Diabetes - Type 1',
    TYPE_2: 'Chronic Diabetes - Type 2',
    GESTATIONAL_PREVIOUS: 'Gestational Diabetes (previous pregnancies)'
}
export type DiabetesMeillitus = keyof typeof DIABETES_MEILLITUS

// PATIENT DATA
// ============
export const TESTSTATUS = {
    Draft: 'Draft',
    Pending: 'Pending',
    WaitForPrepsiaAssignment: 'In Process',
    WaitForResults: 'Result Upload',
    ToReport: 'To Report',
    ToRepeat: 'To Repeat',
    Outcome: 'Outcome',
    Archived: 'Archived',
};
export type TestStatus = keyof typeof TESTSTATUS;

// PATIENT RECORD
// ==============
export const OTHER_BLOOD_THINNER = {
    CLOPIDOGREL: 'Clopidogrel',
    WARFARIN: 'Warfarin',
    LMWHEPARIN: 'LMWHeparin',
    HEPARIN: 'Heparin',
};
export type OtherBloodThinner = keyof typeof OTHER_BLOOD_THINNER;

export const SEVERITY = {
    todo: 'TODO',
};

export const PREMATURITY = {
    NONE: 'None',
    BEFORE_28: 'Delivery before 28 weeks',
    BEFORE_34: 'Delivery before 34 weeks',
    BEFORE_37: 'Delivery before 37 weeks',
    BEFORE_40: 'Delivery before 40 weeks',
};
export type Prematurity = keyof typeof PREMATURITY;

export const REJECTION_REASON = {
    RECEIVED_AT_ROOM_TEMP: 'Recieved at room temp',
    SAMPLE_LEAKAGE: 'Sample leakage',
    INSUFFICIENT_VOLUME: 'Insufficient volume',
    LIMITED_PATIENT_INPUT: 'Limited patient input',
    INCORRECT_TUBE_TYPE: 'Incorrect tube type',
    MISC: 'Misc (describe in notes)',
};

export type RejectionReason = keyof typeof REJECTION_REASON;

export const SAMPLE_CONDITION = {
    GOOD: 'Good',
    SUFFICIENT: 'Sufficient',
    POOR_CLOTTED: 'Poor - clotted',
    POOR_INSUFFICIENT_VOLUME: 'Poor - insufficient volume',
};
export type SampleCondition = keyof typeof SAMPLE_CONDITION;

// RISK RESULTS
export const BIOPYHSICAL: BioInput[] = [
    {
        id: 'map',
        name: 'Mean Arterial Pressure (MAP)',
        unit: 'mmHg',
        min: 60,
        max: 110,
    },
    {
        id: 'utpil',
        name: 'Mean Uterine Artery (UTPI) (left)',
        min: 0.8,
        max: 1.5,
    },
    {
        id: 'utpir',
        name: 'Mean Uterine Artery (UTPI) (right)',
        min: 0.8,
        max: 1.5,
    },
];
export const BIOCHEMICAL: BioInput[] = [
    {
        name: 'Placental Growth Factor (PlGF)',
        min: 10, // TODO
        max: 30, // TODO
    },
    {
        id: '003',
        name: '2-hydroxybutyric acid',
        min: 705.69,
        max: 11886.54111,
    },
    {
        id: '010',
        name: 'Alanine',
        min: 4726.65,
        max: 79614.55577,
    },
    {
        id: '012',
        name: 'Arginine',
        min: 1035.3,
        max: 17438.32984,
    },
    {
        id: '017',
        name: 'Citrulline',
        min: 991.21,
        max: 16695.63554,
    },
    {
        id: '026',
        name: 'Homo-arginine',
        min: 90.43,
        max: 1523.188994,
    },
    {
        id: '028',
        name: 'Isoleucine',
        min: 1519.77,
        max: 25598.61859,
    },
    {
        id: '038',
        name: 'Asymmetric dimethylarginine',
        min: 8.0,
        max: 134.8269239,
    },
    {
        id: '041',
        name: 'Symmetric dimethylarginine',
        min: 14.89,
        max: 250.7479935,
    },
    {
        id: '047',
        name: 'Urea',
        min: 12400.83,
        max: 208876.4592,
    },
    {
        id: '062',
        name: 'Cotinine ',
        min: 45.0,
        max: 757.8947368,
    },
    {
        id: '073',
        name: 'Acetylcarnitine',
        min: 147.9935321,
        max: 2492.766057,
    },
    {
        id: '078',
        name: 'Glutamine',
        min: 7555.76,
        max: 127267.3372,
    },
    {
        id: '087',
        name: 'Threonine',
        min: 5624.450737,
        max: 94736.84211,
    },
    {
        id: '088',
        name: 'Lactic acid',
        min: 37496.33825,
        max: 631578.9474,
    },
    {
        id: '093',
        name: 'Ornithine',
        min: 1873.95,
        max: 31564.42464,
    },
    {
        id: '103',
        name: '2-Hydroxy-(2/3)-methylbutyric acid',
        min: 194.33,
        max: 3273.228989,
    },
    {
        id: '114',
        name: 'Octanoylcarnitine',
        min: 1.874816912,
        max: 31.57894737,
    },
    {
        id: '011',
        name: 'Arachidonic acid',
        min: 934.6491821,
        max: 15742.99716,
    },
    {
        id: '015',
        name: '8,11,14 Eicosatrienoic acid',
        min: 188.5746458,
        max: 3176.30419,
    },
    {
        id: '018',
        name: 'Decanoylcarnitine',
        min: 5.900018558,
        max: 99.37843759,
    },
    {
        id: '019',
        name: 'Dodecanoylcarnitine',
        min: 6.00504924,
        max: 101.1475481,
    },
    {
        id: '020',
        name: 'Docosahexaenoic acid',
        min: 246.6891537,
        max: 4155.170432,
    },
    {
        id: '063',
        name: 'Dilinoleoyl glycerol ',
        min: 92.54440702,
        max: 1558.794856,
    },
    {
        id: '027',
        name: 'Palmitic acid',
        min: 6444.999606,
        max: 108557.9621,
    },
    {
        id: '029',
        name: 'Linoleic acid',
        min: 6919.017792,
        max: 116542.2059,
    },
    {
        id: '034',
        name: 'Oleic acid',
        min: 8825.779155,
        max: 148659.2176,
    },
    {
        id: '037',
        name: 'Palmitoylcarnitine',
        min: 8.987073799,
        max: 151.3760243,
    },
    {
        id: '059',
        name: 'Bilirubin',
        min: 347.5910556,
        max: 5854.736842,
    },
    {
        id: '064',
        name: 'Myristic acid ',
        min: 252.6618975,
        max: 4255.773836,
    },
    {
        id: '071',
        name: '1-Palmitoyl-2-hydroxy-sn-glycero-3-phosphocholine ',
        min: 12413.54951,
        max: 209090.7245,
    },
    {
        id: '132',
        name: '1-Heptadecanoyl-2-hydroxy-sn-glycero-3-phosphocholine',
        min: 258.1270205,
        max: 4347.827001,
    },
    {
        id: '133',
        name: '1-Oleoyl-2-hydroxy-sn-glycero-3-phosphocholine',
        min: 2020.922251,
        max: 34039.90916,
    },
    {
        id: '149',
        name: '1-(1Z-Octadecenyl)-2-oleoyl-sn-glycero-3-phosphocholine',
        min: 63.74377502,
        max: 1073.684211,
    },
];

// OTHER
// ============

// export const PER_PAGE = 50;
// TODO remove
export const PATIENTS_PER_PAGE = 5;

export const MAX_VISIBLE_PLATEMAPS = 30;
export const PLATEMAPS_PER_PAGE = 3;
