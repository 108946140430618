import { unionBy } from "lodash";
import { useContext, useEffect } from "react";
import { OBJECTS } from "../app/constants";
import { BloodMapsContext } from "../contexts/BloodMapsContext";
import { BloodSampleBundlesContext } from "../contexts/BloodSampleBundlesContext";
import { EdtaSamplesContext } from "../contexts/EdtaSamplesContext";
import { PatientContext } from "../contexts/PatientContext";
import { PregnancyContext } from "../contexts/PregnancyContext";
import { ResidenceContext } from "../contexts/ResidenceContext";
import { SerumSamplesContext } from "../contexts/SerumSamplesContext";
import { ValidationError } from "../types";

export function useStateEventErrors(stateEventErrors: ValidationError[]) {
    const { setPatientErrors } = useContext(PatientContext);
    const { setPregnancyErrors } = useContext(PregnancyContext);
    const { setBloodSampleBundleErrors } = useContext(BloodSampleBundlesContext);
    const { setBloodMapsErrors } = useContext(BloodMapsContext);
    const { setEdtaErrors } = useContext(EdtaSamplesContext);
    const { setSerumSamplesErrors } = useContext(SerumSamplesContext);
    const { setResidenceErrors } = useContext(ResidenceContext);

    useEffect(() => {
        const newPatientErrors = stateEventErrors.filter(error => error.objectName && error.objectName === OBJECTS.PATIENT);
        const newPregnancyErrors = stateEventErrors.filter(error => error.objectName && error.objectName === OBJECTS.PREGNANCY);
        const newBloodSampleBundleErrors = stateEventErrors.filter(error => error.objectName && error.objectName === OBJECTS.BLOOD_SAMPLE_BUNDLE);
        const newBloodMapsErrors = stateEventErrors.filter(error => error.objectName && error.objectName === OBJECTS.BLOOD_MAP);
        const newEdtaErrors = stateEventErrors.filter(error => error.objectName && error.objectName === OBJECTS.EDTA_SAMPLE);
        const newSerumSamplesErrors = stateEventErrors.filter(error => error.objectName && error.objectName === OBJECTS.SERUM_SAMPLE);
        const newResidenceErrors = stateEventErrors.filter(error => error.objectName && error.objectName === OBJECTS.RESIDENCE);

        setPatientErrors(errors => unionBy(errors, newPatientErrors, 'field'));
        setPregnancyErrors(errors => unionBy(errors, newPregnancyErrors, 'field'));
        setBloodSampleBundleErrors(errors => unionBy(errors, newBloodSampleBundleErrors, 'field'));
        setBloodMapsErrors(errors => unionBy(errors, newBloodMapsErrors, 'field'));
        setEdtaErrors(errors => unionBy(errors, newEdtaErrors, 'field'));
        setSerumSamplesErrors(errors => unionBy(errors, newSerumSamplesErrors, 'field'));
        setResidenceErrors(errors => unionBy(errors, newResidenceErrors, 'field'));

    }, [setPatientErrors, setPregnancyErrors, setBloodSampleBundleErrors, setBloodMapsErrors, setEdtaErrors, setSerumSamplesErrors, stateEventErrors, setResidenceErrors]);
}