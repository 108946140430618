import { createContext, useCallback, useState } from 'react';
import { getChronicKidneyDisease, updateChronicKidneyDiseaseForPatientId } from '../api/chronicKidneyDisease';
import { YesNoUnkown } from '../app/config';
import { debouncedSaveWithErrors } from '../helper/apiHelper';

interface ChronicKidneyDiseaseContextProps {
    clearChronicKidneyDisease: () => void;
    loadChronicKidneyDiseaseForPatient: (patientId: number) => void;
    chronicKidneyDisease: YesNoUnkown;
    updateChronicKidneyDisease: (delta: { status: YesNoUnkown }) => void;
}

export const ChronicKidneyDiseaseContext = createContext<ChronicKidneyDiseaseContextProps>(null);
ChronicKidneyDiseaseContext.displayName = 'ChronicKidneyDiseaseContext';

export const ChronicKidneyDiseaseContextProvider = (props: any) => {
    const [chronicKidneyDisease, setChronicKidneyDisease] = useState<YesNoUnkown>(null);
    const [chronicKidneyDiseaseId, setChronicKidneyDiseaseId] = useState<number>(null);

    const clearChronicKidneyDisease = useCallback(() => {
        setChronicKidneyDisease(null);
        setChronicKidneyDiseaseId(null);
    }, []);

    const updateChronicKidneyDisease = async (delta: { status: YesNoUnkown }) => {
        await debouncedSaveWithErrors({
            callApi: updateChronicKidneyDiseaseForPatientId,
            setErrors: () => {},
        })(delta, chronicKidneyDiseaseId);
        setChronicKidneyDisease(delta.status);
    };

    const loadChronicKidneyDiseaseForPatient = useCallback(async (patientId: number) => {
        const { id, status } = await getChronicKidneyDisease(patientId);
        setChronicKidneyDiseaseId(id);
        setChronicKidneyDisease(status);
    }, []);

    return (
        <ChronicKidneyDiseaseContext.Provider
            value={{
                chronicKidneyDisease,
                clearChronicKidneyDisease,
                loadChronicKidneyDiseaseForPatient,
                updateChronicKidneyDisease,
            }}
        >
            {props.children}
        </ChronicKidneyDiseaseContext.Provider>
    );
};
