import React from 'react';
import { PlateMapItemVariant } from '../../types';

import './PlateItem.scss';

interface PlateItemProps {
    variant?: PlateMapItemVariant;
    content?: string | number;
    title?: string | number;
}

export const PlateItem: React.FC<PlateItemProps> = ({ variant = 'blank', content = '', title = '' }) => {
    switch (variant) {
        case 'blank':
            content = 'B';
            break;
        case 'calibrator':
            content = `C${content}`;
            break;
    }

    return (
        <div className={`plate-item plate-item--${variant}`} title={String(title || content)}>
            <span>{content}</span>
        </div>
    );
};
