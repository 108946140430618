import clsx from 'clsx';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { SORTING } from '../../app/constants';
import { ReactComponent as TriangleIcon } from '../../resources/icons/triangle.svg';
import { TableEntry } from './TableEntry';
import './PatientTable.scss';
import { PatientListContext, PatientListEntry } from '../../contexts/PatientListContext';
import { orderBy } from 'lodash';

interface PatientTableProps {
    items: PatientListEntry[];
    isMinimal?: boolean;
}

export const PatientTable: React.FC<PatientTableProps> = ({ items, isMinimal = false }) => {
    // hooks
    const { updatePatientList, sortKey, setSortKey, sortOrder, setSortOrder } = useContext(PatientListContext);

    const changeSorting = (id: string) => {
        if (id === sortKey) {
            sortOrder === SORTING.ASC ? setSortOrder(SORTING.DESC) : setSortOrder(SORTING.ASC);
        } else {
            setSortOrder(SORTING.ASC);
        }

        setSortKey(id);
    };

    const toggleAllChecked = (isChecked: boolean) => {
        const updatedRecords = items.map((patientListEntry) => ({ ...patientListEntry, isChecked }));
        updatePatientList(updatedRecords);
    };

    const toggleChecked = (patientListEntry: PatientListEntry) => {
        updatePatientList([{ ...patientListEntry, isChecked: !patientListEntry.isChecked }]);
    };

    const sortedItems = orderBy(items, [sortKey, sortOrder]);

    const allChecked = sortedItems.filter((patientListEntry) => !patientListEntry.isChecked).length === 0;

    return (
        <div className={clsx('patient-table', { 'patient-table--is-minimal': isMinimal })}>
            <div className="table-header data-table__item">
                <Form.Check
                    checked={allChecked}
                    id="table-header-checkbox"
                    type="checkbox"
                    onChange={(e) => toggleAllChecked(e.currentTarget.checked)}
                />

                <TableHeaderItem
                    clickHandler={changeSorting}
                    id="id"
                    label="ID"
                    sortKey={sortKey}
                    sortOrder={sortOrder}
                />
                <TableHeaderItem
                    clickHandler={changeSorting}
                    id="surname"
                    label="Surname"
                    sortKey={sortKey}
                    sortOrder={sortOrder}
                />
                <TableHeaderItem
                    clickHandler={changeSorting}
                    id="forename"
                    label="Forename"
                    sortKey={sortKey}
                    sortOrder={sortOrder}
                />
                <TableHeaderItem
                    clickHandler={changeSorting}
                    id="birthDate"
                    label="DOB"
                    sortKey={sortKey}
                    sortOrder={sortOrder}
                />
                {!isMinimal && (
                    <>
                        <TableHeaderItem
                            clickHandler={changeSorting}
                            id="testRun"
                            label="PrePsia Run No."
                            sortKey={sortKey}
                            sortOrder={sortOrder}
                        />
                        <TableHeaderItem
                            clickHandler={changeSorting}
                            id="testStatus"
                            label="PrePsia Run Status"
                            sortKey={sortKey}
                            sortOrder={sortOrder}
                        />
                        <TableHeaderItem
                            className="justify-center"
                            clickHandler={changeSorting}
                            id="riskResult"
                            label="Risk Result"
                            sortKey={sortKey}
                            sortOrder={sortOrder}
                        />
                        <TableHeaderItem
                            className="justify-center"
                            clickHandler={changeSorting}
                            id="pigf"
                            label="PIGF"
                            sortKey={sortKey}
                            sortOrder={sortOrder}
                        />
                        <TableHeaderItem
                            className="justify-center"
                            clickHandler={changeSorting}
                            id="outcome"
                            label="Outcome"
                            sortKey={sortKey}
                            sortOrder={sortOrder}
                        />
                        <div></div>
                    </>
                )}
            </div>
            {sortedItems.map((patientListEntry) => (
                <TableEntry
                    key={patientListEntry.id}
                    {...patientListEntry}
                    handleCheckbox={() => toggleChecked(patientListEntry)}
                    isMinimal={isMinimal}
                />
            ))}
        </div>
    );
};

interface TableHeaderItemProps {
    sortKey: string;
    sortOrder: SORTING;
    id: string;
    label: string;
    className?: string;
    clickHandler: (id: string) => void;
}
const TableHeaderItem: React.FC<TableHeaderItemProps> = ({
    className,
    sortKey,
    sortOrder,
    label,
    id,
    clickHandler,
}) => {
    const classes = clsx('sort', className, {
        [sortOrder]: sortKey === id,
    });

    return (
        <div className={classes} onClick={() => clickHandler(id)}>
            {label} {sortKey === id && <TriangleIcon />}
        </div>
    );
};
