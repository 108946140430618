
interface DownloadFileProps {
    content?: Blob | string;
    fileName?: string;
    inputType?: 'blob' | 'string';
}

export const downloadFile = ({ content, fileName = 'download', inputType = 'string' }: DownloadFileProps) => {
    const fileBlob = inputType === 'blob' ? content as Blob : new Blob([content], { type: `text/plain` });

    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    
    link.href = url;
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};