import React from 'react';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';

interface SampleSuccessProps {
    dismissHandler: () => void;
    isShown: boolean;
}

export const SampleSuccess : React.FC<SampleSuccessProps> = ({
    dismissHandler, isShown
}) => {
    return (
        <ModalWrapper 
            dismissHandler={dismissHandler}
            header="Success"
            isShown={isShown}
        >
            Blood Samples successfully registered.
        </ModalWrapper>
    );
}
