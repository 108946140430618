import React, { useContext, useState } from 'react';
import { Form, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';

import { BiophysicalData } from '../components/BiophysicalData/BiophysicalData';
import { ClinicalHistoryRiskFactors } from '../components/ClinicalHistoryRiskFactors/ClinicalHistoryRiskFactors';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { PatientConsent } from '../components/PatientConsent/PatientConsent';
import { PatientDetails } from '../components/PatientDetails/PatientDetails';
import { PregnancyDetails } from '../components/PregnancyDetails/PregnancyDetails';
import { SubmitPatientDetails } from '../components/SubmitPatientDetails/SubmitPatientDetails';

import './PatientForm.scss';
import { PatientCreate } from '../components/Overlays/PatientCreate/PatientCreate';
import { PatientRegisterSuccess } from '../components/Overlays/PatientRegisterSuccess/PatientRegisterSuccess';
import { PatientRegisterError } from '../components/Overlays/PatientRegisterError/PatientRegisterError';
import { addNewPatient } from '../api/patient';
import { useLoadPatientForId } from '../hooks/useLoadPatientForId';
import { ScrollTop } from '../helper/ScrollTop';
import { postEvent } from '../api/postEvent';
import { PregnancyContext } from '../contexts/PregnancyContext';
import { POST_EVENT, PREGNANCY_STATE } from '../app/constants';
import { useStateEventErrors } from '../hooks/useStateEventErrors';
import { ValidationError } from '../types';

interface PatientFormProps {
    isNew?: boolean;
}

const PatientForm: React.FC<PatientFormProps> = ({ isNew = false }) => {
    const navigate = useNavigate();
    const { patientId } = useParams();
    const [extPatientId, setExtPatientId] = useState('');
    const [isSubmittedSuccess, setIsSubmittedSuccess] = useState(false);
    const [isSubmittedError, setIsSubmittedError] = useState(false);
    const [patientFormErrors, setPatientFormErrors] = useState<ValidationError[]>([]);
    const [createErrors, setCreateErrors] = useState<ValidationError[]>([]);
    const { pregnancy } = useContext(PregnancyContext);

    useLoadPatientForId(patientId);
    useStateEventErrors(patientFormErrors);

    const createNewPatient = async () => {
        try {
            const result = await addNewPatient(extPatientId);
            navigate(`/patientForm/${result.id}`);
        } catch (e: any) {
            if (e.response.status === 409) {
                setCreateErrors([
                    {
                        code: 'alreadyExists',
                        field: 'extPatientId',
                    },
                ]);
            }
        }
    };

    const submitForm = async () => {
        const errors = await postEvent({ postEvent: POST_EVENT.SUBMIT_PATIENT_FORM, pregnancyId: pregnancy.id });
        if (errors.length > 0) {
            setPatientFormErrors(errors);
            setIsSubmittedError(true);
        } else {
            setIsSubmittedSuccess(true);
        }
    };

    return (
        <div className="patient-form-page">
            <Header>
                <>Patient ID: {patientId}</>
            </Header>
            <Container className="my-4">
                <Form>
                    <PatientDetails />
                    <PregnancyDetails />
                    <ClinicalHistoryRiskFactors />
                    <BiophysicalData />
                    <PatientConsent />
                    {pregnancy.pregnancyState === PREGNANCY_STATE.DRAFT_PATIENT && (
                        <SubmitPatientDetails submitHandler={submitForm} />
                    )}
                </Form>
            </Container>
            <Footer />

            <PatientCreate
                createNewPatient={createNewPatient}
                errors={createErrors}
                extPatientId={extPatientId}
                isShown={isNew}
                updateExtPatientId={(delta) => setExtPatientId(delta.extPatientId)}
            />

            <PatientRegisterSuccess dismissHandler={() => setIsSubmittedSuccess(false)} isShown={isSubmittedSuccess} />

            <PatientRegisterError
                dismissHandler={() => setIsSubmittedError(false)}
                isShown={isSubmittedError}
                patientFormErrors={patientFormErrors}
            />

            <ScrollTop />
        </div>
    );
};

export default PatientForm;
