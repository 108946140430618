import { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
    createEdtaSampleForBloodSampleBundle,
    CreateEdtaSampleParams,
    deleteEdtaSampleById,
    getEdtaSampleByBloodSampleBundleId as getEdtaSampleByMeasurementId,
    updateEdtaSampleById,
} from '../api/edtaSample';
import { debouncedSaveWithErrors } from '../helper/apiHelper';
import { modifyObjectInArrayById } from '../helper/arrayHelper';
import { EdtaSample, EdtaSampleWithMeasurementId, ValidationError } from '../types';

interface EdtaSamplesContextProps {
    clearEdtaSamples: () => void;
    createNewEdtaSampleForBloodSampleBundle: ({
        bloodSampleBundleId,
        data,
    }: CreateEdtaSampleParams) => Promise<EdtaSample>;
    deleteEdtaSampleByBloodSampleBundleId: (bloodSampleBundleId: number) => void;
    edtaSamples: EdtaSampleWithMeasurementId[];
    edtaSamplesErrors: ValidationError[];
    getEdtaSampleByBloodSampleBundleId: (bloodSampleBundleId: number) => EdtaSampleWithMeasurementId;
    loadEdtaSamplesForbloodSampleBundlesIds: (bloodSampleBundlesIds: number[]) => void;
    setEdtaErrors: Dispatch<SetStateAction<ValidationError[]>>;
    updateEdtaSampleAttributeForEdtaSampleId: (edtaSampleId: number, delta: { [key: string]: any }) => void;
}

const edtaSamplesDefaults: EdtaSampleWithMeasurementId[] = [];

export const EdtaSamplesContext = createContext<EdtaSamplesContextProps>(null);
EdtaSamplesContext.displayName = 'EdtaSamplesContext';

export const EdtaSamplesContextProvider = (props: any) => {
    const [edtaSamples, setEdtaSamples] = useState(edtaSamplesDefaults);
    const [edtaSamplesErrors, setEdtaErrors] = useState<ValidationError[]>([]);
    const [debounceFunctions, setDebounceFunctions] = useState<{ [key: string]: any }>({});

    const clearEdtaSamples = useCallback(() => {
        setEdtaSamples(edtaSamplesDefaults);
    }, []);

    const createNewEdtaSampleForBloodSampleBundle = async ({ bloodSampleBundleId, data }: CreateEdtaSampleParams) => {
        const edtaSampleWithLinks = await createEdtaSampleForBloodSampleBundle({ bloodSampleBundleId, data });
        const { _links, ...edtaSampleWithoutLinks } = edtaSampleWithLinks;
        setEdtaSamples([...edtaSamples, { ...edtaSampleWithoutLinks, bloodSampleBundleId }]);
        return edtaSampleWithoutLinks;
    };

    const deleteEdtaSampleByBloodSampleBundleId = async (bloodSampleBundleId: number) => {
        const edtaSample = getEdtaSampleByBloodSampleBundleId(bloodSampleBundleId);

        if (edtaSample) {
            await deleteEdtaSampleById(edtaSample.id);
            setEdtaSamples(edtaSamples.filter((edtaSample) => edtaSample.bloodSampleBundleId !== bloodSampleBundleId));
        }
    };

    const getEdtaSampleByBloodSampleBundleId = (bloodSampleBundleId: number) => {
        return edtaSamples.find((edtaSample) => edtaSample.bloodSampleBundleId === bloodSampleBundleId);
    };

    const loadEdtaSamplesForbloodSampleBundlesIds = useCallback(async (bloodSampleBundlesIds: number[]) => {
        const edtaSamples: EdtaSampleWithMeasurementId[] = [];

        for (const bloodSampleBundleId of bloodSampleBundlesIds) {
            const edtaSampleWithLinks = await getEdtaSampleByMeasurementId(bloodSampleBundleId);
            const { _links, ...edtaSampleWithoutLinks } = edtaSampleWithLinks;
            edtaSamples.push({ ...edtaSampleWithoutLinks, bloodSampleBundleId });
        }

        setEdtaSamples(edtaSamples);
    }, []);

    const updateEdtaSampleAttributeForEdtaSampleId = useCallback(
        async (edtaSampleId: number, delta: { [key: string]: any }) => {
            const attribute = Object.keys(delta)[0];
            let debounceFunction = debounceFunctions[attribute];

            if (!debounceFunctions[attribute]) {
                debounceFunction = debouncedSaveWithErrors({
                    callApi: updateEdtaSampleById,
                    setErrors: setEdtaErrors,
                });
                setDebounceFunctions({ ...debounceFunctions, [attribute]: debounceFunction });
            }

            await debounceFunction(delta, edtaSampleId);

            const updatedEdtaSamples = modifyObjectInArrayById({
                array: edtaSamples,
                delta,
                id: edtaSampleId,
            });

            setEdtaSamples(updatedEdtaSamples);
        },
        [edtaSamples, debounceFunctions]
    );

    return (
        <EdtaSamplesContext.Provider
            value={{
                clearEdtaSamples,
                createNewEdtaSampleForBloodSampleBundle,
                deleteEdtaSampleByBloodSampleBundleId,
                edtaSamples,
                edtaSamplesErrors,
                getEdtaSampleByBloodSampleBundleId,
                loadEdtaSamplesForbloodSampleBundlesIds,
                setEdtaErrors,
                updateEdtaSampleAttributeForEdtaSampleId,
            }}
        >
            {props.children}
        </EdtaSamplesContext.Provider>
    );
};
