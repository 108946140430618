import React, { useContext, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import { BIOCHEMICAL, BIOPYHSICAL } from '../app/config';
import Footer from '../components/Footer/Footer';
import { GenericTable } from '../components/GenericTable/GenericTable';
import Header from '../components/Header/Header';
import { RecordDetails } from '../components/RecordDetails/RecordDetails';
import { RiskScore } from '../components/RiskScore/RiskScore';
import { Scale } from '../components/Scale/Scale';
import { PatientContext } from '../contexts/PatientContext';
import { normalizeValue } from '../helper/scaleHelper';
import { ScrollTop } from '../helper/ScrollTop';
import { useLoadPatientForId } from '../hooks/useLoadPatientForId';
import { ReactComponent as DownloadIcon } from '../resources/icons/download.svg';
import './RiskResult.scss';

interface RiskResultProps {}

export const RiskResult: React.FC<RiskResultProps> = () => {
    const { patientId } = useParams();
    const [isAdvanced, setIsAdvanced] = useState(false);

    useLoadPatientForId(patientId);

    const { patient } = useContext(PatientContext);

    const downloadPDF = () => {};

    const biophysicalHeader = ['Biophysical Inputs', 'Value', ''];
    // TODO - replace with real data
    const dummyBioPhysicalData: { [key: string]: number } = { map: 92, utpil: 1.05, utpir: 1.39 };
    const biophysicalData = BIOPYHSICAL.map(({ id, name, min, max, unit }) => {
        const value = dummyBioPhysicalData[id];
        return [
            <>
                {name} {unit && <small>({unit})</small>}
            </>,
            value.toFixed(2),
            <Scale value={normalizeValue(value, min, max)} variant="secondary" />,
        ];
    });

    const biochemicalHeader = ['Biochemical Inputs', 'Value (ng.ml)', ''];
    const biochemicalData = BIOCHEMICAL.map(({ id, name, min, max, unit = 'ng/ml' }) => {
        // TODO - replace with real data
        const value: number = Math.random() * (max - min) + min;
        return [
            <>
                {id && (
                    <>
                        Metabolite No. {id} <small>(ng/ml)</small>
                        <br />
                    </>
                )}
                {name} {!id && <small>(ng/ml)</small>}
            </>,
            value.toFixed(2),
            <Scale value={normalizeValue(value, min, max)} />,
        ];
    });

    return (
        <div className="risk-result-page">
            <Header>
                <>
                    PrePsia Report: {patient.forename} {patient.surname} - Date xx xx xx
                </>
            </Header>
            <Container className="mt-5">
                <RecordDetails className="mb-5" />
                <RiskScore score={59} />
                <div className="flex mt-4 mb-4 justify-end">
                    <Button variant="outline-secondary" onClick={downloadPDF}>
                        <span className="flex justify-between align-center">
                            PDF <DownloadIcon className="icon icon--small ml-1" />
                        </span>
                    </Button>
                    <Button
                        className="ml-3"
                        variant={isAdvanced ? 'primary' : 'outline-primary'}
                        onClick={() => {
                            setIsAdvanced(!isAdvanced);
                        }}
                    >
                        {isAdvanced ? 'Hide' : 'Show'} advandced analysis
                    </Button>
                </div>

                {isAdvanced && (
                    <>
                        <Row className="mb-5">
                            <Col>
                                <GenericTable
                                    className="risk-result-table"
                                    data={biophysicalData}
                                    header={biophysicalHeader}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col>
                                <GenericTable
                                    className="risk-result-table"
                                    data={biochemicalData}
                                    header={biochemicalHeader}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
            <Footer />
            <ScrollTop />
        </div>
    );
};
