import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { OutcomeAdd } from '../Overlays/OutcomeAdd/OutcomeAdd';
import { SectionHeader } from '../SectionHeader/SectionHeader';

import { ReactComponent as EditIcon } from '../../resources/icons/edit.svg';
import { ReactComponent as AddIcon } from '../../resources/icons/add.svg';
import { GenericTable } from '../GenericTable/GenericTable';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { OutcomeContext } from '../../contexts/OutcomeContext';
import { useLoadOutcomeForPregnancyId } from '../../hooks/useLoadOutcomeForPregnancyId';

interface OutcomeProps {
    forceOverlay?: boolean;
}

export const Outcome = forwardRef<HTMLDivElement, OutcomeProps>(({ forceOverlay }, ref) => {
    const [isOverlayShown, setIsOverlayShown] = useState(false);
    const { pregnancy } = useContext(PregnancyContext);
    const { outcome } = useContext(OutcomeContext);

    useLoadOutcomeForPregnancyId(pregnancy.id);

    useEffect(() => {
        setIsOverlayShown(forceOverlay);
    }, [forceOverlay]);

    const headerOutcome = [
        '',
        'Preeclampsia detected',
        'Date of delivery',
        'Use of Aspirin',
        'Use of other blood thinner',
        '',
    ];
    // TODO fill with data when BE is ready
    const dataOutcome = [
        ['Outcome', outcome.prececlampsiaDetected, outcome.dateDelivery, outcome.aspirinUsed, outcome.bloodThinner, ''],
    ];

    const headerMaternal = ['', 'ICU Admission', 'Severity', 'HELLP Syndrome', 'Prematuraty', 'Target Organ lesion'];
    // TODO fill with data when BE is ready
    const dataMaternal = [
        [
            'Maternal outcome',
            'Yes',
            'High',
            'No',
            'Yes',
            <div>
                Visual alterations
                <br />
                Renal complications
            </div>,
        ],
    ];

    const headerFetal = ['', 'Adverse outcomes', 'Details', '', '', ''];
    // TODO fill with data when BE is ready
    const dataFetal = [
        [
            'Fetal outcome',
            'Yes',
            <div>
                Small for gestational age
                <br />
                Baby in ICU
            </div>,
            '',
            '',
            '',
        ],
    ];

    const Icon = outcome.id ? EditIcon : AddIcon;

    return (
        <div className="outcome" ref={ref}>
            <SectionHeader title="Outcome">
                <Icon className="icon-as-btn" onClick={() => setIsOverlayShown(true)} />
            </SectionHeader>
            {/* TODO uncomment when BE is ready */}
            {/* <GenericTable data={dataOutcome} header={headerOutcome} />
            <div className="divider mt-3 mb-5" />
            <GenericTable data={dataMaternal} header={headerMaternal} />
            <div className="divider mt-3 mb-5" />
            <GenericTable data={dataFetal} header={headerFetal} /> */}

            <OutcomeAdd dismissHandler={() => setIsOverlayShown(false)} isShown={isOverlayShown} />
        </div>
    );
});
