import React, { forwardRef, useContext, useEffect, useState } from 'react';

import { SectionHeader } from '../SectionHeader/SectionHeader';

import { ReactComponent as EditIcon } from '../../resources/icons/edit.svg';
import { ReactComponent as AddIcon } from '../../resources/icons/add.svg';
import { GenericTable } from '../GenericTable/GenericTable';
import { PIGFAdd } from '../Overlays/PIGFAdd/PIGFAdd';
import { getBloodSampleBundleForPigfByPregnancyId } from '../../api/bloodSampleBundles';
import { PregnancyContext } from '../../contexts/PregnancyContext';
import { SerumSamplesContext } from '../../contexts/SerumSamplesContext';
import { SerumSample } from '../../types';
import { BloodSampleBundlesContext } from '../../contexts/BloodSampleBundlesContext';

interface PIGFResultProps {
    forceOverlay?: boolean;
}

export const PIGFResult = forwardRef<HTMLDivElement, PIGFResultProps>(({ forceOverlay }, ref) => {
    const { pregnancy } = useContext(PregnancyContext);
    const { updateSerumSampleAttributeForSerumSampleId, serumSamples } = useContext(SerumSamplesContext);
    const { bloodSampleBundles } = useContext(BloodSampleBundlesContext);

    const [isOverlayShown, setIsOverlayShown] = useState(false);
    const [serumSample, setSerumSample] = useState<SerumSample>(null);
    const [bloodSampleId, setBloodSampleId] = useState<number>(null);

    useEffect(() => {
        if (!pregnancy.id) return;
        const getBloodSampleBundleId = async () => {
            try {
                const bloodSampleId = await getBloodSampleBundleForPigfByPregnancyId(pregnancy.id);
                setBloodSampleId(bloodSampleId);
            } catch (e) {
                console.error(e);
                setBloodSampleId(null);
            }
        };
        getBloodSampleBundleId();
    }, [pregnancy.id, bloodSampleBundles, serumSample]);

    useEffect(() => {
        const relevantSerumSample = serumSamples.find(
            (serumSample) => serumSample.bloodSampleBundleId === bloodSampleId
        );
        setSerumSample(relevantSerumSample);
    }, [serumSamples, bloodSampleId]);

    useEffect(() => {
        setIsOverlayShown(forceOverlay);
    }, [forceOverlay]);

    const addPigfResult = ({ pgifDate, pgifResultValue }: { pgifDate: string; pgifResultValue: string }) => {
        setIsOverlayShown(false);
        if (!serumSample) return;
        updateSerumSampleAttributeForSerumSampleId(serumSample.id, { pgifDate, pgifResultValue });
    };

    const header = ['Result (ng.ml)', 'Date of Result'];

    const data = [[serumSample?.pgifResultValue, serumSample?.pgifDate]];
    const hasResult = serumSample?.pgifDate || serumSample?.pgifResultValue;

    return (
        <div className="pigf-result" ref={ref}>
            <SectionHeader title="PIGF Result">
                {bloodSampleId &&
                    (hasResult ? (
                        <EditIcon className="icon-as-btn" onClick={() => setIsOverlayShown(true)} />
                    ) : (
                        <AddIcon className="icon-as-btn" onClick={() => setIsOverlayShown(true)} />
                    ))}
            </SectionHeader>

            {serumSample && hasResult && <GenericTable data={data} header={header} />}

            {serumSample && (
                <PIGFAdd
                    addPigfResult={addPigfResult}
                    dismissHandler={() => setIsOverlayShown(false)}
                    isShown={isOverlayShown}
                    serumSample={serumSample}
                />
            )}
        </div>
    );
});
