import { useCallback, useContext, useEffect } from "react";
import { OutcomeContext } from "../contexts/OutcomeContext";

export function useLoadOutcomeForPregnancyId(pregnancyId: number) {
    const { clearOutcome, loadOutcomeForPregnancy } = useContext(OutcomeContext);

    const cleanup = useCallback(() => {
        clearOutcome();
    }, [clearOutcome]);

    useEffect(() => {
        if (pregnancyId) {
            loadOutcomeForPregnancy(pregnancyId);
        } else {
            cleanup();
        }

        return cleanup;
    }, [cleanup, loadOutcomeForPregnancy, pregnancyId]);
}