import React from 'react';
import { ModalWrapper } from '../../Wrapper/ModalWrapper';
import { ReactComponent as AddSampleIcon } from '../../../resources/icons/bloodtype.svg';

interface SampleCreateProps {
    confirmHandler: () => void;
    dismissHandler: () => void;
    isShown: boolean;
}

export const SampleCreate: React.FC<SampleCreateProps> = ({
    confirmHandler: createSample,
    dismissHandler,
    isShown,
}) => {
    return (
        <ModalWrapper
            confirmHandler={createSample}
            confirmText="Create"
            dismissHandler={dismissHandler}
            header="Add new sample"
            icon={<AddSampleIcon />}
            isShown={isShown}
            size="large"
            type="confirm"
        >
            <p>Are you sure you want to add a new sample?</p>
        </ModalWrapper>
    );
};
